import { SubjectPermission } from '~/modules/core/enums/permissions/_types';
import { SubjectInfo } from '~/modules/core/api/subjects/_types';
import usePermissions from './usePermissions';

const usePermissionCommunication = () => {
    const { can, canSubject } = usePermissions();

    const canReceiveAny = (): boolean => {
        return can([SubjectPermission.CommunicationMessageReceive]);
    };

    const canSendAny = (): boolean => {
        return can([SubjectPermission.CommunicationMessageSend]);
    };

    const canSendSubject = (subject: SubjectInfo | null): boolean => {
        return canSubject([SubjectPermission.CommunicationMessageSend], subject);
    };

    const filterCanSendSubject = (subjects: SubjectInfo[]) => {
        return subjects.filter((x) => canSendSubject(x));
    };

    return {
        canReceiveAny,
        canSendAny,
        canSendSubject,
        filterCanSendSubject,
    };
};

export default usePermissionCommunication;

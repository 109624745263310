import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { fetchListOptionsToQuery } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import {
    rawToCommunicationMessageInfoArray,
    rawToCommunicationContact,
    rawToCommunicationContactArray,
    rawToCommunicationContactExport,
    rawToCommunicationInfo,
} from './_transformers';
import {
    CommunicationContact,
    CommunicationContactExport,
    CommunicationContactFormData,
    CommunicationContactImportFormData,
    CommunicationContactMultipleFormData,
    CommunicationInfo,
    CommunicationMessageFormData,
    CommunicationMessageInfo,
} from './_types';

// > Endpoint prefix
const apiEndpointContactsPrefix = 'subjects';
const apiEndpointMessagesPrefix = 'subject-messages';

export const fetchCommunicationContactsRequest: ApiHandlerWithData<
    {
        subjectId: number;
        opt?: FetchListOptions;
    },
    ApiResponseContext<CommunicationContact[] | null, null, PaginationMeta | null>
> = async (args: { subjectId: number; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointContactsPrefix, `${args.subjectId}/contacts`), {
        transform: rawToCommunicationContactArray,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const postCommunicationContactsRequest: ApiHandlerWithData<
    {
        subjectId: number;
        data: CommunicationContactMultipleFormData;
    },
    ApiResponseContext<never, CommunicationContactMultipleFormData>
> = async ({ subjectId, data }) => {
    return api.post(formatApiUrl(apiEndpointContactsPrefix, `${subjectId}/contacts`), data);
};

export const putCommunicationContactRequest: ApiHandlerWithData<
    {
        id: number;
        data: CommunicationContactFormData;
    },
    ApiResponseContext<CommunicationContact | null, CommunicationContactFormData>
> = async ({ id, data }) => {
    return api.put(formatApiUrl(apiEndpointContactsPrefix, `contacts/${id}`), data, {
        transform: rawToCommunicationContact,
    });
};

export const deleteCommunicationContactRequest: ApiHandlerWithData<number, ApiResponseContext<never>> = async (
    id: number
) => {
    return api.delete(formatApiUrl(apiEndpointContactsPrefix, `contacts/${id}`));
};

export const postCommunicationContactsImportRequest: ApiHandlerWithData<
    {
        subjectId: number;
        data: CommunicationContactImportFormData;
    },
    ApiResponseContext<never, CommunicationContactImportFormData>
> = async ({ subjectId, data }) => {
    return api.post(formatApiUrl(apiEndpointContactsPrefix, `${subjectId}/contacts/import`), data);
};

export const fetchCommunicationContactsExportRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<CommunicationContactExport | null>
> = async (subjectId: number) => {
    return api.get(formatApiUrl(apiEndpointContactsPrefix, `${subjectId}/contacts/export`), {
        transform: rawToCommunicationContactExport,
    });
};

export const fetchCommunicationInfoRequest: ApiHandler<ApiResponseContext<CommunicationInfo | null>> = async () => {
    return api.get(formatApiUrl(apiEndpointMessagesPrefix, 'info'), {
        transform: rawToCommunicationInfo,
    });
};

export const fetchCommunicationMessagesRequest: ApiHandler<
    ApiResponseContext<CommunicationMessageInfo[] | null>
> = async () => {
    return api.get(formatApiUrl(apiEndpointMessagesPrefix), {
        transform: rawToCommunicationMessageInfoArray,
    });
};

export const postCommunicationMessageRequest: ApiHandlerWithData<
    CommunicationMessageFormData,
    ApiResponseContext<never, CommunicationMessageFormData>
> = async (data) => {
    return api.post(formatApiUrl(apiEndpointMessagesPrefix), data);
};

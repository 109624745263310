import useChecklistsStore from '~/modules/core/stores/checklists';

const useChecklists = () => {
    const store = useChecklistsStore();
    const { checklists, totalChecklists, activeChecklist, checklistFormOpen, modalChecklistOpen, totalUncheckedCount } =
        storeToRefs(store);

    return {
        checklists,
        totalChecklists,
        activeChecklist,
        checklistFormOpen,
        modalChecklistOpen,
        totalUncheckedCount,
        setActiveChecklist: store.setActiveChecklist,
        setChecklistFormOpen: store.setChecklistFormOpen,
        setModalChecklistOpen: store.setModalChecklistOpen,
        clearChecklists: store.clearChecklists,
        updateChecklistById: store.updateChecklistById,
        updateUncheckedCount: store.updateUncheckedCount,
    };
};

export default useChecklists;

import { pick } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToAppFileInfo } from '~/modules/core/api/files/_transformers';
import {
    UserDetail,
    UserSimpleInfo,
    UserSimpleInfoShort,
    UserInfo,
    SettingsUserInfo,
    SettingsUserDetail,
    UserProfileDetail,
    UserConfirmationId,
} from './_types';
import { getFullName } from '~/modules/core/utils/userUtils';
import {
    rawToProjectSimpleInfoArray,
    rawToRoleSimpleInfoArray,
    rawToSubjectSimpleInfoArray,
} from '~/modules/core/api/enums/_transformers';

export const rawToUserSimpleInfoShort: ApiResponseTransformer<UserSimpleInfoShort | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'FullName']),
    };
};

export const rawToUserSimpleInfo: ApiResponseTransformer<UserSimpleInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'FullName', 'AvatarUrl']),
    };
};

export const rawToUserInfo: ApiResponseTransformer<UserInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'FirstName', 'LastName', 'Organization', 'Function', 'Status']),
        FullName: getFullName(data),
    };
};

export const rawToUserDetail: ApiResponseTransformer<UserDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToUserInfo(data);
    if (info == null) {
        console.error(
            '[UserDetailTransformer] - [UserInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    const avatar = rawToAppFileInfo(data.Avatar);

    return {
        ...info,
        ...pick(data, ['Email', 'Phone']),
        Avatar: avatar,
    };
};

export const rawToSettingsUserInfo: ApiResponseTransformer<SettingsUserInfo | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, [
            'Id',
            'FirstName',
            'LastName',
            'Login',
            'Organization',
            'Function',
            'Status',
            'Email',
            'TitleBefore',
            'TitleAfter',
        ]),
        FullName: getFullName(data),
    };
};

export const rawToSettingsUserInfoDetail: ApiResponseTransformer<SettingsUserDetail | null> = (data) => {
    if (data == null) return null;

    const roles = rawToRoleSimpleInfoArray(data.Roles);
    const subjects = rawToSubjectSimpleInfoArray(data.Subjects);
    const projects = rawToProjectSimpleInfoArray(data.Projects);
    const avatar = rawToAppFileInfo(data.Avatar);

    return {
        ...pick(data, [
            'Id',
            'FirstName',
            'LastName',
            'Login',
            'Organization',
            'Function',
            'Status',
            'Email',
            'TitleBefore',
            'TitleAfter',
            'Phone',
            'IsAdmin',
            'AuthAdapter',
        ]),
        Roles: roles,
        Subjects: subjects,
        Projects: projects,
        Avatar: avatar,
        FullName: getFullName(data),
    };
};

export const rawToUserProfileDetail: ApiResponseTransformer<UserProfileDetail | null> = (data) => {
    if (data == null) return null;

    const roles = rawToRoleSimpleInfoArray(data.Roles);
    const subjects = rawToSubjectSimpleInfoArray(data.Subjects);
    const projects = rawToProjectSimpleInfoArray(data.Projects);
    const avatar = rawToAppFileInfo(data.Avatar);

    return {
        ...pick(data, [
            'Id',
            'FirstName',
            'LastName',
            'Login',
            'Organization',
            'Function',
            'Status',
            'Email',
            'TitleBefore',
            'TitleAfter',
            'Phone',
            'IsAdmin',
            'AuthAdapter',
        ]),
        Roles: roles,
        Subjects: subjects,
        Projects: projects,
        Avatar: avatar,
        FullName: getFullName(data),
    };
};

export const rawToUserConfirmationId: ApiResponseTransformer<UserConfirmationId | null> = (data) => {
    return data.ConfirmationId != null ? { ConfirmationId: data.ConfirmationId } : null;
};

export const rawToSettingsUserInfoArray: ApiResponseTransformer<SettingsUserInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSettingsUserInfo(x)).filter((x): x is SettingsUserInfo => !!x);
};

export const rawToUserSimpleInfoShortArray: ApiResponseTransformer<UserSimpleInfoShort[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToUserSimpleInfoShort(x)).filter((x): x is UserSimpleInfoShort => !!x);
};

import { RoutePageRedirects, RedirectType } from '~/plugins/router/_types';
import usePermissionUser from '~/modules/core/composables/permissions/usePermissionsUser';

export const redirects = (): RoutePageRedirects => {
    const { canCreateAny, canManageAny } = usePermissionUser();
    return {
        'settings-users': () => (canManageAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'settings-users-new': () => (canCreateAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

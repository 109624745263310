import { t } from '~/plugins/i18n';
import { RouteLocationNormalized } from 'vue-router';
import { InjectionKey } from 'vue';
import { isEmpty } from 'lodash-es';

export const setPageTitle = (route: RouteLocationNormalized): void => {
    const pageName = route.name?.toString() || '';
    useTitle(t(`pages.${pageName}`), { titleTemplate: `${t('app.title')} | %s` });
};

/**
 * Vue TS strict inject provided (avoid undefined)
 * @param key
 * @param fallback
 */
export const injectStrict = <T>(key: InjectionKey<T>, fallback?: T): T => {
    const resolved = inject(key, fallback);

    if (!resolved) {
        throw new Error(`Could not resolve ${key.description}`);
    }

    return resolved;
};

/**
 * Remove a trailing slash from URL
 * @param url
 */
export const normalizeUrl = (url: string): string => {
    return url.endsWith('/') ? url.slice(0, -1) : url;
};

/**
 * Check if the given data has files.
 *
 * @param data
 */
export const hasFiles = (data: File | Blob | FileList | Record<string, any>): boolean => {
    return (
        data instanceof File ||
        data instanceof Blob ||
        data instanceof FileList ||
        (typeof data === 'object' &&
            data !== null &&
            Object.values(data).find((value) => hasFiles(value)) !== undefined)
    );
};

/**
 * Get Input Error key from name (Laravel validation format X.Y.Z)
 * @param name
 */
export const getInputErrorKey = (name: string) => name.replace(/[[\].]+/g, '.').replace(/\.*$/, '');

export const getAcronym = (val: string): string => {
    return val
        .split(' ')
        .filter((x) => !isEmpty(x))
        .map((x) => x[0])
        .join('');
};

export const convertToNumber = (val?: string | null): number | null => {
    if (val == null) return null;
    const num = +val;
    return isNaN(num) ? null : num;
};

/**
 * utils method for exhaustive switch pattern
 * https://medium.com/technogise/type-safe-and-exhaustive-switch-statements-aka-pattern-matching-in-typescript-e3febd433a7a
 * @param enumValue
 */
export const exhaustiveTypeguard = (_: never): never => {
    throw new Error('Should never get here!');
};

/**
 * Alias method for 'Object.keys' method that returns typed keys instead of string[]
 * It is not exactly typesafe though because at runtime there might be additional keys.
 */
export const getTypedKeys = Object.keys as <T extends object>(yourObject: T) => Array<keyof T>;

/**
 * Check if coordinates are inside dom rect
 * @param x
 * @param y
 * @param rect
 * @returns
 */
export const isInsideDOMRect = (x: number, y: number, rect: DOMRect) => {
    return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
};

import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { ModuleKey } from '~/modules/core/enums/_types';
import { TasksPermissions } from '~/modules/tasks/enums/permissions/_types';

const usePermissionTasksTracking = () => {
    const { can } = usePermissions();

    const canManageTrackTimeMeAny = (): boolean => {
        return can([TasksPermissions.TimeRecordManageOwn, TasksPermissions.Manage], { module: ModuleKey.Tasks });
    };

    const canManageTrackTimeOthersAny = (): boolean => {
        return can([TasksPermissions.TimeRecordManage, TasksPermissions.Manage], { module: ModuleKey.Tasks });
    };

    return {
        canManageTrackTimeMeAny,
        canManageTrackTimeOthersAny,
    };
};

export default usePermissionTasksTracking;

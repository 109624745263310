import { AppPlugin } from '~/plugins/_types';
import notificationManagerInstance, { resolveActions } from '.';

export default {
    name: 'notificationManager',
    install: async () => {
        const actions = resolveActions();

        notificationManagerInstance.registerBatch(actions);
    },
    priority: 3,
} satisfies AppPlugin;

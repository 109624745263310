import browserStorage from '~/plugins/browserStorage';
import config from '~/plugins/config';
import { changeLocale } from './locale';
import { Locale } from './_types';

const createLocaleBrowserStorage = () => {
    const localeStorageKey = config.storage.data.locale.key;
    const { locale: configLocale, availableLocales } = config.app;

    /**
     * Cached locale
     * Must be ref - writable computed `activeLocale` does not work correctly (does not refresh getter when setting value)
     */
    const _cachedLocale: Ref<Locale | null> = ref(null);

    const localeDisplayNames = computed(() => {
        return new Intl.DisplayNames([activeLocale.value], {
            type: 'language',
        });
    });

    const activeLocale = computed({
        get: () => {
            if (_cachedLocale.value == null) {
                const locale = browserStorage.get<string>(localeStorageKey, configLocale);
                _cachedLocale.value = availableLocales.some((x) => x === locale) ? (locale as Locale) : configLocale;
            }

            return _cachedLocale.value;
        },
        set: (newLocale: Locale) => {
            _cachedLocale.value = newLocale;
            browserStorage.set(localeStorageKey, newLocale);
            changeLocale(newLocale);
        },
    });

    const init = () => {
        const locale = activeLocale.value;
        changeLocale(locale);
    };

    return {
        localeDisplayNames,
        availableLocales,
        activeLocale,
        init,
    };
};

const localeStorage = createLocaleBrowserStorage();

export default localeStorage;

import { defineStore } from 'pinia';
import { NotificationStatusInfo } from '~/modules/core/api/notifications/_types';

export const useNotificationsStore = defineStore('notifications', () => {
    const status = ref<NotificationStatusInfo | null>(null);
    const needNotificationsRefresh = ref(false);

    const setNotificationsRefresh = (value: boolean) => {
        needNotificationsRefresh.value = value;
    };

    return {
        status,
        needNotificationsRefresh,
        setNotificationsRefresh,
    };
});

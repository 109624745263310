export enum GlobalPermission {
    // manage crisis documents
    CrisisDocumentManage = 'CrisisDocumentManage',

    // show detail of any user
    UserDetail = 'UserDetail',
    // create new user
    UserCreate = 'UserCreate',
    // update any user
    UserUpdate = 'UserUpdate',

    // manage crisis documents
    LabelsManage = 'LabelsManage',
    // manage users in projects
    GlobalProjectUserManage = 'GlobalProjectUserManage',
}

export enum SubjectPermission {
    // see subject detail
    Detail = 'SubjectDetail',
    // can update subject
    Update = 'SubjectUpdate',
    // can create subject
    Create = 'SubjectCreate',
    // can delete subject
    Delete = 'SubjectDelete',
    // can create project in subject
    ProjectCreate = 'SubjectProjectCreate',
    // can see user list
    UserList = 'SubjectUserList',
    // can see user detail
    UserDetail = 'SubjectUserDetail',
    // can manage users (add/edit/delete)
    UserManage = 'SubjectUserManage',
    // user will became contact person for subject
    ContactUser = 'SubjectUserContact',
    // can see contact list and manage (import/add/edit/delete)
    ContactManage = 'SubjectContactManage',
    // can send messages for crisis communication
    CommunicationMessageSend = 'SubjectMessageSend',
    // can receive messages for crisis communication
    CommunicationMessageReceive = 'SubjectMessageReceive',
    // manage project invitations
    AcceptProjectInvitations = 'SubjectAcceptProjectInvitation',
}

export enum ProjectPermission {
    // see project detail
    Detail = 'ProjectDetail',
    // can update project
    Update = 'ProjectUpdate',
    // can delete project
    Delete = 'ProjectDelete',
    // can see user list
    UserList = 'ProjectUserList',
    // can see user detail
    UserDetail = 'ProjectUserDetail',
    // can manage users (add/edit/delete)
    UserManage = 'ProjectUserManage',
    // can invite another subjects to project
    InviteSubject = 'ProjectInviteSubject',
}

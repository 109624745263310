import { FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import {
    fetchTasksIssueDetailRequest,
    fetchTasksIssuesGanttSelectRequest,
    fetchTasksIssuesRequest,
    fetchTasksIssuesSelectRequest,
    patchTasksIssueChangeAssigneeRequest,
    patchTasksIssueChangeDueDateRequest,
    patchTasksIssueChangeEstimatedTimeRequest,
    patchTasksIssueChangePriorityRequest,
    patchTasksIssueChangeStateRequest,
    patchTasksIssueCloseRequest,
    patchTasksIssueReopenRequest,
    patchTasksIssueRequest,
    patchTasksIssueTimeComplianceRequest,
    postTasksIssueRequest,
} from '~/modules/tasks/api/issues';
import {
    TasksIssueDetail,
    TasksIssueCreateFormData,
    TasksIssueInfo,
    TasksIssueEditFormData,
    TasksIssueDueDateFormData,
    TasksIssueEstimatedTimeFormData,
    TasksIssueStateInfo,
    TasksIssueStateFormData,
    TasksIssuePriorityInfo,
    TasksIssuePriorityFormData,
    TasksIssueAssigneeInfo,
    TasksIssueAssigneeFormData,
    TasksIssueClosedInfo,
    TasksIssueInfoShort,
    TasksIssueDueDateInfo,
    TasksIssueEstimatedTimeInfo,
} from '~/modules/tasks/api/issues/_types';
import { useTasksIssueStore } from '~/modules/tasks/stores/tasksIssue';
import {
    fetchAndStoreData,
    updateAndStoreFormData,
    updateAndStoreDataOrNotifyError,
    updateValuesAndStoreDataOrSetValidationErrors,
} from '~/modules/core/services/utils';
import { FetchDetailOptions } from '~/modules/core/services/_types';
import routerInstance from '~/plugins/router';
import { FetchListOptions } from '~/modules/core/api/_types';
import { TasksIssuePriority, TasksIssueState } from '~/modules/tasks/enums/_types';

/**
 * @param issueId
 * @param opt
 */
export const fetchTasksIssueDetail = async (
    issueId: number,
    opt?: FetchDetailOptions
): Promise<{ isSuccess: boolean; data: TasksIssueDetail | null }> => {
    return await fetchAndStoreData(
        issueId,
        routerInstance,
        getActiveIssueFromStore,
        async (issueId) => await fetchTasksIssueDetailRequest(issueId),
        opt
    );
};

/**
 * @param opt
 */
export const fetchTasksIssues = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TasksIssueInfo[] | null;
}> => {
    return await fetchTasksIssuesRequest({ opt });
};

/**
 * @param opt
 */
export const fetchTasksIssuesSelect = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TasksIssueInfoShort[] | null;
}> => {
    return await fetchTasksIssuesSelectRequest({ opt });
};

/**
 * @param opt
 */
export const fetchTasksIssuesGanttSelect = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TasksIssueInfo[] | null;
}> => {
    return await fetchTasksIssuesGanttSelectRequest({ opt });
};

/**
 * @param projectId
 * @param values
 * @param ctx
 */
export const createTasksIssue: FormHandlerWithArgs<
    number,
    TasksIssueCreateFormData,
    { isSuccess: boolean; data: TasksIssueDetail | null }
> = async (projectId, values, ctx) => {
    return await updateAndStoreFormData(
        projectId,
        values,
        ctx,
        getActiveIssueFromStore,
        async (projectId, values) =>
            await postTasksIssueRequest({
                projectId,
                data: values,
            })
    );
};

/**
 * @param issueId
 * @param values
 * @param ctx
 */
export const updateTasksIssue: FormHandlerWithArgs<
    number,
    TasksIssueEditFormData,
    { isSuccess: boolean; data: TasksIssueDetail | null }
> = async (issueId, values, ctx) => {
    return await updateAndStoreFormData(
        issueId,
        values,
        ctx,
        getActiveIssueFromStore,
        async (issueId, values) =>
            await patchTasksIssueRequest({
                issueId,
                data: values,
            })
    );
};

/**
 * @param issueId
 */
export const reopenTasksIssue = async (
    issueId: number
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateIssue } = useTasksIssueStore();
    return await updateAndStoreDataOrNotifyError<TasksIssueClosedInfo, TasksIssueClosedInfo>(
        () => patchTasksIssueReopenRequest(issueId),
        updateIssue
    );
};

/**
 * @param issueId
 */
export const closeTasksIssue = async (
    issueId: number
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateIssue } = useTasksIssueStore();
    return await updateAndStoreDataOrNotifyError<TasksIssueClosedInfo, TasksIssueClosedInfo>(
        () => patchTasksIssueCloseRequest(issueId),
        updateIssue
    );
};

/**
 * @param issueId
 * @param assigneeId
 */
export const changeAssigneeTasksIssue = async (
    issueId: number,
    assigneeId: number | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateIssue } = useTasksIssueStore();
    return await updateAndStoreDataOrNotifyError<TasksIssueAssigneeInfo, TasksIssueAssigneeFormData>(
        () => patchTasksIssueChangeAssigneeRequest({ issueId, data: { AssigneeId: assigneeId } }),
        updateIssue
    );
};

/**
 * @param issueId
 * @param priority
 */
export const changePriorityTasksIssue = async (
    issueId: number,
    priority: TasksIssuePriority | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateIssue } = useTasksIssueStore();
    return await updateAndStoreDataOrNotifyError<TasksIssuePriorityInfo, TasksIssuePriorityFormData>(
        () => patchTasksIssueChangePriorityRequest({ issueId, data: { Priority: priority } }),
        updateIssue
    );
};

/**
 * @param issueId
 * @param state
 */
export const changeStateTasksIssue = async (
    issueId: number,
    state: TasksIssueState | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateIssue } = useTasksIssueStore();
    return await updateAndStoreDataOrNotifyError<TasksIssueStateInfo, TasksIssueStateFormData>(
        () => patchTasksIssueChangeStateRequest({ issueId, data: { State: state } }),
        updateIssue
    );
};

/**
 * @param issueId
 * @param values
 * @param ctx
 */
export const changeDueDateTasksIssue: FormHandlerWithArgs<
    number,
    TasksIssueDueDateFormData,
    { isSuccess: boolean }
> = async (issueId, values, ctx) => {
    const { updateIssue } = useTasksIssueStore();

    return await updateValuesAndStoreDataOrSetValidationErrors<TasksIssueDueDateInfo, TasksIssueDueDateFormData>(
        ctx,
        () => patchTasksIssueChangeDueDateRequest({ issueId, data: values }),
        updateIssue
    );
};

/**
 * @param issueId
 * @param values
 * @param ctx
 */
export const changeEstimatedTimeTasksIssue: FormHandlerWithArgs<
    number,
    TasksIssueEstimatedTimeFormData,
    { isSuccess: boolean }
> = async (issueId, values, ctx) => {
    const { updateIssue } = useTasksIssueStore();
    return await updateValuesAndStoreDataOrSetValidationErrors<
        TasksIssueEstimatedTimeInfo,
        TasksIssueEstimatedTimeFormData
    >(ctx, () => patchTasksIssueChangeEstimatedTimeRequest({ issueId, data: values }), updateIssue);
};

/**
 * @param issueId
 * @param isCompliant
 */
export const confirmTimeComplianceTasksIssues = async (
    issueId: number,
    isCompliant: boolean
): Promise<{
    isSuccess: boolean;
}> => {
    return await patchTasksIssueTimeComplianceRequest({ issueId, isCompliant });
};

const getActiveIssueFromStore = (): Ref<TasksIssueDetail | null> => {
    const { activeIssue } = storeToRefs(useTasksIssueStore());
    return activeIssue;
};

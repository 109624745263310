import { ConfigDataInfo } from '~/modules/core/api/config/_types';
import { fetchConfigRequest } from '~/modules/core/api/config';
import { useConfigStore } from '~/modules/core/stores/config';

export const fetchConfig = async (): Promise<{ isSuccess: boolean; data: ConfigDataInfo | null }> => {
    const result = await fetchConfigRequest();

    if (result.isSuccess && result.data) {
        const store = useConfigStore();
        store.setConfigData(result.data);
    }

    return result;
};

import { isEmpty } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { FetchListFilterOptions, FetchListOptions, FetchListSortOptions, PaginationMeta } from './_types';

const QUERY_NULL = 'null';

export const rawToPaginationMeta: ApiResponseTransformer<PaginationMeta | null> = (data) => {
    if (data == null) return null;
    return {
        Limit: data.Limit || -1,
        Offset: data.Offset || 0,
        Total: data.Total || 0,
    };
};

export const fetchListOptionsToQuery = (opt?: FetchListOptions | null) => {
    let query: any = {};
    if (opt?.pagination != null) {
        query = {
            ...opt.pagination,
        };
    }

    if (opt?.sort != null) {
        query.sort = getSortColumn(opt.sort);
    }

    if (opt?.search != null) {
        query.search = opt.search;
    }

    if (opt?.filter != null) {
        query.filter = getFilterQuery(opt.filter);
    }

    return query;
};

const getSortColumn = (sort: FetchListSortOptions): string => {
    return sort.descending ? `-${sort.column}` : sort.column;
};

const getFilterQuery = (filter: FetchListFilterOptions[]) => {
    const filterQuery: Record<string, Record<string, any>> = {};

    filter.forEach((f) => {
        if (isEmpty(f.column) || isEmpty(f.op)) return;

        if (!(f.column in filterQuery)) {
            filterQuery[f.column] = {};
        }

        if (!(f.op in filterQuery[f.column])) {
            filterQuery[f.column][f.op] = [];
        }

        filterQuery[f.column][f.op].push(f.val == null ? QUERY_NULL : f.val);
    });

    // If there is only one value in array then we remove the array and use value directly.
    // The reason is that some columns only supports one value, so we cannot send it as array.
    // If there are more than one value in unsupported columns, we do not fix it here but in the
    // component that created the filter array input (because transformer does not have context
    // what should have multiple values and what should not).
    for (const col in filterQuery) {
        for (const op in filterQuery[col]) {
            if (filterQuery[col][op].length === 1) {
                filterQuery[col][op] = filterQuery[col][op][0];
            }
        }
    }
    return filterQuery;
};

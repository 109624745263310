<script lang="ts" setup>
import useHomeWidgets from '~/modules/core/composables/useHomeWidgets';

const { widgets } = useHomeWidgets();
</script>
<template>
    <page-base padding>
        <page-header :title="$t('home.homepage')" :subtitle="$t('home.welcomeBack')" :caption="$t('app.title-long')" />
        <div class="row q-mt-xl q-col-gutter-md">
            <component v-for="w in widgets" :key="w.uid" :is="w.widget" />
        </div>
    </page-base>
</template>

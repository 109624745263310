import { getAppSettingsConfig } from '~/modules/core/configs/settings';

const usePermissionSettings = () => {
    const settings = getAppSettingsConfig();
    const canShowSettings = (): boolean => {
        return settings.some((x) => x.canShow());
    };
    return {
        settings,
        canShowSettings,
    };
};

export default usePermissionSettings;

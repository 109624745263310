export enum StatisticsPermissions {
    /**
     * create, edit, delete master product
     */
    MasterProductManage = 'StatisticsMasterProductManage',
    /**
     * create/delete dashboard
     */
    DashboardManage = 'StatisticsDashboardManage',
    /**
     * manage which inventories (projects) should be included in statistics
     */
    InventoryManage = 'StatisticsInventoryManage',
}

import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { rawToAppFileInfoArray } from '~/modules/core/api/files/_transformers';
import { rawToUserSimpleInfo } from '~/modules/core/api/users/_transformers';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { TasksIssueComment } from './_types';

export const rawToTasksIssueComment: ApiResponseTransformer<TasksIssueComment | null> = (data) => {
    if (data == null) return null;
    const createdBy = rawToUserSimpleInfo(data.CreatedBy);
    if (createdBy == null) {
        console.error(
            "[TasksIssueCommentTransformer] - Property 'CreatedBy' is required but [UserSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }
    return {
        ...pick(data, ['Id', 'ParentId', 'Body']),
        CreatedBy: createdBy,
        UpdatedBy: rawToUserSimpleInfo(data.UpdatedBy),
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        UpdatedAt: data.UpdatedAt ? DateTime.fromISO(data.UpdatedAt) : null,
        DeletedAt: data.DeletedAt ? DateTime.fromISO(data.DeletedAt) : null,
        Attachments: rawToAppFileInfoArray(data.Attachments) || [],
    };
};

export const rawToTasksIssueCommentArray: ApiResponseTransformer<TasksIssueComment[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToTasksIssueComment(x)).filter((x): x is TasksIssueComment => !!x);
};

export interface TasksIssueCommentFormData {
    ParentId: number | null;
    Body?: string | null;
    Attachments: number[];
}

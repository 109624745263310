import { AppPlugin } from '~/plugins/_types';
import { createPinia } from 'pinia';
import router from '~/plugins/router';

export default {
    name: 'pinia',
    install: async (app) => {
        const pinia = createPinia();

        // @see https://github.com/vuejs/pinia/discussions/1717
        pinia.router = () => router;

        app.use(pinia);
    },
    priority: 10,
} satisfies AppPlugin;

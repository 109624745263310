import { DispatchingIssueComment } from '~/modules/dispatching/api/comments/_types';
import { DispatchingIssueDetail } from '~/modules/dispatching/api/issues/_types';

export const useDispatchingIssueStore = defineStore('dispatching-issue', () => {
    const activeIssue = ref<DispatchingIssueDetail | null>(null);

    const addOrReplaceComment = (comment: DispatchingIssueComment) => {
        if (activeIssue.value == null) {
            return;
        }

        const cIndex = activeIssue.value.Comments.findIndex((x) => x.Id === comment.Id);

        if (cIndex < 0) {
            activeIssue.value.Comments.push(comment);
        } else {
            activeIssue.value.Comments[cIndex] = comment;
        }
    };

    const deleteComment = (commentId: number) => {
        if (activeIssue.value == null) {
            return;
        }

        activeIssue.value.Comments = activeIssue.value.Comments.filter((x) => x.Id !== commentId);
    };

    const reset = (): void => {
        activeIssue.value = null;
    };

    return {
        activeIssue,
        addOrReplaceComment,
        deleteComment,
        reset,
    };
});

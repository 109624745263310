import { AppPlugin } from '~/plugins/_types';
import router from '~/plugins/router';
import { init, BrowserTracing, Replay, vueRouterInstrumentation } from '@sentry/vue';
import config from '~/plugins/config';

export default {
    name: 'sentry',
    install: async (app) => {
        init({
            app,
            enabled: config.sentry.enabled,
            dsn: config.sentry.dsn,
            environment: config.env.mode,
            release: config.sentry.release,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router),
                }),
                new Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.3,
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    },
    priority: 9,
} satisfies AppPlugin;

import * as yup from 'yup';
import { VueI18nTranslation } from 'vue-i18n';

/**
 * Set Yup Locale
 *
 * @link https://github.com/jquense/yup?tab=readme-ov-file#localization-and-i18n
 */
export const changeYupLocale = (t: VueI18nTranslation) => {
    yup.setLocale({
        mixed: {
            default: t('validation.messages._default'),
            required: t('validation.messages.required'),
        },
    });
};

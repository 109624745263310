import { NavigationGuardWithThis, NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import { useTasksIssueStore } from '~/modules/tasks/stores/tasksIssue';
import { TasksTrackingManageTab } from '~/modules/tasks/enums/_types';
import { useTasksGanttStore } from '~/modules/tasks/stores/tasksGanttDetail';

export const after: NavigationHookAfter = (to) => {
    resetTaskIssuesStore(to);
    resetTaskGanttStore(to);
};

export const before: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    if (to.name?.toString() === 'tasks-tracking-manage') {
        await next({
            name: 'tasks-tracking-manage-section',
            params: {
                section: TasksTrackingManageTab.FinishedRecords,
            },
        });
        return true;
    }
    return false;
};

const resetTaskIssuesStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('tasks-issues-id')) {
        const store = useTasksIssueStore();
        store.reset();
    }
};

const resetTaskGanttStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('tasks-gantts-id')) {
        const store = useTasksGanttStore();
        store.reset();
    }
};

import { HCDashboardInfo } from '~/modules/humanitarian-care/api/transactions/_types';

const useHCDashboardStore = defineStore('hc-dashboard', () => {
    const activeDashboard = ref<HCDashboardInfo | null>(null);

    const reset = () => {
        activeDashboard.value = null;
    };

    return {
        activeDashboard,
        reset,
    };
});

export default useHCDashboardStore;

import { isArray, pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToSubjectInfoShort } from '~/modules/core/api/subjects/_transformers';
import { rawToUserSimpleInfoShort } from '~/modules/core/api/users/_transformers';
import {
    CommunicationContact,
    CommunicationContactExport,
    CommunicationInfo,
    CommunicationInfoSender,
    CommunicationMessageInfo,
} from './_types';

export const rawToCommunicationContact: ApiResponseTransformer<CommunicationContact | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Name', 'Function', 'Email', 'Phone']),
    };
};

export const rawToCommunicationContactExport: ApiResponseTransformer<CommunicationContactExport | null> = (data) => {
    if (data == null) return null;
    return {
        File: data,
    };
};

export const rawToCommunicationInfo: ApiResponseTransformer<CommunicationInfo | null> = (data) => {
    if (data == null) return null;

    const sender = rawToCommunicationInfoSender(data.Sender);
    if (sender == null) {
        console.error(
            "[CommunicationMessageInfoTransformer] - Data for property 'Sender' from [CommunicationInfoSenderTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        Sender: sender,
    };
};

export const rawToCommunicationInfoSender: ApiResponseTransformer<CommunicationInfoSender | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Name', 'Email']),
    };
};

export const rawToCommunicationMessageInfo: ApiResponseTransformer<CommunicationMessageInfo | null> = (data) => {
    if (data == null) return null;

    const sender = rawToUserSimpleInfoShort(data.Sender);
    if (sender == null) {
        console.error(
            "[CommunicationMessageInfoTransformer] - Data for property 'Sender' from [UserSimpleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const subject = rawToSubjectInfoShort(data.Subject);
    if (subject == null) {
        console.error(
            "[CommunicationMessageInfoTransformer] - Data for property 'Subject' from [SubjectInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Title', 'Body', 'MessageType', '']),
        Type: isArray(data.Type) ? data.Type : [],
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        Sender: sender,
        Subject: subject,
    };
};

export const rawToCommunicationContactArray: ApiResponseTransformer<CommunicationContact[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;

    return data.map((x) => rawToCommunicationContact(x)).filter((x): x is CommunicationContact => !!x);
};

export const rawToCommunicationMessageInfoArray: ApiResponseTransformer<CommunicationMessageInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;

    return data.map((x) => rawToCommunicationMessageInfo(x)).filter((x): x is CommunicationMessageInfo => !!x);
};

import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { ModuleKey } from '~/modules/core/enums/_types';
import { TasksPermissions } from '~/modules/tasks/enums/permissions/_types';

const usePermissionTasksGantt = () => {
    const { can, user } = usePermissions();

    const canViewGanttsAny = (): boolean => {
        return can([TasksPermissions.GanttManage, TasksPermissions.GanttManageOwn], { module: ModuleKey.Tasks });
    };

    const canDetailGanttAny = (authorId: number, isConfidential: boolean): boolean => {
        if (can([TasksPermissions.GanttManage], { module: ModuleKey.Tasks })) return true;

        return (
            (!isConfidential || user.value?.Id === authorId) &&
            can([TasksPermissions.GanttManageOwn], { module: ModuleKey.Tasks })
        );
    };

    const canManageGanttAny = (authorId: number): boolean => {
        if (can([TasksPermissions.GanttManage], { module: ModuleKey.Tasks })) return true;

        return user.value?.Id === authorId && can([TasksPermissions.GanttManageOwn], { module: ModuleKey.Tasks });
    };

    return {
        canViewGanttsAny,
        canDetailGanttAny,
        canManageGanttAny,
    };
};

export default usePermissionTasksGantt;

export enum HumanitarianCarePermissions {
    // create transaction
    TransactionCreate = 'HumanitarianCareTransactionCreate',
    // Delete transaction
    TransactionDelete = 'HumanitarianCareTransactionDelete',
    // Restore transaction
    TransactionRestore = 'HumanitarianCareTransactionRestore',
    // Can manage settings
    SettingsManage = 'HumanitarianCareSettingsManage',
}

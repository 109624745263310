import { pick } from 'lodash-es';
import { DateTime, Duration } from 'luxon';
import { API_DATE_FORMAT } from '~/modules/core/constants';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToTasksIssueCommentArray } from '~/modules/tasks/api/comments/_transformers';
import {
    TasksIssueAssigneeInfo,
    TasksIssueClosedInfo,
    TasksIssueCreateFormData,
    TasksIssueDetail,
    TasksIssueDueDateFormData,
    TasksIssueDueDateInfo,
    TasksIssueEstimatedTimeFormData,
    TasksIssueEstimatedTimeInfo,
    TasksIssueInfo,
    TasksIssueInfoShort,
    TasksIssuePriorityInfo,
    TasksIssueStateInfo,
} from './_types';
import { rawToAppFileInfoArray } from '~/modules/core/api/files/_transformers';
import { rawToProjectInfoShort } from '~/modules/core/api/projects/_transformers';
import { rawToUserSimpleInfoShort } from '~/modules/core/api/users/_transformers';
import { createEstimatedTime } from '~/modules/tasks/utils/estimatedTimeUtils';

export const rawToTasksIssueInfoShort: ApiResponseTransformer<TasksIssueInfoShort | null> = (data) => {
    if (data == null) return null;

    const project = rawToProjectInfoShort(data.Project);
    if (project == null) {
        console.error(
            "[TasksIssueInfoShortTransformer] - Property 'Project' is required but [ProjectInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name']),
        Project: project,
    };
};

export const rawToTasksIssueInfo: ApiResponseTransformer<TasksIssueInfo | null> = (data) => {
    if (data == null) return null;

    const createdBy = rawToUserSimpleInfoShort(data.CreatedBy);
    if (createdBy == null) {
        console.error(
            "[TasksIssueInfoTransformer] - Property 'CreatedBy' is required but [UserSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const project = rawToProjectInfoShort(data.Project);
    if (project == null) {
        console.error(
            "[TasksIssueInfoTransformer] - Property 'Project' is required but [ProjectInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name', 'CommentsCount', 'State', 'Priority', 'EstimatedTimeCompliance']),
        CreatedBy: createdBy,
        Assignee: rawToUserSimpleInfoShort(data.Assignee),
        Project: project,
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        UpdatedAt: DateTime.fromISO(data.UpdatedAt),
        ClosedAt: data.ClosedAt ? DateTime.fromISO(data.ClosedAt) : null,
        BeginAt: data.BeginAt ? DateTime.fromISO(data.BeginAt) : null,
        FinishAt: data.FinishAt ? DateTime.fromISO(data.FinishAt) : null,
        EstimatedTime: data.EstimatedTime == null ? null : Duration.fromObject({ seconds: data.EstimatedTime }),
        EstimatedTimeApprovedBy: rawToUserSimpleInfoShort(data.EstimatedTimeApprovedBy),
        EstimatedTimeApprovedAt: data.EstimatedTimeApprovedAt ? DateTime.fromISO(data.EstimatedTimeApprovedAt) : null,
    };
};

export const rawToTasksIssueDetail: ApiResponseTransformer<TasksIssueDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToTasksIssueInfo(data);
    if (info == null) {
        console.error(
            '[TasksIssueInfoTransformer] - [TasksIssueInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        SpentTime: data.SpentTime == null ? null : Duration.fromObject({ seconds: data.SpentTime }),
        Body: data.Body,
        Attachments: rawToAppFileInfoArray(data.Attachments) || [],
        Comments: rawToTasksIssueCommentArray(data.Comments) || [],
    };
};

export const rawToTasksIssueAssigneeInfo: ApiResponseTransformer<TasksIssueAssigneeInfo | null> = (data) => {
    return {
        Assignee: rawToUserSimpleInfoShort(data.Assignee),
    };
};

export const rawToTasksIssuePriorityInfo: ApiResponseTransformer<TasksIssuePriorityInfo | null> = (data) => {
    return {
        Priority: data.Priority,
    };
};

export const rawToTasksIssueStateInfo: ApiResponseTransformer<TasksIssueStateInfo | null> = (data) => {
    return {
        State: data.State,
    };
};

export const rawToTasksIssueDueDateInfo: ApiResponseTransformer<TasksIssueDueDateInfo | null> = (data) => {
    return {
        BeginAt: data.BeginAt ? DateTime.fromISO(data.BeginAt) : null,
        FinishAt: data.FinishAt ? DateTime.fromISO(data.FinishAt) : null,
    };
};

export const rawToTasksIssueEstimatedTimeInfo: ApiResponseTransformer<TasksIssueEstimatedTimeInfo | null> = (data) => {
    return {
        EstimatedTime: data.EstimatedTime == null ? null : Duration.fromObject({ seconds: data.EstimatedTime }),
        EstimatedTimeCompliance: data.EstimatedTimeCompliance,
        EstimatedTimeApprovedBy: rawToUserSimpleInfoShort(data.EstimatedTimeApprovedBy),
        EstimatedTimeApprovedAt: data.EstimatedTimeApprovedAt ? DateTime.fromISO(data.EstimatedTimeApprovedAt) : null,
    };
};

export const rawToTasksIssueClosedInfo: ApiResponseTransformer<TasksIssueClosedInfo | null> = (data) => {
    return {
        ClosedAt: data.ClosedAt ? DateTime.fromISO(data.ClosedAt) : null,
    };
};

export const rawToTasksIssueInfoArray: ApiResponseTransformer<TasksIssueInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToTasksIssueInfo(x)).filter((x): x is TasksIssueInfo => !!x);
};

export const rawToTasksIssueInfoShortArray: ApiResponseTransformer<TasksIssueInfoShort[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToTasksIssueInfoShort(x)).filter((x): x is TasksIssueInfoShort => !!x);
};

export const tasksIssueCreateFormDataToRaw = (data: Partial<TasksIssueCreateFormData>) => {
    return {
        ...data,
        BeginAt: data.BeginAt?.toFormat(API_DATE_FORMAT) || null,
        FinishAt: data.FinishAt?.toFormat(API_DATE_FORMAT) || null,
    };
};

export const tasksIssueDueDateFormDataToRaw = (data: Partial<TasksIssueDueDateFormData>) => {
    return {
        BeginAt: data.BeginAt?.toFormat(API_DATE_FORMAT) || null,
        FinishAt: data.FinishAt?.toFormat(API_DATE_FORMAT) || null,
    };
};

export const tasksIssueEstimatedTimeFormDataToRaw = (data: Partial<TasksIssueEstimatedTimeFormData>) => {
    return {
        EstimatedTime:
            data.EstimatedTime == null ? data.EstimatedTime : createEstimatedTime(data.EstimatedTime).totalMin * 60,
    };
};

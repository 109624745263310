import config from '~/plugins/config';
import { BrowserStorage } from './_types';

const appLocalStorage = (): BrowserStorage => {
    const set: BrowserStorage['set'] = (key, value) => {
        if (config.storage.type !== 'local') return;

        localStorage.setItem(key, JSON.stringify(value));
    };

    const get: BrowserStorage['get'] = (key, defaultValue = null) => {
        try {
            const value = localStorage.getItem(key) ?? '';
            return JSON.parse(value);
        } catch {
            return defaultValue;
        }
    };

    const remove: BrowserStorage['remove'] = (key) => {
        if (config.storage.type !== 'local') return;

        localStorage.removeItem(key);
    };

    const clear: BrowserStorage['clear'] = () => {
        if (config.storage.type !== 'local') return;

        localStorage.clear();
    };

    return {
        set,
        get,
        remove,
        clear,
    };
};

export default appLocalStorage;

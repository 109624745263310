import { ProjectDetail, ProjectUserDetail } from '~/modules/core/api/projects/_types';

const useProjectStore = defineStore('project', () => {
    const activeProject = ref<ProjectDetail | null>(null);
    const activeProjectUser = ref<ProjectUserDetail | null>(null);

    const reset = () => {
        activeProject.value = null;
    };

    return {
        activeProject,
        activeProjectUser,
        reset,
    };
});

export default useProjectStore;

import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationActionInfo } from '~/plugins/notificationManager/_types';

export enum NotificationActionDispatchingKey {
    Project = 'DispatchingProject',
    Issue = 'DispatchingIssue',
    IssueComment = 'DispatchingIssueComment',
}

export const getActions = (): NotificationActionInfo[] => {
    return [
        {
            group: NotificationActionDispatchingKey.Project,
            actionKey: NotificationActionDispatchingKey.Project,
            label: `notifications.actions.${NotificationActionDispatchingKey.Project}.actionTitle`,
            canExecuteFn: (info) => info.RouteParams != null,
            executeFn: async (info) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'dispatching-id',
                    params: info.RouteParams,
                });
            },
        },
        {
            group: NotificationActionDispatchingKey.Issue,
            actionKey: NotificationActionDispatchingKey.Issue,
            label: `notifications.actions.${NotificationActionDispatchingKey.Issue}.actionTitle`,
            canExecuteFn: (info) => info.RouteParams != null,
            executeFn: async (info) => {
                if (info.RouteParams == null) return false;
                return await executeRoute({
                    name: 'dispatching-issues-id',
                    params: info.RouteParams,
                });
            },
        },
        {
            group: NotificationActionDispatchingKey.IssueComment,
            actionKey: NotificationActionDispatchingKey.IssueComment,
            label: `notifications.actions.${NotificationActionDispatchingKey.IssueComment}.actionTitle`,
            canExecuteFn: (info) => info.RouteParams?.issueDisplayId != null,
            executeFn: async (info) => {
                if (info.RouteParams?.issueDisplayId == null) return false;

                return await executeRoute({
                    name: 'dispatching-issues-id',
                    params: {
                        id: info.RouteParams.issueDisplayId,
                    },
                    hash: info.RouteParams?.id != null ? `#comment_${info.RouteParams.id}` : undefined,
                });
            },
        },
    ];
};

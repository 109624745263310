import { GlobalPermission } from '~/modules/core/enums/permissions/_types';
import usePermissions from './usePermissions';

const usePermissionUser = () => {
    const { can } = usePermissions();

    const canDetailAny = (): boolean => {
        return can([GlobalPermission.UserDetail]);
    };

    const canCreateAny = (): boolean => {
        return can([GlobalPermission.UserCreate]);
    };

    const canUpdateAny = (): boolean => {
        return can([GlobalPermission.UserUpdate]);
    };

    const canManageAny = (): boolean => {
        return can([GlobalPermission.UserUpdate, GlobalPermission.UserCreate, GlobalPermission.UserDetail]);
    };

    return {
        canDetailAny,
        canManageAny,
        canCreateAny,
        canUpdateAny,
    };
};

export default usePermissionUser;

import { ReportTemplateDetail } from '~/modules/reporting/api/reports/_types';

const useTemplateDetailStore = defineStore('template-detail', () => {
    const templateDetail = ref<ReportTemplateDetail | null>(null);

    return {
        templateDetail,
    };
});

export default useTemplateDetailStore;

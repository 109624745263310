import { App } from 'vue';
import { AppPlugin } from '~/plugins/_types';
import { orderBy } from 'lodash-es';
import { debug } from '~/utils/debugUtilts';

export const installPlugins = (app: App) => {
    const plugins = import.meta.glob<AppPlugin>('./**/*_plugin.ts', { eager: true, import: 'default' });
    const sortedPlugins = orderBy(plugins, 'priority', 'desc');

    sortedPlugins.map(async ({ install, name, priority }) => {
        await install(app).then(() => debug(`[Plugin manager] > Installed ${name}`, { name, priority }));
    });
};

import { ProjectPermission, SubjectPermission } from '~/modules/core/enums/permissions/_types';
import { SubjectInfo, SubjectProjectInfo } from '~/modules/core/api/subjects/_types';
import usePermissions from './usePermissions';

const usePermissionSubject = () => {
    const { can, canPermission, canSubject } = usePermissions();

    const canCreateAny = (): boolean => {
        return can([SubjectPermission.Create]);
    };

    const canCreateSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.Create], subject);
    };

    const canCreateAnyProject = (): boolean => {
        return can([SubjectPermission.ProjectCreate]);
    };

    const canEditSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.Update], subject);
    };

    const canDetailSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.Detail], subject);
    };

    const canUsersManageSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.UserManage], subject);
    };

    const canContactsManageSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.ContactManage], subject);
    };

    const canManageProjectInvitationsSubject = (subject: SubjectInfo): boolean => {
        return canSubject([SubjectPermission.AcceptProjectInvitations], subject);
    };

    const canDetailSubjectProject = (project: SubjectProjectInfo | null): boolean => {
        return canPermission([ProjectPermission.Detail], project?.Permissions || null);
    };

    const canManageAny = (): boolean => {
        return can([
            SubjectPermission.Create,
            SubjectPermission.Update,
            SubjectPermission.Delete,
            SubjectPermission.ContactManage,
            SubjectPermission.UserManage,
            SubjectPermission.AcceptProjectInvitations,
        ]);
    };

    const filterCanCreateSubject = (subjects: SubjectInfo[]) => {
        return subjects.filter((x) => canCreateSubject(x));
    };

    const filterUserManageProjects = (projects: SubjectProjectInfo[]) => {
        const p = [ProjectPermission.UserManage];
        return projects.filter((x) => canPermission(p, x.Permissions));
    };

    return {
        canCreateAny,
        canCreateSubject,
        canEditSubject,
        canDetailSubject,
        canUsersManageSubject,
        canContactsManageSubject,
        canManageAny,
        canCreateAnyProject,
        canManageProjectInvitationsSubject,
        filterCanCreateSubject,
        filterUserManageProjects,
        canDetailSubjectProject,
    };
};

export default usePermissionSubject;

import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionGlobal from '~/modules/core/composables/permissions/usePermissionsGlobal';

export const redirects = (): RoutePageRedirects => {
    const { canLabelsManage } = usePermissionGlobal();
    return {
        'settings-labels': () => (canLabelsManage() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'settings-labels-new': () => (canLabelsManage() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

import { omit } from 'lodash-es';
import { FetchListType } from '~/modules/core/enums/_types';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import {
    projectInvitationFormDataToRaw,
    rawToProjectDetail,
    rawToProjectInfoArray,
    rawToProjectSubjectBindInfoArray,
    rawToProjectSubjectUnassignedUserInfoArray,
    rawToProjectTemplateInfoArray,
    rawToProjectUserDetail,
    rawToProjectUserInfoArray,
} from './_transformers';
import {
    ProjectDetail,
    ProjectApiFormData,
    ProjectInfo,
    ProjectTemplateInfo,
    ProjectUserInfo,
    ProjectUserDetail,
    ProjectUserFormData,
    ProjectSubjectBindInfo,
    ProjectInvitationFormData,
    ProjectSubjectUnassignedUserInfo,
} from './_types';

// > Endpoint prefix
const apiProjectsEndpointPrefix = 'projects';
const apiProjectSubjectEndpointPrefix = 'project-subject';

export const fetchProjectsRequest: ApiHandlerWithData<
    { type: FetchListType; opt?: FetchListOptions },
    ApiResponseContext<ProjectInfo[] | null, null, PaginationMeta>
> = async (args: { type: FetchListType; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, `list/${args.type}`), {
        transform: rawToProjectInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const deleteProjectFavoriteRequest: ApiHandlerWithData<number, ApiResponseContext<never>> = async (id) => {
    return api.delete(formatApiUrl(apiProjectsEndpointPrefix, `${id}/favorite`));
};

export const postProjectFavoriteRequest: ApiHandlerWithData<number, ApiResponseContext<never>> = async (id) => {
    return api.post(formatApiUrl(apiProjectsEndpointPrefix, `${id}/favorite`));
};

export const postProjectRequest: ApiHandlerWithData<
    ProjectApiFormData,
    ApiResponseContext<ProjectDetail | null, ProjectApiFormData>
> = async (data) => {
    return api.post(formatApiUrl(apiProjectsEndpointPrefix), data, { transform: rawToProjectDetail });
};

export const putProjectRequest: ApiHandlerWithData<
    {
        id: number;
        data: ProjectApiFormData;
    },
    ApiResponseContext<ProjectDetail | null, ProjectApiFormData>
> = async ({ id, data }) => {
    return api.put(formatApiUrl(apiProjectsEndpointPrefix, id), omit(data, ['ModuleId', 'SubjectId']), {
        transform: rawToProjectDetail,
    });
};

export const deleteProjectRequest: ApiHandlerWithData<number, ApiResponseContext<ProjectDetail | null>> = async (
    projectId
) => {
    return api.delete(formatApiUrl(apiProjectsEndpointPrefix, `${projectId}`));
};

export const fetchProjectDetailRequest: ApiHandlerWithData<number, ApiResponseContext<ProjectDetail | null>> = async (
    projectId: number
) => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, projectId), {
        transform: rawToProjectDetail,
    });
};

export const fetchProjectTemplatesRequest: ApiHandler<ApiResponseContext<ProjectTemplateInfo[] | null>> = async () => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, 'templates'), {
        transform: rawToProjectTemplateInfoArray,
    });
};

export const fetchProjectUsersRequest: ApiHandlerWithData<
    { projectId: number; opt?: FetchListOptions },
    ApiResponseContext<ProjectUserInfo[] | null, null, PaginationMeta>
> = async (args: { projectId: number; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, `${args.projectId}/users`), {
        transform: rawToProjectUserInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchProjectUserDetailRequest: ApiHandlerWithData<
    { projectId: number; userId: number },
    ApiResponseContext<ProjectUserDetail | null>
> = async (args: { projectId: number; userId: number }) => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, `${args.projectId}/users/${args.userId}`), {
        transform: rawToProjectUserDetail,
    });
};

export const postProjectUserRequest: ApiHandlerWithData<
    {
        projectId: number;
        data: ProjectUserFormData;
    },
    ApiResponseContext<null, ProjectUserFormData>
> = async ({ projectId, data }) => {
    return api.post(formatApiUrl(apiProjectsEndpointPrefix, `${projectId}/users`), data);
};

export const patchProjectUserRequest: ApiHandlerWithData<
    {
        projectId: number;
        userId: number;
        data: ProjectUserFormData;
    },
    ApiResponseContext<ProjectUserDetail | null, ProjectUserFormData>
> = async ({ projectId, userId, data }) => {
    return api.patch(formatApiUrl(apiProjectsEndpointPrefix, `${projectId}/users/${userId}`), omit(data, ['Users']), {
        transform: rawToProjectUserDetail,
    });
};

export const deleteProjectUserRequest: ApiHandlerWithData<
    { projectId: number; userId: number },
    ApiResponseContext<null>
> = async (args: { projectId: number; userId: number }) => {
    return api.delete(formatApiUrl(apiProjectsEndpointPrefix, `${args.projectId}/users/${args.userId}`));
};

export const patchProjectSubjectBindingAcceptRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<ProjectSubjectBindInfo>
> = async (projectSubjectId) => {
    return api.patch(formatApiUrl(apiProjectSubjectEndpointPrefix, `${projectSubjectId}/accept`));
};

export const deleteProjectSubjectBindingRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<ProjectSubjectBindInfo>
> = async (projectSubjectId) => {
    return api.delete(formatApiUrl(apiProjectSubjectEndpointPrefix, projectSubjectId));
};

export const postProjectInvitationsRequest: ApiHandlerWithData<
    {
        projectId: number;
        data: ProjectInvitationFormData;
    },
    ApiResponseContext<ProjectSubjectBindInfo[], ProjectInvitationFormData>
> = async ({ projectId, data }) => {
    return api.post(
        formatApiUrl(apiProjectsEndpointPrefix, `${projectId}/invitations`),
        projectInvitationFormDataToRaw(data),
        {
            transform: rawToProjectSubjectBindInfoArray,
        }
    );
};

export const fetchProjectUnassignedUsersRequest: ApiHandlerWithData<
    { projectId: number; opt?: FetchListOptions },
    ApiResponseContext<ProjectSubjectUnassignedUserInfo[] | null, null, PaginationMeta>
> = async (args: { projectId: number; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiProjectsEndpointPrefix, `${args.projectId}/users/unassigned`), {
        transform: rawToProjectSubjectUnassignedUserInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(args.opt),
    });
};

import { InventoryProductDetail } from '~/modules/inventory/api/products/_types';

const useInventoryProductStore = defineStore('inventory-product', () => {
    const activeProduct = ref<InventoryProductDetail | null>(null);

    const reset = () => {
        activeProduct.value = null;
    };

    return {
        activeProduct,
        reset,
    };
});

export default useInventoryProductStore;

import { TasksIssueComment } from '~/modules/tasks/api/comments/_types';
import { TasksIssueDetail } from '~/modules/tasks/api/issues/_types';

export const useTasksIssueStore = defineStore('tasks-issue', () => {
    const activeIssue = ref<TasksIssueDetail | null>(null);
    const isMenuOpen = ref(false);
    const inMiniMode = ref(false);

    const updateIssue = (val: Partial<TasksIssueDetail>) => {
        if (activeIssue.value == null) return;

        activeIssue.value = {
            ...activeIssue.value,
            ...val,
        };
    };

    const addOrReplaceComment = (comment: TasksIssueComment) => {
        if (activeIssue.value == null) {
            return;
        }

        const cIndex = activeIssue.value.Comments.findIndex((x) => x.Id === comment.Id);

        if (cIndex < 0) {
            activeIssue.value.Comments.push(comment);
        } else {
            activeIssue.value.Comments[cIndex] = comment;
        }
    };

    const deleteComment = (commentId: number) => {
        if (activeIssue.value == null) {
            return;
        }

        activeIssue.value.Comments = activeIssue.value.Comments.filter((x) => x.Id !== commentId);
    };

    const reset = (): void => {
        activeIssue.value = null;
    };

    return {
        activeIssue,
        isMenuOpen,
        inMiniMode,
        addOrReplaceComment,
        deleteComment,
        reset,
        updateIssue,
    };
});

import { RouteLocationNormalized } from 'vue-router';
import { RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionsHumanitarianCare from '~/modules/humanitarian-care/composables/permissions/usePermissionsHC';
import { checkApiResponseRedirect } from '~/plugins/router/utils';

export const redirects = (): RoutePageRedirects => {
    const { getProjectDetailFromRoute } = usePermissionProject();

    const { canCreateTransaction, canManageSettings } = usePermissionsHumanitarianCare();

    const canGoNewTransaction = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canCreateTransaction(response.data));
    };

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canManageSettings(response.data));
    };

    return {
        'humanitarian-care-id-new': canGoNewTransaction,
        'humanitarian-care-id-settings': canGoSettings,
        'humanitarian-care-id-settings-section': canGoSettings,
    };
};

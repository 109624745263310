import { NavigationGuardWithThis, NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import useHRWorkerStore from '~/modules/human-resources/stores/worker';
import { HRFolderKey } from '~/modules/human-resources/enums/_types';
import useHREnumsStore from '~/modules/human-resources/stores/hrEnums';

export const after: NavigationHookAfter = (to) => {
    resetFromHRModuleRoute(to);
    resetFromHRWorkerDetailRoute(to);
};

export const before: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    if (to.name?.toString() === 'human-resources-id') {
        await next({
            name: 'human-resources-id-section',
            params: {
                id: to.params.id,
                section: HRFolderKey.Recruitment,
            },
        });
        return true;
    }
    return false;
};

const resetFromHRWorkerDetailRoute = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('human-resources-workers-id')) {
        const workerStore = useHRWorkerStore();
        workerStore.reset();
    }
};

const resetFromHRModuleRoute = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('human-resources') && to.name?.toString() !== 'human-resources') {
        const enumsStore = useHREnumsStore();
        enumsStore.reset();
    }
};

import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import { useDispatchingIssueStore } from '~/modules/dispatching/stores/dispatchingIssue';

export const after: NavigationHookAfter = (to) => {
    resetSubjectStore(to);
};

const resetSubjectStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('dispatching-issues-id')) {
        const store = useDispatchingIssueStore();
        store.reset();
    }
};

import { LabelInfo } from '~/modules/core/api/labels/_types';

const useLabelsStore = defineStore('labels', () => {
    const labels = ref<Array<LabelInfo>>([]);

    const setLabels = (newLabels: LabelInfo[]) => {
        labels.value = newLabels;
    };

    return {
        labels,
        setLabels,
    };
});

export default useLabelsStore;

import { createApp } from 'vue';
import App from '~/App.vue';
import { installPlugins } from '~/plugins';

// > App CSS
import '~/css/app.scss';

// > Vue init
const app = createApp(App);
// > Install plugins
installPlugins(app);
// > Vue mount
app.mount('#app');

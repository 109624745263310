import config from '~/plugins/config';
import { createJobManager } from './manager';
import { JobInfo, JobManager } from './_types';

const jobManagerInstance: JobManager = createJobManager();

export const resolveJobs = (): JobInfo[] => {
    const jobsFiles = import.meta.glob<{ getJobs: () => JobInfo[] }>(`../../modules/**/jobs/_jobs.ts`, {
        eager: true,
    });

    const jobs: JobInfo[] = [];
    Object.entries(jobsFiles).forEach(async ([key, value]) => {
        const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/jobs/);

        if (matchedModule?.length !== 2) {
            console.warn('[Plugin JobManager]', `Can't load '${key}' jobs.`, jobsFiles);
            return;
        }

        const module = matchedModule[1];

        if (!config.isModuleSupported(module)) {
            console.log('[Plugin JobManager]', `Module '${module}' is not supported for jobs.`);
            return;
        }

        const moduleJobs = value.getJobs();
        jobs.push(...moduleJobs);
    });

    return jobs;
};

export default jobManagerInstance;

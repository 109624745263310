import usePermissionSubject from '~/modules/core/composables/permissions/usePermissionsSubject';
import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';

export const redirects = (): RoutePageRedirects => {
    const { canCreateAny, canManageAny } = usePermissionSubject();
    return {
        'settings-subjects': () => (canManageAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'settings-subjects-new': () => (canCreateAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

import { HttpStatusCode } from 'axios';
import { SubmissionContext } from 'vee-validate';
import { Router, START_LOCATION } from 'vue-router';
import { ApiErrors, ApiGenericValues, ApiResponseContext, ApiStatusCode } from '~/plugins/apiClient/_types';
import { goToAccessDeniedPage, goToNotFoundPage } from '~/plugins/router/utils';
import { FetchCollectionOptions, FetchDetailOptions, RedirectOptions, StoreOptions } from './_types';
import { AppFileDetail, UploadFileFormData } from '~/modules/core/api/files/_types';
import { isEmpty } from 'lodash-es';
import { Notify } from 'quasar';
import { t } from '~/plugins/i18n';

export const redirectIfApiError = async (
    router: Router,
    status: ApiStatusCode,
    opt?: RedirectOptions
): Promise<boolean> => {
    if (status === HttpStatusCode.NotFound && opt?.redirectIfNotFound) {
        await goToNotFoundPage(router);
        return true;
    }

    if (status === HttpStatusCode.Forbidden && opt?.redirectIfAccessDenied) {
        await goToAccessDeniedPage(router);
        return true;
    }

    return false;
};

export const fetchAndStoreCollection = async <TArgs, TData>(
    getDataFromStoreFn: () => Ref<TData[] | null>,
    fetchDataFn: (args?: TArgs | null) => Promise<{ isSuccess: boolean; status: ApiStatusCode; data: TData[] | null }>,
    args?: TArgs | null,
    opt?: FetchCollectionOptions
): Promise<{ isSuccess: boolean; data: TData[] | null }> => {
    const storeData = getDataFromStoreFn();

    if (storeData.value != null && !opt?.force && !opt?.noStore) {
        return { isSuccess: true, data: storeData.value };
    }

    const { isSuccess, data } = await fetchDataFn(args);

    if (isSuccess && !opt?.noStore) {
        storeData.value = data;
    }

    return { isSuccess, data };
};

export const fetchAndStoreData = async <TData>(
    id: number,
    router: Router,
    getDataFromStoreFn: () => Ref<TData | null>,
    fetchDataFn: (id: number) => Promise<{
        isSuccess: boolean;
        status: ApiStatusCode;
        isNotFoundError: boolean;
        isAuthError: boolean;
        data: TData | null;
    }>,
    opt?: FetchDetailOptions,
    storeOpt?: StoreOptions
): Promise<{ isSuccess: boolean; isNotFoundError: boolean; isAuthError: boolean; data: TData | null }> => {
    const storeData = getDataFromStoreFn();
    const idLabel = (storeOpt?.idKey ?? 'Id') as keyof TData;

    if (storeData.value && storeData.value[idLabel] === id && !opt?.force && !opt?.noStore) {
        return { isSuccess: true, isAuthError: false, isNotFoundError: false, data: storeData.value };
    }

    const { isSuccess, data, status, isAuthError, isNotFoundError } = await fetchDataFn(id);

    if (
        !opt?.noStore &&
        router.currentRoute.value != START_LOCATION && // https://gitlab.awebsys.cz/iskl/spa/-/issues/202
        !router.currentRoute.value.name?.toString().startsWith(opt?.routeNamePrefix ?? '')
    ) {
        return { isSuccess: false, isAuthError, isNotFoundError, data: null };
    }

    if (isSuccess && !opt?.noStore) {
        storeData.value = data;
    } else {
        await redirectIfApiError(router, status, opt);
    }

    return { isSuccess, data, isAuthError, isNotFoundError };
};

/**
 * @param args
 * @param values
 * @param setErrors
 * @param getDataFromStoreFn
 * @param updateDataFn
 * @param transformErrors
 */
export const updateAndStoreFormData: <TArgs, TValues extends ApiGenericValues, TData>(
    args: TArgs,
    values: TValues,
    ctx: SubmissionContext<TValues>,
    getDataFromStoreFn: () => Ref<TData | null>,
    updateDataFn: (
        args: TArgs,
        values: TValues
    ) => Promise<{ isSuccess: boolean; isValidationError: boolean; errors: ApiErrors<TValues>; data: TData | null }>,
    transformErrors?: (errors: ApiErrors<TValues>) => ApiErrors<TValues>
) => Promise<{ isSuccess: boolean; data: TData | null }> = async (
    args,
    values,
    { setErrors },
    getDataFromStoreFn,
    updateDataFn,
    transformErrors
) => {
    const storeData = getDataFromStoreFn();
    const { data, isSuccess, isValidationError, errors } = await updateDataFn(args, values);

    if (isSuccess) {
        storeData.value = data;
    } else if (isValidationError) {
        setErrors(transformErrors ? transformErrors(errors) : errors);
    }

    return { isSuccess, isValidationError, data };
};

/**
 * @param args
 * @param values
 * @param setErrors
 */
export const updateAndStoreData: <TValues extends ApiGenericValues, TData>(
    ctx: SubmissionContext<TValues>,
    getDataFromStoreFn: () => Ref<TData | null>,
    updateDataFn: () => Promise<{
        isSuccess: boolean;
        isValidationError: boolean;
        errors: ApiErrors<TValues>;
        data: TData | null;
    }>,
    transformErrors?: (errors: ApiErrors<TValues>) => ApiErrors<TValues>
) => Promise<{ isSuccess: boolean; data: TData | null }> = async (
    { setErrors },
    getDataFromStoreFn,
    updateDataFn,
    transformErrors
) => {
    const storeData = getDataFromStoreFn();
    const { data, isSuccess, isValidationError, errors } = await updateDataFn();

    if (isSuccess) {
        storeData.value = data;
    } else if (isValidationError) {
        setErrors(transformErrors ? transformErrors(errors) : errors);
    }

    return { isSuccess, isValidationError, data };
};

/**
 * @param ctx
 * @param requestFn
 * @param updateFn
 * @param transformErrors
 */
export const updateValuesAndStoreDataOrSetValidationErrors = async <T extends Partial<T>, R extends ApiGenericValues>(
    ctx: SubmissionContext<R>,
    requestFn: () => Promise<ApiResponseContext<T | null, R>>,
    updateFn?: (val: Partial<T>) => void,
    transformErrors?: (errors: ApiErrors<R>) => ApiErrors<R>
): Promise<{
    isSuccess: boolean;
    data: T | null;
}> => {
    const { isSuccess, isValidationError, data, errors } = await requestFn();

    if (isSuccess && data != null) {
        if (updateFn != null) updateFn(data);
    } else if (isValidationError) {
        ctx.setErrors(transformErrors ? transformErrors(errors) : errors);
    }

    return { isSuccess, data };
};

/**
 * @param requestFn
 * @param updateFn
 */
export const updateAndStoreDataOrNotifyError = async <T extends Partial<T>, R>(
    requestFn: () => Promise<ApiResponseContext<T | null, R>>,
    updateFn?: (val: Partial<T>) => void,
    translateLabelPrefix?: string
): Promise<{
    isSuccess: boolean;
    data: T | null;
}> => {
    const { isSuccess, isValidationError, data, errors } = await requestFn();

    if (isSuccess && data != null) {
        if (updateFn) updateFn(data);
    } else if (isValidationError) {
        const firstErrMsgKey = (Object.keys(errors) as Array<keyof typeof errors>).find((x) => !isEmpty(errors[x]));
        const msgKeys = firstErrMsgKey?.toString().split('.') ?? [];

        Notify.create({
            group: 'tasksIssue',
            type: 'negative',
            message: translateLabelPrefix
                ? t(`${translateLabelPrefix}.${msgKeys[msgKeys.length - 1]?.toString()}`) +
                  ': ' +
                  errors[firstErrMsgKey]?.toString()
                : errors[firstErrMsgKey]?.toString(),
        });
    }

    return { isSuccess, data };
};

export const uploadFileWithUploader = async (
    uploadFn: () => Promise<ApiResponseContext<AppFileDetail | null, UploadFileFormData, unknown>>
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: string | string[];
    appFile: AppFileDetail | null;
}> => {
    const { data, isSuccess, isValidationError, errors } = await uploadFn();
    let err: string | string[] = '';

    if (isValidationError) {
        err = errors.File || '';
    }

    return { isSuccess, isValidationError, errors: err, appFile: data };
};

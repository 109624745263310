export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const MAX_ROLE_ACCESS_LEVEL = 255;

export const NO_DATA_PLACEHOLDER = '---';

export const DATE_INPUT_PLACEHOLDER = 'dd.mm.yyyy';
export const DATETIME_INPUT_PLACEHOLDER = 'dd.mm.yyyy hh:mm';
export const DURATION_FORMAT = 'hh:mm:ss';

export const DATE_MONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
    year: undefined,
    month: 'long',
    day: 'numeric',
};

export const getFullName = (user: { FirstName: string; LastName: string }): string => {
    return `${user.FirstName} ${user.LastName}`;
};

export const getFullNameTitles = (user: {
    FirstName: string;
    LastName: string;
    TitleBefore: string | null;
    TitleAfter: string | null;
}): string => {
    const nameFragments = [user.TitleBefore || '', user.FirstName, user.LastName, user.TitleAfter || ''];
    return nameFragments.join(' ');
};

import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToUserSimpleInfoShort } from '~/modules/core/api/users/_transformers';
import { AppFileDetail, AppFileInfo } from './_types';

export const rawToAppFileInfo: ApiResponseTransformer<AppFileInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'DisplayName', 'FileName', 'FileType', 'FileSize', 'FileUrl', 'PublicUrl']),
    };
};

export const rawToAppFileDetail: ApiResponseTransformer<AppFileDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToAppFileInfo(data);
    if (info == null) {
        console.error(
            '[AppFileDetailTransformer] - [AppFileInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    const user = rawToUserSimpleInfoShort(data.User);
    if (user == null) {
        console.error(
            "[ProjectInfoShortTransformer] - Property 'User' is required but [UserSimpleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...info,
        ...pick(data, ['FileableId', 'FileableType', 'Type']),
        User: user,
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        UpdatedAt: DateTime.fromISO(data.UpdateAt),
        DeletedAt: data.DeletedAt ? DateTime.fromISO(data.DeletedAt) : null,
    };
};

export const rawToAppFileInfoArray: ApiResponseTransformer<AppFileInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToAppFileInfo(x)).filter((x): x is AppFileInfo => !!x);
};

export const rawToAppFileDetailArray: ApiResponseTransformer<AppFileDetail[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToAppFileDetail(x)).filter((x): x is AppFileDetail => !!x);
};

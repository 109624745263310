import { SettingsSectionKey } from '~/modules/core/enums/_types';
import usePermissionDocuments from '~/modules/core/composables/permissions/usePermissionsDocuments';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionSubject from '~/modules/core/composables/permissions/usePermissionsSubject';
import { SettingsSectionConfig } from './_types';
import { t } from '~/plugins/i18n';
import usePermissionGlobal from '~/modules/core/composables/permissions/usePermissionsGlobal';
import usePermissionUser from '~/modules/core/composables/permissions/usePermissionsUser';

export const getAppSettingsConfig: () => SettingsSectionConfig[] = () => {
    const { canManageAny: canManageAnySubject } = usePermissionSubject();
    const { canManageAny: canManageAnyUser } = usePermissionUser();
    const { canManageAny: canManageAnyProject } = usePermissionProject();
    const { canManage: canManageDocuments } = usePermissionDocuments();
    const { canLabelsManage } = usePermissionGlobal();

    return [
        {
            key: SettingsSectionKey.Subjects,
            icon: 'las la-folder',
            to: { name: 'settings-subjects' },
            title: t(`settings.sections.${SettingsSectionKey.Subjects}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Subjects}.action`),
            canShow: canManageAnySubject,
        },
        {
            key: SettingsSectionKey.Projects,
            icon: 'las la-project-diagram',
            to: { name: 'settings-projects' },
            title: t(`settings.sections.${SettingsSectionKey.Projects}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Projects}.action`),
            canShow: canManageAnyProject,
        },
        {
            key: SettingsSectionKey.Users,
            icon: 'las la-user',
            to: { name: 'settings-users' },
            title: t(`settings.sections.${SettingsSectionKey.Users}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Users}.action`),
            canShow: () => canManageAnyUser(),
        },
        {
            key: SettingsSectionKey.Documents,
            icon: 'las la-file',
            to: { name: 'settings-documents' },
            title: t(`settings.sections.${SettingsSectionKey.Documents}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Documents}.action`),
            canShow: canManageDocuments,
        },
        {
            key: SettingsSectionKey.Enums,
            icon: 'las la-list-ol',
            to: { name: 'settings-enums' },
            title: t(`settings.sections.${SettingsSectionKey.Enums}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Enums}.action`),
            canShow: () => false,
        },
        {
            key: SettingsSectionKey.Labels,
            icon: 'las la-tag',
            to: { name: 'settings-labels' },
            title: t(`settings.sections.${SettingsSectionKey.Labels}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.Labels}.action`),
            canShow: () => canLabelsManage(),
        },
        {
            key: SettingsSectionKey.AppSettings,
            icon: 'las la-cog',
            to: { name: 'settings-app-settings' },
            title: t(`settings.sections.${SettingsSectionKey.AppSettings}.title`),
            actionTitle: t(`settings.sections.${SettingsSectionKey.AppSettings}.action`),
            canShow: () => false,
        },
    ];
};

import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import {
    rawToLabelSimpleInfoArray,
    rawToProjectSimpleInfo,
    rawToRoleSimpleDetailArray,
    rawToRoleSimpleInfoArray,
    rawToSubjectSimpleInfo,
} from '~/modules/core/api/enums/_transformers';
import { rawToSubjectInfoShort } from '~/modules/core/api/subjects/_transformers';
import { rawToModuleInfoShort } from '~/modules/core/api/modules/_transformers';
import { ProjectSubjectBindType } from '~/modules/core/enums/_types';
import {
    ProjectDetail,
    ProjectInfo,
    ProjectInfoShort,
    ProjectInvitationFormData,
    ProjectSubjectBindInfo,
    ProjectSubjectInfo,
    ProjectSubjectUnassignedUserInfo,
    ProjectTemplateInfo,
    ProjectUserDetail,
    ProjectUserInfo,
    ProjectUserRoles,
    ProjectUserSubjectRoles,
} from './_types';
import { API_DATE_FORMAT, MAX_ROLE_ACCESS_LEVEL } from '~/modules/core/constants';
import { rawToUserDetail, rawToUserInfo, rawToUserSimpleInfoShortArray } from '~/modules/core/api/users/_transformers';

export const rawToProjectSubjectInfo: ApiResponseTransformer<ProjectSubjectInfo | null> = (data) => {
    if (data == null) return null;

    const subject = rawToSubjectInfoShort(data.Subject);
    if (subject == null) {
        console.error(
            "[ProjectSubjectInfoTransformer] - Data for property 'Subject' from [SubjectInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Type']),
        Subject: subject,
        AccessLevel: data.AccessLevel ?? MAX_ROLE_ACCESS_LEVEL,
        ExpiresAt: data.ExpiresAt == null ? null : DateTime.fromFormat(data.ExpiresAt, API_DATE_FORMAT),
        Permissions: Array.isArray(data.Permissions) ? new Set<string>(data.Permissions) : new Set<string>(),
    };
};

export const rawToProjectSubjectBindInfo: ApiResponseTransformer<ProjectSubjectInfo | null> = (data) => {
    if (data == null) return null;

    const info = rawToProjectSubjectInfo(data);
    if (info == null) {
        console.error(
            '[ProjectSubjectBindInfoTransformer] - [ProjectSubjectInfoShortTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    const project = rawToProjectSimpleInfo(data.Project);
    if (project == null) {
        console.error(
            "[ProjectSubjectBindInfoTransformer] - Data for property 'Project' from [ProjectSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...info,
        Project: project,
    };
};

export const rawToProjectInfoShort: ApiResponseTransformer<ProjectInfoShort | null> = (data) => {
    if (data == null) return null;

    const ownerSubject = rawToSubjectInfoShort(data.OwnerSubject);
    if (ownerSubject == null) {
        console.error(
            "[ProjectInfoShortTransformer] - Data for property 'OwnerSubject' from [SubjectInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const module = rawToModuleInfoShort(data.Module);
    if (module == null) {
        console.error(
            "[ProjectInfoShortTransformer] - Property 'Module' is required but [ModuleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name']),
        Module: module,
        OwnerSubject: ownerSubject,
    };
};

export const rawToProjectInfo: ApiResponseTransformer<ProjectInfo | null> = (data) => {
    if (data == null) return null;

    const info = rawToProjectInfoShort(data);
    if (info == null) {
        console.error(
            '[ProjectInfoTransformer] - [ProjectInfoShortTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        ...pick(data, ['Description', 'IsFavorite']),
        Labels: rawToLabelSimpleInfoArray(data.Labels) || [],
    };
};

export const rawToProjectDetail: ApiResponseTransformer<ProjectDetail | null> = (data) => {
    if (data == null) return null;

    const module = rawToModuleInfoShort(data.Module);
    if (module == null) {
        console.error(
            "[ProjectInfoShortTransformer] - Property 'Module' is required but [ModuleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const subjects = rawToProjectSubjectInfoArray(data.Subjects) || [];
    const ownerSubject = subjects.find((x) => x.Type === ProjectSubjectBindType.Owner)?.Subject;
    if (ownerSubject == null) {
        console.error(
            "[ProjectInfoTransformer] - Property 'OwnerSubject' is required but could not be found in `Subjects` property. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name', 'Description', 'IsFavorite']),
        Module: module,
        OwnerSubject: ownerSubject,
        Subjects: subjects,
        Labels: rawToLabelSimpleInfoArray(data.Labels) || [],
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        UpdatedAt: DateTime.fromISO(data.UpdatedAt),
        DeletedAt: data.DeletedAt ? DateTime.fromISO(data.DeletedAt) : null,
    };
};

export const rawToProjectTemplateInfo: ApiResponseTransformer<ProjectTemplateInfo | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Id', 'ModuleId', 'Name']),
    };
};

export const rawToProjectUserInfo: ApiResponseTransformer<ProjectUserInfo | null> = (data) => {
    if (data == null) return null;

    const info = rawToUserInfo(data);
    if (info == null) {
        console.error(
            '[SubjectUserInfoTransformer] - [UserInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        Roles: rawToRoleSimpleDetailArray(data.Roles) || [],
    };
};

export const rawToProjectUserDetail: ApiResponseTransformer<ProjectUserDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToUserDetail(data);
    if (info == null) {
        console.error(
            '[SubjectUserDetailTransformer] - [UserDetailTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        Subjects: rawToProjectUserSubjectRolesArray(data.Subjects) || [],
    };
};

export const rawToProjectUserSubjectRoles: ApiResponseTransformer<ProjectUserSubjectRoles | null> = (data) => {
    if (data == null) return null;

    const subject = rawToSubjectSimpleInfo(data.Subject);
    if (subject == null) {
        console.error(
            "[ProjectUserSubjectRolesTransformers] - Data for property 'Subject' from [SubjectSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const roles = rawToProjectUserRoles(data.ProjectRoles);
    if (roles == null) {
        console.error(
            "[ProjectUserSubjectRolesTransformers] - Data for property 'ProjectRoles' from [ProjectUserRolesTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        Subject: subject,
        ProjectRoles: roles,
    };
};

export const rawToProjectUserRoles: ApiResponseTransformer<ProjectUserRoles | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['ProjectSubjectId', 'Type']),
        Roles: rawToRoleSimpleInfoArray(data.Roles) || [],
    };
};

export const rawToProjectSubjectUnassignedUserInfo: ApiResponseTransformer<ProjectSubjectUnassignedUserInfo | null> = (
    data
) => {
    if (data == null) return null;

    const subject = rawToSubjectSimpleInfo(data.Subject);
    if (subject == null) {
        console.error(
            "[ProjectSubjectUnassignedUserInfoTransformer] - Data for property 'Subject' from [SubjectSimpleTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        Subject: subject,
        UnassignedUsers: rawToUserSimpleInfoShortArray(data.UnassignedUsers) || [],
    };
};

export const rawToProjectInfoArray: ApiResponseTransformer<ProjectInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectInfo(x)).filter((x): x is ProjectInfo => !!x);
};

export const rawToProjectTemplateInfoArray: ApiResponseTransformer<ProjectTemplateInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectTemplateInfo(x)).filter((x): x is ProjectTemplateInfo => !!x);
};

export const rawToProjectUserInfoArray: ApiResponseTransformer<ProjectUserInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectUserInfo(x)).filter((x): x is ProjectUserInfo => !!x);
};

export const rawToProjectUserSubjectRolesArray: ApiResponseTransformer<ProjectUserSubjectRoles[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectUserSubjectRoles(x)).filter((x): x is ProjectUserSubjectRoles => !!x);
};

export const rawToProjectSubjectInfoArray: ApiResponseTransformer<ProjectSubjectInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectSubjectInfo(x)).filter((x): x is ProjectSubjectInfo => !!x);
};

export const rawToProjectSubjectBindInfoArray: ApiResponseTransformer<ProjectSubjectBindInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectSubjectBindInfo(x)).filter((x): x is ProjectSubjectBindInfo => !!x);
};

export const rawToProjectSubjectUnassignedUserInfoArray: ApiResponseTransformer<
    ProjectSubjectUnassignedUserInfo[] | null
> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data
        .map((x) => rawToProjectSubjectUnassignedUserInfo(x))
        .filter((x): x is ProjectSubjectUnassignedUserInfo => !!x);
};

export const projectInvitationFormDataToRaw = (data: ProjectInvitationFormData) => ({
    ...data,
    ExpiresAt: data.ExpiresAt?.toFormat(API_DATE_FORMAT) || null,
    Subjects: data.Subjects.map((x) => x.Id),
});

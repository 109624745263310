import { NavigationGuardWithThis, NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import { ModuleKey } from '~/modules/core/enums/_types';
import useProjectStore from '~/modules/core/stores/project';
import useSubjectStore from '~/modules/core/stores/subject';
import { getModuleRouteOverviewName } from '~/modules/core/utils/routeUtils';
import { ProjectDetail } from '~/modules/core/api/projects/_types';
import { UserSettingsTab } from '~/modules/core/components/users/detail/_types';
import useUserStore from '~/modules/core/stores/settingsUser';

export const after: NavigationHookAfter = (to) => {
    resetProjectStore(to);
    resetSubjectStore(to);
    resetUserStore(to);
};

export const before: NavigationGuardWithThis<undefined> = (to, from, next) => {
    if (to.name?.toString() === 'settings-users-id') {
        next({
            name: 'settings-users-id-section',
            params: {
                id: to.params.id,
                section: UserSettingsTab.Info,
            },
        });
        return true;
    }
    return false;
};

const resetSubjectStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('settings-subjects-id')) {
        const store = useSubjectStore();
        store.reset();
    }
};

const resetUserStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('settings-users-id')) {
        const store = useUserStore();
        store.reset();
    }
};

const resetProjectStore = (to: RouteLocationNormalized) => {
    const store = useProjectStore();
    if (store.activeProject == null) return;

    if (!isProjectDetailRoute(to) || !isProjectFromSameModule(to, store.activeProject)) {
        store.reset();
    }
};

const isProjectFromSameModule = (to: RouteLocationNormalized, project: ProjectDetail) => {
    const moduleRoute = getModuleRouteOverviewName(project.Module.Key);
    return to.name?.toString().startsWith(moduleRoute);
};

const isProjectDetailRoute = (to: RouteLocationNormalized) => {
    return (
        Object.values(ModuleKey)
            .map((x) => getModuleRouteOverviewName(x))
            // if it starts with module overview name (which should be detail page and its subpages) but it is not the overview page itself
            .some((x) => to.name?.toString() !== x && to.name?.toString().startsWith(x))
    );
};

import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { NotificationAction } from '~/modules/core/enums/_types';
import { rawToNotificationInfoDetail, rawToNotificationInfoList, rawToNotificationStatusInfo } from './_transformers';
import { NotificationInfoDetail, NotificationInfoList, NotificationStatusInfo } from './_types';

// > Endpoint prefix
const apiEndpointPrefix = 'notifications';

export const fetchNotificationsRequest: ApiHandlerWithArgs<
    FetchListOptions,
    ApiResponseContext<NotificationInfoList | null, null, PaginationMeta>
> = async (opt?: FetchListOptions) => {
    return api.get(formatApiUrl(apiEndpointPrefix), {
        transform: rawToNotificationInfoList,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchNotificationsStatusRequest: ApiHandler<
    ApiResponseContext<NotificationStatusInfo | null>
> = async () => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'info'), {
        transform: rawToNotificationStatusInfo,
    });
};

export const patchNotificationActionRequest: ApiHandlerWithData<
    { id: number; action: NotificationAction },
    ApiResponseContext<NotificationInfoDetail>
> = async ({ id, action }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `${id}/${action}`), {
        transform: rawToNotificationInfoDetail,
    });
};

import { pick } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { ModuleInfo, ModuleInfoShort } from './_types';

export const rawToModuleInfoShort: ApiResponseTransformer<ModuleInfoShort | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Key']),
    };
};

export const rawToModuleInfo: ApiResponseTransformer<ModuleInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Key']),
        Permissions: Array.isArray(data.Permissions) ? new Set<string>(data.Permissions) : new Set<string>(),
    };
};

export const rawToModuleInfoArray: ApiResponseTransformer<ModuleInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToModuleInfo(x)).filter((x): x is ModuleInfo => !!x);
};

import { GlobalPermission } from '~/modules/core/enums/permissions/_types';
import usePermissions from './usePermissions';

const usePermissionDocuments = () => {
    const { can } = usePermissions();

    const canManage = (): boolean => {
        return can([GlobalPermission.CrisisDocumentManage]);
    };

    return {
        canManage,
    };
};

export default usePermissionDocuments;

import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import {
    ReportDetail,
    ReportInfo,
    ReportingInfoSender,
    ReportTemplateInfo,
    ReportTemplateDetail,
} from '~/modules/reporting/api/reports/_types';
import {
    rawToReportingInfoSender,
    rawToReportDetail,
    rawToReportInfoArray,
    rawToReportTemplateInfoArray,
    rawToReportTemplateDetail,
} from '~/modules/reporting/api/reports/_transformers';
import { AppFileInfo, UploadFileFormData } from '~/modules/core/api/files/_types';
import { rawToAppFileInfo } from '~/modules/core/api/files/_transformers';
import { ReportFormData } from '~/modules/reporting/api/reports/_types';

// > Endpoint prefix
const apiEndpointPrefix = 'reporting';

export const fetchReportingReportListRequest: ApiHandlerWithData<
    { projectId: number; opt?: FetchListOptions },
    ApiResponseContext<ReportInfo[] | null, null, PaginationMeta>
> = async (args: { projectId: number; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `${args.projectId}/reports`), {
        transform: rawToReportInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchReportingReportDetailRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<ReportDetail | null, null, PaginationMeta>
> = async (reportId: number) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `reports/${reportId}`), {
        transform: rawToReportDetail,
    });
};

export const fetchReportingSenderRequest: ApiHandlerWithData<
    { projectId: number },
    ApiResponseContext<ReportingInfoSender | null, null, null>
> = async (args: { projectId: number }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `${args.projectId}/sender`), {
        transform: rawToReportingInfoSender,
    });
};

export const fetchReportingTemplateListRequest: ApiHandlerWithData<
    { projectId: number; opt?: FetchListOptions },
    ApiResponseContext<ReportTemplateInfo[] | null, null, PaginationMeta>
> = async (args: { projectId: number; opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `${args.projectId}/templates`), {
        transform: rawToReportTemplateInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchReportingTemplateDetailRequest: ApiHandlerWithData<
    { projectId: number; templateId: number },
    ApiResponseContext<ReportTemplateDetail | null, null, PaginationMeta>
> = async (args: { projectId: number; templateId: number }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `${args.projectId}/templates/${args.templateId}`), {
        transform: rawToReportTemplateDetail,
    });
};

export const postReportingReportRequest: ApiHandlerWithData<
    {
        projectId: number;
        data: ReportFormData;
    },
    ApiResponseContext<ReportDetail, ReportFormData>
> = async ({ projectId, data }) => {
    return api.post(formatApiUrl(apiEndpointPrefix, `${projectId}/reports`), data);
};

export const postReportingAttachmentRequest: ApiHandlerWithData<
    { projectId: number; data: UploadFileFormData },
    ApiResponseContext<AppFileInfo | null, UploadFileFormData>
> = async ({ projectId, data }) => {
    return api.post(formatApiUrl(apiEndpointPrefix, `${projectId}/reports/file`), data, {
        transform: rawToAppFileInfo,
    });
};

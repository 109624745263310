import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import useStatisticsMasterProductStore from '~/modules/statistics/stores/masterProduct';

export const after: NavigationHookAfter = (to) => {
    resetMasterProductStore(to);
};

const resetMasterProductStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('statistics-products-id')) {
        const store = useStatisticsMasterProductStore();
        store.reset();
    }
};

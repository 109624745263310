import { RouteLocationNormalized } from 'vue-router';
import usePermissionDispatchingEnum from '~/modules/dispatching/composables/permissions/usePermissionsDispatchingEnum';
import usePermissionDispatchingIssue from '~/modules/dispatching/composables/permissions/usePermissionsDispatchingIssue';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import { checkApiResponseRedirect } from '~/plugins/router/utils';
import { RoutePageRedirects } from '~/plugins/router/_types';

export const redirects = (): RoutePageRedirects => {
    const { getProjectDetailFromRoute } = usePermissionProject();

    const { canManageEnums } = usePermissionDispatchingEnum();
    const { canCreateIssue } = usePermissionDispatchingIssue();

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canManageEnums(response.data));
    };

    const canGoNewIssue = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canCreateIssue(response.data));
    };

    return {
        'dispatching-id-new': canGoNewIssue,
        'dispatching-id-settings': canGoSettings,
    };
};

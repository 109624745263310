export enum ProjectSettingsTasksKey {
    Project = 'project',
}

export enum TasksIssueState {
    New = 'New',
    Waiting = 'Waiting',
    InProgress = 'InProgress',
    Resolved = 'Resolved',
    Closed = 'Closed',
    Testing = 'Testing',
    OnTest = 'OnTest',
    Reopened = 'Reopened',
}

export enum TasksIssuePriority {
    Low = 'Low',
    Normal = 'Normal',
    High = 'High',
    Urgent = 'Urgent',
}

export enum TasksAttachmentType {
    Issue = 'TasksIssueAttachment',
    Markdown = 'TasksIssueMarkdown',
}

export enum TasksTrackingManageTab {
    FinishedRecords = 'records',
    TimerRecords = 'timers',
    Reports = 'reports',
    Approve = 'approve',
}

export enum TasksIssuesTab {
    Open = 'open',
    Closed = 'closed',
    All = 'all',
}

export enum TasksGanttTaskSyncProperty {
    Assignee = 'assignee_id',
    Name = 'name',
    BeginAt = 'begin_at',
    FinishAt = 'finish_at',
    State = 'state',
    Priority = 'priority',
}

export const isTasksTrackingManageTab = (key: string): key is TasksTrackingManageTab => {
    return Object.values(TasksTrackingManageTab).some((x) => x === key);
};

import usePermissionDocuments from '~/modules/core/composables/permissions/usePermissionsDocuments';
import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';

export const redirects = (): RoutePageRedirects => {
    const { canManage } = usePermissionDocuments();

    return {
        'settings-documents': () => (canManage() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import useHCTransactionStore from '~/modules/humanitarian-care/stores/transactions';
import useHCPurposeStore from '~/modules/humanitarian-care/stores/purposes';
import useHCDashboardStore from '~/modules/humanitarian-care/stores/dashboard';

export const after: NavigationHookAfter = (to) => {
    resetHCTransactionStore(to);
    resetHCPurposeStore(to);
    resetHCDashboardStore(to);
};

const resetHCTransactionStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('humanitarian-care-transactions-id')) {
        const store = useHCTransactionStore();
        store.reset();
    }
};

const resetHCPurposeStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('humanitarian-care')) {
        const purposeStore = useHCPurposeStore();
        purposeStore.reset();
    }
};

const resetHCDashboardStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('humanitarian-care')) {
        const dashboardStore = useHCDashboardStore();
        dashboardStore.reset();
    }
};

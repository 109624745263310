export enum FilterOperator {
    Eq = 'eq',
    Neq = 'neq',
    Gt = 'gt',
    Gte = 'gte',
    Lt = 'lt',
    Lte = 'lte',
    Like = 'like',
}

export enum FilterStringOperator {
    Like = FilterOperator.Like,
}

export enum FilterBooleanOperator {
    Eq = FilterOperator.Eq,
    Neq = FilterOperator.Neq,
}

export enum FilterEnumOperator {
    Eq = FilterOperator.Eq,
    Neq = FilterOperator.Neq,
}

export enum FilterDateOperator {
    Eq = FilterOperator.Eq,
    Neq = FilterOperator.Neq,
    Gte = FilterOperator.Gte,
    Lte = FilterOperator.Lte,
}

export enum FilterNumberOperator {
    Eq = FilterOperator.Eq,
    Neq = FilterOperator.Neq,
    Gte = FilterOperator.Gte,
    Lte = FilterOperator.Lte,
    Gt = FilterOperator.Gt,
    Lt = FilterOperator.Lt,
}

export enum FilterBarDataType {
    Enum = 'enum',
    String = 'string',
    Number = 'number',
    Date = 'date',
    DateTime = 'datetime',
    Boolean = 'boolean',
}

import { pick } from 'lodash-es';
import {
    LabelSimpleInfo,
    ProjectSimpleInfo,
    RolesGroupedCollection,
    RolesGroupMap,
    RoleSimpleDetail,
    RoleSimpleInfo,
    SubjectSimpleInfo,
} from './_types';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToModuleInfoShort } from '~/modules/core/api/modules/_transformers';
import { ModuleKey, UserRoleType } from '~/modules/core/enums/_types';

export const rawToProjectSimpleInfo: ApiResponseTransformer<ProjectSimpleInfo | null> = (data) => {
    if (data == null) return null;

    const module = rawToModuleInfoShort(data.Module);
    if (module == null) {
        console.error(
            "[ProjectSimpleInfoTransformer] - Property 'Module' is required but [ModuleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name']),
        Module: module,
    };
};

export const rawToSubjectSimpleInfo: ApiResponseTransformer<SubjectSimpleInfo | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Id', 'Name']),
    };
};

export const rawToLabelSimpleInfo: ApiResponseTransformer<LabelSimpleInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Name']),
    };
};

export const rawToRoleSimpleInfo: ApiResponseTransformer<RoleSimpleInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Name']),
    };
};

export const rawToRoleSimpleDetail: ApiResponseTransformer<RoleSimpleDetail | null> = (data) => {
    if (data == null) return null;
    const module = rawToModuleInfoShort(data.Module);

    return {
        ...pick(data, ['Id', 'Name', 'Type']),
        AccessLevel: data.AccessLevel || 0,
        Module: module,
    };
};

export const rawToRolesGroupedCollection: ApiResponseTransformer<RolesGroupedCollection | null> = (data) => {
    if (data == null) return null;

    const roles = rawToRoleSimpleDetailArray(data) || [];
    const groupedRoles = getRolesGroupMap(roles);

    return {
        Roles: roles,
        GroupedRoles: groupedRoles,
    };
};

export const rawToProjectSimpleInfoArray: ApiResponseTransformer<ProjectSimpleInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToProjectSimpleInfo(x)).filter((x): x is ProjectSimpleInfo => !!x);
};

export const rawToSubjectSimpleInfoArray: ApiResponseTransformer<SubjectSimpleInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectSimpleInfo(x)).filter((x): x is SubjectSimpleInfo => !!x);
};

export const rawToLabelSimpleInfoArray: ApiResponseTransformer<LabelSimpleInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToLabelSimpleInfo(x)).filter((x): x is LabelSimpleInfo => !!x);
};

export const rawToRoleSimpleInfoArray: ApiResponseTransformer<RoleSimpleInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToRoleSimpleInfo(x)).filter((x): x is RoleSimpleInfo => !!x);
};

export const rawToRoleSimpleDetailArray: ApiResponseTransformer<RoleSimpleDetail[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToRoleSimpleDetail(x)).filter((x): x is RoleSimpleDetail => !!x);
};

const getRolesGroupMap = (roles: RoleSimpleDetail[]) => {
    return roles.reduce(
        (res, currVal) => {
            if (currVal.Type !== UserRoleType.Project) {
                res[currVal.Type].push(currVal);
            } else if (currVal.Module != null) {
                res[UserRoleType.Project][currVal.Module.Key].push(currVal);
            } else {
                Object.values(ModuleKey).forEach((x) => {
                    res[UserRoleType.Project][x].push(currVal);
                });
            }

            return { ...res };
        },
        {
            [UserRoleType.Global]: [],
            [UserRoleType.Subject]: [],
            [UserRoleType.Project]: Object.values(ModuleKey).reduce(
                (o, key) => ({ ...o, [key]: [] }),
                {} as Record<ModuleKey, RoleSimpleDetail[]>
            ),
        } as RolesGroupMap
    );
};

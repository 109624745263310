import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationActionInfo } from '~/plugins/notificationManager/_types';

export enum NotificationActionSharedKey {
    SubjectMessage = 'SubjectMessage',
    Subject = 'Subject',
}

export const getActions = (): NotificationActionInfo[] => {
    return [
        {
            group: NotificationActionSharedKey.SubjectMessage,
            actionKey: NotificationActionSharedKey.SubjectMessage,
            label: `notifications.actions.${NotificationActionSharedKey.SubjectMessage}.actionTitle`,
            executeFn: async () =>
                await executeRoute({
                    name: 'communication',
                }),
        },
        {
            group: NotificationActionSharedKey.Subject,
            actionKey: NotificationActionSharedKey.Subject,
            label: `notifications.actions.${NotificationActionSharedKey.Subject}.actionTitle`,
            canExecuteFn: (info) => info.RouteParams != null,
            executeFn: async (info) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'settings-subjects-id',
                    params: info.RouteParams,
                });
            },
        },
    ];
};

export enum HRFolderKey {
    Recruitment = 'recruitment',
    Rejected = 'rejected',
    Current = 'current',
    Former = 'former',
}

export const isHRFolderKey = (key: string): key is HRFolderKey => {
    return Object.values(HRFolderKey).some((x) => x === key);
};

import { HRAttachmentTypeInfo } from '~/modules/human-resources/api/attachments/_types';
import { HREnumInfo } from '~/modules/human-resources/api/enums/_types';

const useHREnumsStore = defineStore('hr-enums', () => {
    const attachmentTypes = ref<HRAttachmentTypeInfo[] | null>(null);
    const employmentRelationshipTypes = ref<HREnumInfo[] | null>(null);
    const reset = () => {
        attachmentTypes.value = null;
        employmentRelationshipTypes.value = null;
    };

    return {
        attachmentTypes,
        employmentRelationshipTypes,
        reset,
    };
});

export default useHREnumsStore;

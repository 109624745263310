import { EstimatedTime } from './_types';

const estimatedTimeTypeConfig = {
    md: {
        key: 'MD',
        totalMin: 480,
    },
    h: {
        key: 'h',
        totalMin: 60,
    },
    min: {
        key: 'min',
        totalMin: 1,
    },
} as const;

/**
 * Creates estimatedTime object
 * @param val either label (string) or totalMin (number)
 */
export const createEstimatedTime = (val: string | number): EstimatedTime => {
    const estimatedTime =
        typeof val === 'string' ? createEstimatedTimeFromLabel(val) : createEstimatedTimeFromTotalMinutes(val);

    return {
        ...estimatedTime,
    };
};

const createEstimatedTimeFromLabel = (label: string): EstimatedTime => {
    const splitVal = label.trim().split(' ');
    let totalMin = 0;
    splitVal.forEach((val) => {
        const match = val
            .toLowerCase()
            .trim()
            .match(
                `\\d+(${estimatedTimeTypeConfig.md.key.toLowerCase()}|${estimatedTimeTypeConfig.h.key.toLowerCase()}|${estimatedTimeTypeConfig.min.key.toLowerCase()})`
            );

        if (match == null) throw Error('Invalid label');

        totalMin +=
            +match[0].replace(match[1], '') *
            (Object.values(estimatedTimeTypeConfig)?.find((x) => x.key.toLowerCase() === match[1].toLowerCase())
                ?.totalMin || 0);
    });

    return createEstimatedTimeFromTotalMinutes(totalMin);
};

const createEstimatedTimeFromTotalMinutes = (totalMin: number): EstimatedTime => {
    let remainder = totalMin;

    const md = Math.floor(remainder / estimatedTimeTypeConfig['md'].totalMin);
    remainder = remainder % estimatedTimeTypeConfig['md'].totalMin;

    const h = Math.floor(remainder / estimatedTimeTypeConfig['h'].totalMin);
    remainder = remainder % estimatedTimeTypeConfig['h'].totalMin;

    return {
        md,
        h,
        min: remainder,
        totalMin,
        label: getLabel(md, h, remainder),
    };
};

const getLabel = (md: number, h: number, min: number) => {
    let label = '';
    if (md > 0) label += `${md}${estimatedTimeTypeConfig['md'].key} `;
    if (h > 0) label += `${h}${estimatedTimeTypeConfig['h'].key} `;
    if (min > 0) label += `${min}${estimatedTimeTypeConfig['min'].key}`;

    return label.trim();
};

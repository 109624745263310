// @ts-nocheck

import { isEmpty } from 'lodash-es';
import { createEstimatedTime } from '~/modules/tasks/utils/estimatedTimeUtils';

export default {
    estimatedLabel: (value, [target], ctx) => {
        if (isEmpty(value) || typeof value !== 'string') {
            return true;
        }

        try {
            createEstimatedTime(value);
        } catch {
            return false;
        }

        return true;
    },
};

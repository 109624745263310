import { AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import { SubmissionContext } from 'vee-validate';

// > Config
export interface ApiConfig extends Pick<AxiosRequestConfig, 'headers' | 'withCredentials' | 'timeout'> {
    baseURL: string;
    endpointUrl?: string;
}

export interface ApiServiceOpts {
    axios?: ApiRequestConfig;
    debug?: boolean;
    notifyOnError?: boolean;
    public?: boolean;
}

// > Axios request custom "config"
export interface ApiRequestConfig
    extends Pick<
        AxiosRequestConfig,
        | 'data'
        | 'baseURL'
        | 'headers'
        | 'responseType'
        | 'cancelToken'
        | 'onDownloadProgress'
        | 'onUploadProgress'
        | 'timeout'
        | 'params'
    > {
    endpointUrl?: string;
    transform?: ApiResponseTransformer;
    transformMeta?: ApiResponseTransformer;
    notify?: boolean;
}

// > Models
export enum HttpStatusCodeExtended {
    CsrfTokenMismatch = 419,
}
export type ApiStatusCode = HttpStatusCode | HttpStatusCodeExtended;

export type ApiGenericValues = Record<string, any>;

export interface ApiGenericErrorsData<TData = unknown> {
    Status: number;
    Message: string;
    Data: TData;
}

export type ApiErrors<T = ApiGenericValues> = Partial<Record<keyof T, string | undefined>> & {
    _reason: string;
};

export interface ApiResponseContext<T, D = unknown, M = unknown> {
    data: T;
    status: ApiStatusCode;
    meta: M;
    message: string;
    isSuccess: boolean;
    isValidationError: boolean;
    isAuthError: boolean;
    isNotFoundError: boolean;
    errors: ApiErrors<D>;
}

export type ApiSimpleResponseContext<T, D> = Pick<ApiResponseContext<T, D>, 'data' | 'errors' | 'isSuccess'>;

export type ApiResponse<T = any, D = any, M = any> = ApiResponseContext<T, D, M> & AxiosResponse<T, D>;

// > Function prototypes
export type ApiResponseTransformer<T = unknown> = (data: any) => T;

export type ApiHandler<R = unknown> = () => Promise<R>;

// With optional data (args - nullable|undefined|default values)
export type ApiHandlerWithArgs<T, R = void> = (args?: T) => Promise<R>;

// With required data (args)
export type ApiHandlerWithData<T extends ApiGenericValues | string | boolean | number, R = void> = (
    values: T
) => Promise<R>;

export type FormHandler<T extends ApiGenericValues, R = void> = (values: T, ctx: SubmissionContext<T>) => Promise<R>;

export type FormHandlerWithArgs<T, V extends ApiGenericValues, R = void> = (
    args: T,
    values: V,
    ctx: SubmissionContext<V>
) => Promise<R>;

export const isApiGenericErrorsData = (data: unknown): data is ApiGenericErrorsData => {
    return data != null && typeof data === 'object' && 'Status' in data && 'Message' in data;
};

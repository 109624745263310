import { HCPurposeInfo } from '~/modules/humanitarian-care/api/enums/_types';

const useHCPurposeStore = defineStore('hc-purpose', () => {
    const purposes = ref<HCPurposeInfo[] | null>(null);

    const reset = () => {
        purposes.value = null;
    };

    return {
        purposes,
        reset,
    };
});

export default useHCPurposeStore;

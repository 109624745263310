import { debug } from '~/utils/debugUtilts';
import { NotificationActionInfo, NotificationManager } from './_types';

export const createNotificationManager = (): NotificationManager => {
    const { getAction, getActions, setAction } = createNotificationManagerStore();

    const register: NotificationManager['register'] = (action) => {
        const existingAction = getAction(action.actionKey);
        if (existingAction != null) {
            console.warn(
                `[Plugin NotificationManager] - action with key '${action.actionKey}' is already registered. This action registration will be skipped.`
            );
            return;
        }

        setAction(action);
        debug(`[Plugin NotificationManager] - action with key '${action.actionKey}' was registered`);
    };

    const registerBatch: NotificationManager['registerBatch'] = (actions) => {
        actions.forEach((x) => register(x));
    };

    return {
        register,
        registerBatch,
        getActions,
    };
};

const createNotificationManagerStore = () => {
    const registeredActions: NotificationActionInfo[] = [];

    const getAction = (key: string): NotificationActionInfo | null => {
        return registeredActions.find((x) => x.actionKey === key) || null;
    };

    const getActions = (group: string): NotificationActionInfo[] => {
        return registeredActions.filter((x) => x.group === group);
    };

    const setAction = (action: NotificationActionInfo) => {
        registeredActions.push(action);
    };

    return {
        registeredActions,
        getAction,
        getActions,
        setAction,
    };
};

import config from '~/plugins/config';
import { createNotificationManager } from './manager';
import { NotificationManager, NotificationActionInfo } from './_types';

const notificationManagerInstance: NotificationManager = createNotificationManager();

export const resolveActions = (): NotificationActionInfo[] => {
    const actionsFiles = import.meta.glob<{ getActions: () => NotificationActionInfo[] }>(
        `../../modules/**/notifications/_actions.ts`,
        {
            eager: true,
        }
    );

    const actions: NotificationActionInfo[] = [];
    Object.entries(actionsFiles).forEach(async ([key, value]) => {
        const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/notifications/);

        if (matchedModule?.length !== 2) {
            console.warn('[Plugin NotificationManager]', `Can't load '${key}' jobs.`, actionsFiles);
            return;
        }

        const module = matchedModule[1];

        if (!config.isModuleSupported(module)) {
            console.log('[Plugin NotificationManager]', `Module '${module}' is not supported for actions.`);
            return;
        }

        const moduleJobs = value.getActions();
        actions.push(...moduleJobs);
    });

    return actions;
};

export default notificationManagerInstance;

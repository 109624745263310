import { SubjectDetail, SubjectUserDetail } from '~/modules/core/api/subjects/_types';

const useSubjectStore = defineStore('subject', () => {
    const activeSubject = ref<SubjectDetail | null>(null);
    const activeSubjectUser = ref<SubjectUserDetail | null>(null);

    const reset = (): void => {
        activeSubject.value = null;
        activeSubjectUser.value = null;
    };

    return {
        activeSubject,
        activeSubjectUser,
        reset,
    };
});

export default useSubjectStore;

import { isUndefined } from 'lodash-es';
import config from '~/plugins/config';
import local from './local';
import cookie from './cookie';
import { SupportedBrowserStorages, BrowserStorageType, BrowserStorage } from './_types';

const supportedStorages: SupportedBrowserStorages = {
    local,
    cookie,
};

const initStorage = (type: BrowserStorageType): BrowserStorage => {
    const storage = supportedStorages[type];

    if (isUndefined(storage)) {
        throw new Error(`Storage type '${type}' is not supported.`);
    }

    return storage();
};

const browserStorageInstance = initStorage(config.storage.type);

export default browserStorageInstance;

import {
    deleteCommunicationContactRequest,
    fetchCommunicationContactsRequest,
    fetchCommunicationInfoRequest,
    fetchCommunicationMessagesRequest,
    postCommunicationContactsImportRequest,
    postCommunicationContactsRequest,
    postCommunicationMessageRequest,
    putCommunicationContactRequest,
} from '~/modules/core/api/communication';
import {
    CommunicationContact,
    CommunicationContactFormData,
    CommunicationContactImportFormData,
    CommunicationContactMultipleFormData,
    CommunicationInfo,
    CommunicationMessageFormData,
    CommunicationMessageInfo,
} from '~/modules/core/api/communication/_types';
import { FormHandler, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import useCommunicationStore from '~/modules/core/stores/communication';

/**
 * @param subjectId
 * @param opt
 */
export const fetchCommunicationContacts = async (
    subjectId: number,
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: CommunicationContact[] | null; meta: PaginationMeta | null }> => {
    return await fetchCommunicationContactsRequest({ subjectId, opt });
};

/**
 * @param id
 */
export const deleteCommunicationContact = async (id: number): Promise<{ isSuccess: boolean }> => {
    return await deleteCommunicationContactRequest(id);
};

/**
 * @param subjectId
 * @param values
 * @param ctx
 */
export const createCommunicationContacts: FormHandlerWithArgs<
    number,
    CommunicationContactMultipleFormData,
    { isSuccess: boolean; isValidationError: boolean }
> = async (subjectId, values, { setErrors }): Promise<{ isSuccess: boolean; isValidationError: boolean }> => {
    const { isSuccess, isValidationError, errors } = await postCommunicationContactsRequest({
        subjectId,
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

/**
 * @param subjectId
 * @param values
 * @param ctx
 */
export const importCommunicationContacts: FormHandlerWithArgs<
    number,
    CommunicationContactImportFormData,
    { isSuccess: boolean; isValidationError: boolean }
> = async (subjectId, values, { setErrors }): Promise<{ isSuccess: boolean; isValidationError: boolean }> => {
    const { isSuccess, isValidationError, errors } = await postCommunicationContactsImportRequest({
        subjectId,
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

/**
 * @param contactId
 * @param values
 * @param ctx
 */
export const updateCommunicationContact: FormHandlerWithArgs<
    number,
    CommunicationContactFormData,
    { isSuccess: boolean; isValidationError: boolean; data: CommunicationContact | null }
> = async (contactId, values, { setErrors }) => {
    const { isSuccess, isValidationError, data, errors } = await putCommunicationContactRequest({
        id: contactId,
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError, data };
};

export const fetchCommunicationInfo = async (): Promise<{ isSuccess: boolean; data: CommunicationInfo | null }> => {
    const { isSuccess, data } = await fetchCommunicationInfoRequest();
    if (isSuccess && data != null) {
        const communicationStore = useCommunicationStore();
        communicationStore.info = data;
    }

    return { isSuccess, data };
};

export const fetchCommunicationMessages = async (): Promise<{
    isSuccess: boolean;
    data: CommunicationMessageInfo[] | null;
}> => {
    return await fetchCommunicationMessagesRequest();
};

/**
 * @param values
 * @param ctx
 */
export const sendCommunicationMessage: FormHandler<
    CommunicationMessageFormData,
    { isSuccess: boolean; isValidationError: boolean }
> = async (values, { setErrors }): Promise<{ isSuccess: boolean; isValidationError: boolean }> => {
    const { isSuccess, isValidationError, errors } = await postCommunicationMessageRequest(values);

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

import { isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import { QTableProps } from 'quasar';
import { FilterBarResult } from '~/components/filters/_types';
import { AppTableRequestPayload } from '~/components/tables/_types';
import { FetchListFilterOptions, PaginationMeta } from '~/modules/core/api/_types';
import { FetchListOptions } from '~/modules/core/api/_types';
import { FetchListFilterOperator, isFetchListFilterOperator } from '~/modules/core/enums/_types';
import { FilterBarDataType } from '~/modules/core/configs/filters/_types';
import { API_DATE_FORMAT } from '~/modules/core/constants';

export const useApiCollectionData = <TData>(
    fetchDataCallback: (
        opt?: FetchListOptions
    ) => Promise<{ isSuccess: boolean; data: TData[] | null; meta?: PaginationMeta | null }>,
    initialPagination?: QTableProps['pagination'] | null,
    implicitInitFilter?: FetchListFilterOptions[] | null
) => {
    const {
        data,
        isLoading,
        request: requestArgs,
        pagination,
        userFilter,
        implicitFilter,
    } = useApiCollectionDataWithArgs<unknown, TData>(
        (_, opt) => fetchDataCallback(opt),
        initialPagination,
        implicitInitFilter
    );

    const request = async (requestPayload?: AppTableRequestPayload, delay?: number) => {
        return await requestArgs(null, requestPayload, delay);
    };

    return {
        data,
        pagination,
        userFilter,
        implicitFilter,
        isLoading,
        request,
    };
};

export const useApiCollectionDataWithArgs = <TArgs, TData>(
    fetchDataCallback: (
        args: TArgs,
        opt?: FetchListOptions
    ) => Promise<{ isSuccess: boolean; data: TData[] | null; meta?: PaginationMeta | null }>,
    initialPagination?: QTableProps['pagination'] | null,
    implicitInitFilter?: FetchListFilterOptions[] | null
) => {
    const data = shallowRef<TData[]>([]);
    const {
        pagination,
        userFilter,
        implicitFilter,
        isLoading,
        request: requestTmp,
    } = useApiCollectionDataObjectWithArgs<TArgs, TData[]>(fetchDataCallback, initialPagination, implicitInitFilter);

    const request = async (
        args: TArgs,
        requestPayload?: AppTableRequestPayload,
        delay?: number
    ): Promise<{ isSuccess: boolean; data: TData[] | null }> => {
        const result = await requestTmp(args, requestPayload, delay);
        data.value = result.data || [];

        return result;
    };

    return {
        data,
        pagination,
        userFilter,
        implicitFilter,
        isLoading,
        request,
    };
};

export const useApiCollectionDataObject = <TData>(
    fetchDataCallback: (
        opt?: FetchListOptions
    ) => Promise<{ isSuccess: boolean; data: TData | null; meta?: PaginationMeta | null }>,
    initialPagination?: QTableProps['pagination'] | null,
    implicitInitFilter?: FetchListFilterOptions[] | null
) => {
    const {
        data,
        isLoading,
        request: requestArgs,
        pagination,
        userFilter,
        implicitFilter,
    } = useApiCollectionDataObjectWithArgs<unknown, TData>(
        (_, opt) => fetchDataCallback(opt),
        initialPagination,
        implicitInitFilter
    );

    const request = async (requestPayload?: AppTableRequestPayload, delay?: number) => {
        return await requestArgs(null, requestPayload, delay);
    };

    return {
        data,
        pagination,
        userFilter,
        implicitFilter,
        isLoading,
        request,
    };
};

export const useApiCollectionDataObjectWithArgs = <TArgs, TData>(
    fetchDataCallback: (
        args: TArgs,
        opt?: FetchListOptions
    ) => Promise<{ isSuccess: boolean; data: TData | null; meta?: PaginationMeta | null }>,
    initialPagination?: QTableProps['pagination'] | null,
    implicitInitFilter?: FetchListFilterOptions[] | null
) => {
    const data = ref<TData | null>();
    const pagination = ref(initialPagination || undefined);
    const search = ref<string>('');
    const userFilter = ref<FilterBarResult[]>([]);
    const implicitFilter = ref<FetchListFilterOptions[]>(implicitInitFilter || []);
    const listOptions = ref<FetchListOptions>();

    const { isLoading, execute } = useAsyncState(
        async (args: TArgs, requestPayload?: AppTableRequestPayload | null) => {
            listOptions.value = getFetchListOptions({
                pagination: requestPayload?.pagination ?? pagination.value,
                search: requestPayload?.search ?? search.value,
                userFilter: requestPayload?.userFilter ?? userFilter.value,
                implicitFilter: requestPayload?.implicitFilter ?? implicitFilter.value,
            });

            const { isSuccess, data: tmpData, meta } = await fetchDataCallback(args, listOptions.value);

            data.value = tmpData;

            updatePagination(meta);

            return { isSuccess, data: tmpData };
        },
        { isSuccess: false, data: null },
        {
            immediate: false,
        }
    );

    const updatePagination = (meta?: PaginationMeta | null) => {
        if (meta == null) return;

        pagination.value = {
            ...pagination.value,
            rowsNumber: meta.Total,
            page: meta.Offset / meta.Limit + 1,
        };
    };

    const getFetchListOptions = (payload: AppTableRequestPayload): FetchListOptions => {
        const opt: FetchListOptions = {};

        if (payload.pagination != null) {
            opt.pagination = {
                limit: payload.pagination.rowsPerPage || 0,
                offset: ((payload.pagination.page || 1) - 1) * (payload.pagination.rowsPerPage || 0),
            };

            if (!isEmpty(payload.pagination.sortBy)) {
                opt.sort = {
                    column: payload.pagination.sortBy || '',
                    descending: payload.pagination.descending || false,
                };
            }
        }

        opt.filter = [
            ...(payload.implicitFilter || []),
            ...(payload.userFilter
                ?.map((x) => {
                    if (!isFetchListFilterOperator(x.operator?.key)) return null;

                    return {
                        column: x.column.key,
                        op: x.operator?.key || FetchListFilterOperator.Eq,
                        val: getFilterVal(x),
                    };
                })
                .filter((x): x is FetchListFilterOptions => !!x) || []),
        ];

        if (!isEmpty(payload.search)) {
            opt.search = payload.search;
        }

        return opt;
    };

    const getFilterVal = (filter: FilterBarResult) => {
        if (filter.column.dataType === FilterBarDataType.Date) {
            return (filter.value.val as DateTime).toFormat(API_DATE_FORMAT);
        }

        if (filter.column.dataType === FilterBarDataType.DateTime) {
            return (filter.value.val as DateTime).toISO();
        }

        return filter.value.val;
    };

    const request = async (
        args: TArgs,
        requestPayload?: AppTableRequestPayload,
        delay?: number
    ): Promise<{ isSuccess: boolean; data: TData | null }> => {
        return await execute(delay, args, requestPayload);
    };

    return {
        data,
        pagination,
        userFilter,
        implicitFilter,
        isLoading,
        listOptions,
        request,
    };
};

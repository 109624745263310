import { SettingsUserDetail } from '~/modules/core/api/users/_types';

const useUserSettingsStore = defineStore('settings-user', () => {
    const activeUser = ref<SettingsUserDetail | null>(null);

    const reset = (): void => {
        activeUser.value = null;
    };

    return {
        activeUser,
        reset,
    };
});

export default useUserSettingsStore;

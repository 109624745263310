import { AppPlugin } from '~/plugins/_types';
import { Icon } from 'leaflet';
import { LeafletIconDefaults } from './_types';
import 'leaflet/dist/leaflet.css';

export default {
    name: 'leaflet',
    install: async () => {
        runIconFix();
    },
    priority: 1,
} satisfies AppPlugin;

/**
 * this is fix if there is error when icons are missing
 * See: https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
 * It is from documentation for vue2 but it still applies
 */
const runIconFix = () => {
    delete (Icon.Default.prototype as LeafletIconDefaults)._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: import('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: import('leaflet/dist/images/marker-icon.png'),
        shadowUrl: import('leaflet/dist/images/marker-shadow.png'),
    });
};

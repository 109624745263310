import { AppPlugin } from '~/plugins/_types';
import { t } from '~/plugins/i18n';
import { changeYupLocale } from '~/plugins/yup/index';

export default {
    name: 'yup',
    install: async (app) => {
        changeYupLocale(t);
    },
    priority: 5,
} satisfies AppPlugin;

import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { ReportingPermissions } from '~/modules/reporting/enums/permissions/_types';

const usePermissionReportingReports = () => {
    const { canProject } = usePermissions();

    const canCreateReport = (project: ProjectDetail | null): boolean => {
        return canProject([ReportingPermissions.ReportCreate], project);
    };

    return {
        canCreateReport,
    };
};

export default usePermissionReportingReports;

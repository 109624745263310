import { pick } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { LabelInfo } from './_types';

export const rawToLabelInfo: ApiResponseTransformer<LabelInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Name', 'ProjectsCount']),
        IsActive: data.IsActive || false,
    };
};

export const rawToLabelInfoArray: ApiResponseTransformer<LabelInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToLabelInfo(x)).filter((x): x is LabelInfo => !!x);
};

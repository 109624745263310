import { fetchSimpleLabelsRequest } from '~/modules/core/api/enums';
import {
    deleteLabelRequest,
    fetchLabelsMyRequest,
    fetchLabelsRequest,
    postLabelRequest,
} from '~/modules/core/api/labels';
import { LabelSimpleInfo } from '~/modules/core/api/enums/_types';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { LabelFormData, LabelInfo } from '~/modules/core/api/labels/_types';
import useLabelsStore from '~/modules/core/stores/labels';
import { FormHandler } from '~/plugins/apiClient/_types';

export const fetchEnumLabels = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: LabelSimpleInfo[] | null; meta: PaginationMeta | null }> => {
    return await fetchSimpleLabelsRequest({ opt });
};

export const fetchLabelsWithCount = async (): Promise<{
    isSuccess: boolean;
    data: LabelInfo[] | null;
}> => {
    const { isSuccess, data } = await fetchLabelsMyRequest();

    if (isSuccess && data) {
        const store = useLabelsStore();
        store.setLabels(data);
    }

    return { isSuccess, data };
};

export const fetchLabels = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: LabelInfo[] | null }> => {
    return await fetchLabelsRequest(opt);
};

export const deleteLabel = async (labelId: number): Promise<{ isSuccess: boolean }> => {
    return await deleteLabelRequest(labelId);
};

export const createLabel: FormHandler<LabelFormData, { isSuccess: boolean }> = async (values, { setErrors }) => {
    const { isSuccess, isValidationError, errors } = await postLabelRequest({
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import useInventoryHistoryStore from '~/modules/inventory/stores/history';
import useInventoryProductStore from '~/modules/inventory/stores/products';

export const after: NavigationHookAfter = (to) => {
    resetHistoryStore(to);
    resetProductStore(to);
};

const resetHistoryStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('inventory-history-id')) {
        const store = useInventoryHistoryStore();
        store.reset();
    }
};

const resetProductStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('inventory-products-id')) {
        const store = useInventoryProductStore();
        store.reset();
    }
};

export enum TasksPermissions {
    // global role for manager - can manage time tracking and tasks issues (create/update all properties issues,edit/delete comments)
    Manage = 'TasksManage',
    // view issues list and detail
    IssueDetail = 'TasksIssueDetail',
    // create issue
    IssueCreate = 'TasksIssueCreate',
    // update issue - Title, Body, Attachments
    IssueUpdateBase = 'TasksIssueUpdateBase',
    // update issue state
    IssueUpdateState = 'TasksIssueUpdateState',
    // update issue assignee
    IssueUpdateAssignee = 'TasksIssueUpdateAssignee',
    // update issue priority
    IssueUpdatePriority = 'TasksIssueUpdatePriority',
    // update issue - begin at, finish at
    IssueUpdateDueDate = 'TasksIssueUpdateDueDate',
    // update issue - estimated time
    IssueUpdateEstimatedTime = 'TasksIssueUpdateEstimateTime',
    // close/reopen issue
    IssueClose = 'TasksIssueClose',
    // close/reopen issue
    IssueEstimatedTimeApprove = 'TasksIssueApproveEstimateTime',
    // create comment
    CommentCreate = 'TasksIssueCommentCreate',
    // edit comment other than authors own
    CommentUpdate = 'TasksIssueCommentUpdate',
    // edit comment authors own
    CommentUpdateOwn = 'TasksIssueCommentUpdateOwn',
    // delete comment other than authors own
    CommentDelete = 'TasksIssueCommentDelete',
    // delete comment  authors own
    CommentDeleteOwn = 'TasksIssueCommentDeleteOwn',
    // can use time tracking for yourself
    TimeRecordManageOwn = 'TasksTimeRecordManageOwn',
    // can use time tracking for other users
    TimeRecordManage = 'TasksTimeRecordManage',
    // can view all gantt instances and create/manage own gantt instances
    GanttManageOwn = 'TasksGanttManageOwn',
    // can view/create/manage all gantt instances
    GanttManage = 'TasksGanttManage',
    // can view project overview
    ProjectOverview = 'TasksProjectOverview',
    // can manage tasks project settings
    ManageSettings = 'TasksManageSettings',
}

import { pick } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { DateTime } from 'luxon';
import {
    ReportDataItem,
    ReportDataItemColumnSize,
    ReportDetail,
    ReportInfo,
    ReportingInfoSender,
    ReportingSender,
    ReportTemplateInfo,
    ReportTemplateDetail,
} from './_types';
import { rawToUserSimpleInfo, rawToUserSimpleInfoShort } from '~/modules/core/api/users/_transformers';
import { rawToProjectSimpleInfo } from '~/modules/core/api/enums/_transformers';
import { rawToAppFileInfo, rawToAppFileInfoArray } from '~/modules/core/api/files/_transformers';
import { ReportDataItemType } from '~/modules/reporting/enums/_types';
import { API_DATE_FORMAT } from '~/modules/core/constants';

export const rawToReportInfo: ApiResponseTransformer<ReportInfo | null> = (data) => {
    if (data == null) return null;

    const creator = rawToUserSimpleInfoShort(data.Creator);
    if (creator == null) {
        console.error(
            "[ReportingReportInfoTransformer] - Property 'Creator' is required but [UserSimpleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }
    if (data.InterventionAt == null) {
        console.error("[ReportingReportInfoTransformer] - Property 'InterventionAt' is required but BE returned null.");
        return null;
    }

    return {
        ...pick(data, ['Id', 'Name']),
        Creator: creator,
        InterventionAt: DateTime.fromFormat(data.InterventionAt, API_DATE_FORMAT),
        IsRecreatable: data.IsRecreatable || false,
    };
};

export const rawToReportInfoArray: ApiResponseTransformer<ReportInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToReportInfo(x)).filter((x): x is ReportInfo => !!x);
};

export const rawToReportDetail: ApiResponseTransformer<ReportDetail | null> = (data) => {
    if (data == null) return null;

    const creator = rawToUserSimpleInfo(data.Creator);
    if (creator == null) {
        console.error(
            "[ReportingReportDetailTransformer] - Property 'Creator' is required but [UserSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }
    const project = rawToProjectSimpleInfo(data.Project);
    if (project == null) {
        console.error(
            "[ReportingReportDetailTransformer] - Property 'Project' is required but [ProjectSimpleInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }
    const sender = rawToReportingSender(data.Sender);
    if (sender == null) {
        console.error(
            "[ReportingReportDetailTransformer] - Property 'Sender' is required but [ReportingSenderTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }
    if (data.InterventionAt == null) {
        console.error(
            "[ReportingReportDetailTransformer] - Property 'InterventionAt' is required but BE returned null."
        );
        return null;
    }
    const attachments = rawToAppFileInfoArray(data.Attachments) || [];
    const pdfReport = rawToAppFileInfo(data.PdfReport);

    const itemsData = rawToReportDataItemArray(data.Data);

    return {
        ...pick(data, ['Id', 'Name', 'Recipients', 'TemplateId']),
        PdfReport: pdfReport,
        Creator: creator,
        Project: project,
        Sender: sender,
        Data: itemsData,
        InterventionAt: DateTime.fromISO(data.InterventionAt),
        Attachments: attachments,
    };
};

export const rawToReportDataItemArray: ApiResponseTransformer<ReportDataItem[]> = (data) => {
    if (data == null || !Array.isArray(data)) return [];

    return data.map((x) => rawToReportDataItem(x)).filter((x): x is ReportDataItem => !!x);
};

export const rawToReportDataItem: ApiResponseTransformer<ReportDataItem | null> = (data) => {
    if (data == null) return null;

    let value = data.Value;
    if (data.Value == null) {
        value = data.Value;
    } else if (data.Type == ReportDataItemType.Datetime) {
        value = DateTime.fromISO(data.Value);
    } else if (data.Type == ReportDataItemType.Date) {
        value = DateTime.fromISO(data.Value);
    } else if (data.Type == ReportDataItemType.Boolean) {
        value = data.Value || false;
    }

    return {
        ...pick(data, ['Key', 'Name', 'Type']),
        Value: value,
        IsRequired: data.IsRequired || false,
        ColumnSize: rawToReportDataItemColumnSize(data.ColumnSize),
    };
};

export const rawToReportDataItemColumnSize: ApiResponseTransformer<ReportDataItemColumnSize> = (data) => {
    return {
        xs: data?.xs || null,
        sm: data?.sm || null,
        md: data?.md || null,
        lg: data?.lg || null,
    };
};

export const rawToReportingInfoSender: ApiResponseTransformer<ReportingInfoSender | null> = (data) => {
    if (data == null) return null;

    const sender = rawToReportingSender(data.Sender);
    if (sender == null) return null;

    return {
        Sender: sender,
    };
};

export const rawToReportingSender: ApiResponseTransformer<ReportingSender | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Name', 'Email']),
    };
};

export const rawToReportTemplateInfo: ApiResponseTransformer<ReportTemplateInfo | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Id', 'Name']),
    };
};

export const rawToReportTemplateInfoArray: ApiResponseTransformer<ReportTemplateInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToReportTemplateInfo(x)).filter((x): x is ReportTemplateInfo => !!x);
};

export const rawToReportTemplateDetail: ApiResponseTransformer<ReportTemplateDetail | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Id', 'Name']),
        Structure: rawToReportDataItemArray(data.Structure),
    };
};

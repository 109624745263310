export enum DispatchingPermissions {
    // view issues list and detail
    IssueDetail = 'DispatchingIssueDetail',
    // create issue
    IssueCreate = 'DispatchingIssueCreate',
    // update issue other than authors own
    IssueUpdate = 'DispatchingIssueUpdate',
    // change state for issue
    IssueChangeState = 'DispatchingIssueChangeState',
    // delete/restore issue
    IssueDelete = 'DispatchingIssueDelete',
    // manage enums
    EnumManage = 'DispatchingEnumManage',
    // create comment
    CommentCreate = 'DispatchingCommentCreate',
    // edit comment other than authors own
    CommentUpdate = 'DispatchingCommentUpdate',
    // delete/restore comment
    CommentDelete = 'DispatchingCommentDelete',
}

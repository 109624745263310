import { config } from 'md-editor-v3';
import { AppPlugin } from '~/plugins/_types';
import en from './locales/en.json';
import cs from './locales/cs.json';
import 'md-editor-v3/lib/style.css';

export default {
    name: 'md-editor',
    install: async () => {
        config({
            editorConfig: {
                languageUserDefined: {
                    en: en,
                    cs: cs,
                },
            },
        });
    },
    priority: 1,
} satisfies AppPlugin;

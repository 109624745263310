import { createRouter, createWebHistory } from 'vue-router';
import { setupLayouts } from 'virtual:generated-layouts';
import generatedRoutes from 'virtual:generated-pages';
import { afterEach, beforeEach } from '~/plugins/router/middlewares';

const _createRouter = () => {
    const router = createRouter({
        history: createWebHistory(),
        routes: setupLayouts(generatedRoutes),
    });

    router.beforeEach(beforeEach);
    router.afterEach(afterEach);

    return router;
};

export default _createRouter;

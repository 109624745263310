import config from '~/plugins/config';
import { RoutePageRedirects } from './_types';

const resolvePageRedirects = (): RoutePageRedirects => {
    const localeFiles = import.meta.glob<{ redirects: () => RoutePageRedirects }>(
        `../../modules/**/pages/**/_redirects.ts`,
        {
            eager: true,
        }
    );

    const pageRedirects: RoutePageRedirects = {};
    Object.entries(localeFiles).forEach(async ([key, value]) => {
        const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/pages/);

        if (matchedModule?.length !== 2) {
            console.warn('[Plugin router]', `Can't load '${key}' redirects.`);
            return;
        }

        const module = matchedModule[1];

        if (!config.isModuleSupported(module)) {
            console.log('[Plugin router]', `Module '${module}' is not supported for redirects.`);
            return;
        }

        const redirects = value.redirects();
        for (const key in redirects) {
            pageRedirects[key] = redirects[key];
        }
    });

    return pageRedirects;
};

export const getPageRedirects = () => {
    if (_pageRedirects == null) {
        _pageRedirects = resolvePageRedirects();
    }
    return _pageRedirects;
};

let _pageRedirects: RoutePageRedirects | null = null;

import createI18n from '~/plugins/i18n/i18n';

// > i18n global instance
const i18nInstance = createI18n();

// > i18n export functions
export const { t, d, n } = i18nInstance.global;

// > Quasar languages import
export const quasarLanguages = import.meta.glob('/node_modules/quasar/lang/(cs|en-US).mjs');

export default i18nInstance;

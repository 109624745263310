import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { StatisticsPermissions } from '~/modules/statistics/enums/permissions/_types';

const usePermissionStatistics = () => {
    const { canProject } = usePermissions();

    const canManageInventory = (project: ProjectDetail | null): boolean => {
        return canProject([StatisticsPermissions.InventoryManage], project);
    };

    const canManageDashboard = (project: ProjectDetail | null): boolean => {
        return canProject([StatisticsPermissions.DashboardManage], project);
    };

    const canManageMasterProduct = (project: ProjectDetail | null): boolean => {
        return canProject([StatisticsPermissions.MasterProductManage], project);
    };

    return {
        canManageInventory,
        canManageDashboard,
        canManageMasterProduct,
    };
};

export default usePermissionStatistics;

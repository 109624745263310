import { pick } from 'lodash-es';
import { rawToTasksIssueInfoShort } from '~/modules/tasks/api/issues/_transformers';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import {
    TrackingTimeRecordFormData,
    TrackingTimeRecordInfo,
    TrackingTimeRecordReportExportInfo,
    TrackingTimeRecordReportGroupInfo,
    TrackingTimeRecordReportInfo,
    TrackingTimerFormData,
    TrackingTimerInfo,
    TrackingTimerStartFormData,
} from './_types';
import { DateTime, Duration } from 'luxon';
import { rawToUserSimpleInfoShort } from '~/modules/core/api/users/_transformers';

export const rawToTrackingTimerInfo: ApiResponseTransformer<TrackingTimerInfo | null> = (data) => {
    if (data == null) return null;

    const user = rawToUserSimpleInfoShort(data.User);
    if (user == null) {
        console.error(
            "[TTrackingTimerInfoTransformer] - Property 'User' is required but [UserSimpleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Description']),
        User: user,
        Issue: rawToTasksIssueInfoShort(data.Issue),
        StartAt: DateTime.fromISO(data.StartAt),
    };
};

export const rawToTrackingTimeRecordInfo: ApiResponseTransformer<TrackingTimeRecordInfo | null> = (data) => {
    if (data == null) return null;

    const issue = rawToTasksIssueInfoShort(data.Issue);
    if (issue == null) {
        console.error(
            "[TrackingTimeRecordInfoTransformer] - Property 'Project' is required but [TasksIssueInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const user = rawToUserSimpleInfoShort(data.User);
    if (user == null) {
        console.error(
            "[TrackingTimeRecordInfoTransformer] - Property 'User' is required but [UserSimpleInfoShortTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Description', 'IsEditable', 'IsApproved', 'IsManualEntry']),
        Issue: issue,
        StartAt: DateTime.fromISO(data.StartAt),
        EndAt: DateTime.fromISO(data.EndAt),
        User: user,
        Duration: Duration.fromObject({ seconds: data.Duration }),
        ReviewedAt: data.ReviewedAt ? DateTime.fromISO(data.ReviewedAt) : null,
        ReviewedBy: rawToUserSimpleInfoShort(data.ReviewedBy),
    };
};

export const rawToTrackingTimeRecordReportGroupInfo: ApiResponseTransformer<
    TrackingTimeRecordReportGroupInfo | null
> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['Label', 'Key']),
        Total: Duration.fromObject({ seconds: data.Total }),
        Items: rawToTrackingTimeRecordInfoArray(data.Items) ?? [],
    };
};

export const rawToTrackingTimeRecordReportInfo: ApiResponseTransformer<TrackingTimeRecordReportInfo | null> = (
    data
) => {
    if (data == null) return null;

    const groups: Record<string, TrackingTimeRecordReportGroupInfo> = {};

    if (data.Groups == null || typeof data.Groups !== 'object') {
        console.error(
            "[TrackingTimeRecordReportInfoTransformer] - Property 'Groups' is required to be object. Cannot transform object from BE."
        );
        return null;
    }

    Object.keys(data.Groups).forEach((x) => {
        const g = rawToTrackingTimeRecordReportGroupInfo(data.Groups[x]);
        if (g == null) {
            console.error(
                "[TrackingTimeRecordReportInfoTransformer] - Item in property 'Groups' returned null. Skipping this object from parsing."
            );
            return;
        }

        groups[x] = g;
    });

    return {
        ...pick(data, ['GroupBy']),
        Total: Duration.fromObject({ seconds: data.Total }),
        Groups: groups,
    };
};

export const rawToTrackingTimeRecordReportExportInfo: ApiResponseTransformer<
    TrackingTimeRecordReportExportInfo | null
> = (data) => {
    if (data == null) return null;

    return {
        RawData: data,
    };
};

export const rawToTrackingTimeRecordInfoArray: ApiResponseTransformer<TrackingTimeRecordInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToTrackingTimeRecordInfo(x)).filter((x): x is TrackingTimeRecordInfo => !!x);
};

export const rawToTrackingTimerInfoArray: ApiResponseTransformer<TrackingTimerInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToTrackingTimerInfo(x)).filter((x): x is TrackingTimerInfo => !!x);
};

export const rawToTrackingTimeRecordReportGroupInfoArray: ApiResponseTransformer<
    TrackingTimeRecordReportGroupInfo[] | null
> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data
        .map((x) => rawToTrackingTimeRecordReportGroupInfo(x))
        .filter((x): x is TrackingTimeRecordReportGroupInfo => !!x);
};

export const trackingTimerStartFormDataToRaw = (data: TrackingTimerStartFormData) => {
    return {
        Description: data.Description,
        IssueId: data.IssueId?.Id,
    };
};

export const trackingTimerFormDataToRaw = (data: TrackingTimerFormData) => {
    return {
        Description: data.Description,
        IssueId: data.IssueId?.Id,
        StartAt: data.StartAt?.toISO(),
    };
};

export const trackingTimeRecordFormDataToRaw = (data: TrackingTimeRecordFormData) => {
    return {
        Description: data.Description,
        IssueId: data.IssueId?.Id,
        UserId: data.UserId?.Id,
        StartAt: data.StartAt?.toISO(),
        EndAt: data.EndAt?.toISO(),
    };
};

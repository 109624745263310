import { Notify } from 'quasar';
import { isNull } from 'lodash-es';
import { FormHandler } from '~/plugins/apiClient/_types';
import { t } from '~/plugins/i18n';
import { AuthLoginFormData, TwoFactorAuthSmsChallengeData } from '~/modules/core/api/auth/_types';
import {
    fetchUserRequest,
    loginRequest,
    logoutRequest,
    twoFactorAuthSmsChallengeRequest,
    twoFactorAuthSmsLoginRequest,
} from '~/modules/core/api/auth';
import { useAuthStore } from '~/plugins/auth';
import { fetchCommunicationInfo } from '~/modules/core/services/communication';
import usePermissionCommunication from '~/modules/core/composables/permissions/usePermissionsCommunication';
import jobManagerInstance from '~/plugins/jobManager';
import { AuthUser } from '~/plugins/auth/_types';
import { HttpStatusCode } from 'axios';
import { configureScope as sentryConfigureScope } from '@sentry/vue';
import { fetchTasksTrackingTimer } from '~/modules/tasks/services/tracking';
import { useTasksTimeRecordsMeStore } from '~/modules/tasks/stores/tasksTimeRecordsMe';
import { useTasksTimerStore } from '~/modules/tasks/stores/tasksTimer';

/**
 * @param values
 * @param ctx
 */
export const preLoginFor2fa: FormHandler<AuthLoginFormData> = async (values, { setErrors }) => {
    const authStore = useAuthStore();

    const { data, isSuccess: isSuccessLoginRequest, isValidationError, errors } = await loginRequest(values);

    if (!isSuccessLoginRequest || isNull(data)) {
        setErrors(isValidationError ? errors : { Password: t('auth.form.login.errors.invalidCredentials') });
        return;
    }

    authStore.setToken({ ...data.Token, Validated: false });

    if (data.Required2FA) {
        const { isSuccess: isSuccessSmsChallenge, message } = await twoFactorAuthSmsChallengeRequest();

        if (!isSuccessSmsChallenge) {
            setErrors(isValidationError ? errors : { Password: message });
            return;
        }
        await authStore.finishPreLogin();
        return;
    }

    if (data.User) {
        await finishLogin(data.User);
    }
};

export const twoFactorAuthSmsChallengeNewSms = async () => {
    const { isSuccess, message } = await twoFactorAuthSmsChallengeRequest();

    if (!isSuccess) {
        Notify.create({
            group: '2fa',
            type: 'negative',
            message: message,
        });
    } else {
        Notify.create({
            group: '2fa',
            type: 'positive',
            message: t('auth.notify.smsHasBeenSent'),
        });
    }
};

export const loginBySmsChallenge: FormHandler<TwoFactorAuthSmsChallengeData> = async (values, { setErrors }) => {
    const { data, message, errors, status } = await twoFactorAuthSmsLoginRequest(values);

    if (status == HttpStatusCode.UnprocessableEntity) {
        setErrors({ Code: errors?.Code ?? message });
        return;
    } else if (isNull(data)) {
        return;
    }

    await finishLogin(data);
};

export const logout = async (silent = false): Promise<void> => {
    const authStore = useAuthStore();

    await logoutRequest();
    await authStore.logout();

    if (!silent) {
        Notify.create({
            group: 'loggedOut',
            type: 'positive',
            message: t('auth.notify.loggedOut'),
        });
    }

    await executePostLogoutActions();
};

export const fetchUser = async (): Promise<boolean> => {
    const authStore = useAuthStore();
    const { data, isSuccess } = await fetchUserRequest();

    if (isSuccess) {
        authStore.setUser(data);
        await executePostLoginActions(data);
    }

    return isSuccess;
};

export const tryAutoLogin = async (): Promise<boolean> => {
    const authStore = useAuthStore();

    if (authStore.tokenExpired || authStore.loggedIn || !authStore.token?.Validated) {
        return false;
    }

    return await fetchUser();
};

const finishLogin = async (data: AuthUser) => {
    const authStore = useAuthStore();

    await authStore.finishLogin(data);

    Notify.create({
        group: 'loggedIn',
        type: 'positive',
        message: t('auth.notify.loggedIn'),
    });

    await executePostLoginActions(data);
};

const executePostLoginActions = async (user: AuthUser | null) => {
    jobManagerInstance.startAllJobs();

    const { canSendAny } = usePermissionCommunication();
    if (canSendAny()) {
        await fetchCommunicationInfo();
    }

    // todo: module disable + permissions checks;
    await fetchTasksTrackingTimer(true);

    if (user) {
        const { updateUncheckedCount } = useChecklists();
        updateUncheckedCount(user?.TotalUncheckedCount ?? 0);

        sentryConfigureScope((scope) => {
            scope.setUser({ id: user.Id });
        });
    }
};

const executePostLogoutActions = () => {
    jobManagerInstance.pauseAllJobs();

    resetTasks();

    sentryConfigureScope((scope) => {
        scope.setUser(null);
    });
};

const resetTasks = () => {
    useTasksTimeRecordsMeStore().reset();
    useTasksTimerStore().reset();
};

import { DateTime, Duration, WeekdayNumbers } from 'luxon';
import { FetchListOptions } from '~/modules/core/api/_types';
import { fetchTasksTrackingRecordsMe } from '~/modules/tasks//services/tracking';
import { TrackingTimeRecordInfo } from '~/modules/tasks//api/tracking/_types';
import { FetchListFilterOperator } from '~/modules/core/enums/_types';
import { TrackingTimeRecordDayData, TrackingTimeRecordWeekData } from './_types';
import { useTasksTimeRecordsMeStore } from '~/modules/tasks//stores/tasksTimeRecordsMe';

const useTrackingRecordsMe = () => {
    const store = useTasksTimeRecordsMeStore();
    const { weekData, start, end } = storeToRefs(store);
    const { data, isLoading, request } = useApiCollectionData(async (opt?: FetchListOptions) => {
        return await fetchTasksTrackingRecordsMe(opt);
    });

    const createWeekData = (
        start: DateTime,
        end: DateTime,
        data: TrackingTimeRecordInfo[]
    ): TrackingTimeRecordWeekData => {
        const filterData = data.filter((x) => x.StartAt >= start && x.StartAt <= end);
        let totalTime = Duration.fromMillis(0);
        const week: Partial<Record<WeekdayNumbers, TrackingTimeRecordDayData>> = {};

        filterData.forEach((x) => {
            let currentDay = (week as Record<number, TrackingTimeRecordDayData>)[x.StartAt.weekday];
            if (currentDay == null) {
                currentDay = {
                    totalTime: Duration.fromMillis(0),
                    day: DateTime.fromObject({ year: x.StartAt.year, month: x.StartAt.month, day: x.StartAt.day }),
                    data: [],
                };
                (week as Record<number, TrackingTimeRecordDayData>)[x.StartAt.weekday] = currentDay;
            }

            currentDay.totalTime = currentDay.totalTime.plus(x.Duration);
            totalTime = totalTime.plus(x.Duration);
            currentDay.data.push(x);
        });

        return {
            start: DateTime.fromISO(start.toISO()!),
            end: DateTime.fromISO(end.toISO()!),
            totalTime,
            week,
        };
    };

    const loadNextWeekData = async () => {
        let tmpEnd: DateTime | undefined;
        let tmpStart: DateTime | undefined;

        // first load - this and last week together
        if (start.value == null || end.value == null) {
            tmpEnd = DateTime.now().endOf('week');
            tmpStart = tmpEnd.startOf('week').plus({ days: -7 });
        } else {
            tmpEnd = start.value?.plus({ seconds: -1 });
            tmpStart = tmpEnd.startOf('week');
        }

        const { isSuccess, data } = await request({
            implicitFilter: [
                { column: 'StartAt', op: FetchListFilterOperator.Gte, val: tmpStart.toISO() },
                {
                    column: 'StartAt',
                    op: FetchListFilterOperator.Lte,
                    val: tmpEnd.toISO(),
                },
            ],
            pagination: {
                rowsPerPage: -1,
            },
        });

        if (isSuccess && data != null) {
            if (start.value == null || end.value == null) {
                const thisWeekEnd = tmpEnd;
                const thisWeekStart = tmpEnd.startOf('week');
                const lastWeekEnd = thisWeekStart.plus({ seconds: -1 });
                const lastWeekStart = tmpStart;

                weekData.value.push(
                    createWeekData(thisWeekStart, thisWeekEnd, data),
                    createWeekData(lastWeekStart, lastWeekEnd, data)
                );
            } else {
                weekData.value.push(createWeekData(tmpStart, tmpEnd, data));
            }

            start.value = tmpStart;
            end.value = tmpEnd;
        }
    };

    const reload = async () => {
        store.reset();

        await loadNextWeekData();
    };

    return {
        data,
        isLoading,
        weekData,
        loadNextWeekData,
        updateRecord: store.updateRecord,
        reload,
    };
};

export default useTrackingRecordsMe;

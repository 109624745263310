import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { DispatchingPermissions } from '~/modules/dispatching/enums/permissions/_types';

const usePermissionDispatchingEnum = () => {
    const { canProject } = usePermissions();

    const canManageEnums = (project: ProjectDetail | null): boolean => {
        return canProject([DispatchingPermissions.EnumManage], project);
    };

    return {
        canManageEnums,
    };
};

export default usePermissionDispatchingEnum;

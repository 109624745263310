import { FetchListOptions } from '~/modules/core/api/_types';
import {
    fetchTasksTrackingRecordsMeRequest,
    fetchTasksTrackingRecordsRequest,
    fetchTasksTimerRequest,
    patchTasksTimerStartRequest,
    putTasksTimerStopRequest,
    patchTasksTimerRequest,
    patchTasksTimeRecordRequest,
    deleteTasksTimeRecordRequest,
    postTasksTimeRecordCloneRequest,
    postTasksTimeRecordRequest,
    patchTasksTimeRecordApproveRequest,
    fetchTasksTrackingTimersRequest,
    patchTasksTimeRecordRejectRequest,
    fetchTasksTrackingTimeRecordReportRequest,
    fetchTasksTrackingTimeRecordReportExportRequest,
} from '~/modules/tasks/api/tracking';
import {
    TrackingTimeRecordRejectFormData,
    TrackingTimeRecordFormData,
    TrackingTimeRecordInfo,
    TrackingTimerFormData,
    TrackingTimerInfo,
    TrackingTimerStartFormData,
    TrackingTimeRecordReportInfo,
    TrackingTimeRecordReportGroupByKey,
    TrackingTimeRecordReportExportInfo,
} from '~/modules/tasks/api/tracking/_types';
import { useTasksTimerStore } from '~/modules/tasks/stores/tasksTimer';
import { FormHandler, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import { updateAndStoreData, updateAndStoreDataOrNotifyError } from '~/modules/core/services/utils';
import { DateTime } from 'luxon';
import { TasksIssueInfoShort } from '~/modules/tasks/api/issues/_types';
import { useTasksTimeRecordsMeStore } from '~/modules/tasks/stores/tasksTimeRecordsMe';

/**
 * @param opt
 */
export const fetchTasksTrackingRecordsMe = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordInfo[] | null;
}> => {
    return await fetchTasksTrackingRecordsMeRequest({ opt });
};

/**
 * @param opt
 */
export const fetchTasksTrackingRecords = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordInfo[] | null;
}> => {
    return await fetchTasksTrackingRecordsRequest({ opt });
};

/**
 * @param opt
 */
export const fetchTasksTrackingTimers = async (
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TrackingTimerInfo[] | null;
}> => {
    return await fetchTasksTrackingTimersRequest({ opt });
};

/**
 * @param key
 * @param opt
 */
export const fetchTasksTrackingTimeRecordReport = async (
    key: TrackingTimeRecordReportGroupByKey,
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordReportInfo | null;
}> => {
    return await fetchTasksTrackingTimeRecordReportRequest({ key, opt });
};

/**
 * @param opt
 */
export const fetchTasksTrackingTimeRecordReportExport = async (
    key: TrackingTimeRecordReportGroupByKey,
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordReportExportInfo | null;
}> => {
    return await fetchTasksTrackingTimeRecordReportExportRequest({ key, opt });
};

/**
 * @param opt
 */
export const fetchTasksTrackingTimer = async (
    silent?: boolean
): Promise<{
    isSuccess: boolean;
    data: TrackingTimerInfo | null;
}> => {
    const activeTimer = getActiveTimerFromStore();

    const { isSuccess, data } = await fetchTasksTimerRequest(!silent);

    if (isSuccess) {
        activeTimer.value = data;
    }

    return { isSuccess, data };
};

/**
 * @param values
 * @param ctx
 */
export const startTrackingTimer: FormHandler<
    TrackingTimerStartFormData,
    { isSuccess: boolean; data: TrackingTimerInfo | null }
> = async (values, ctx) => {
    return await updateAndStoreData(
        ctx,
        getActiveTimerFromStore,
        async () =>
            await patchTasksTimerStartRequest({
                data: values,
            })
    );
};

/**
 * @param values
 * @param ctx
 */
export const stopTrackingTimer: FormHandler<
    TrackingTimerFormData,
    { isSuccess: boolean; data: TrackingTimeRecordInfo | null }
> = async (values, ctx) => {
    const activeTimer = getActiveTimerFromStore();
    const { updateRecord } = useTrackingRecordsMe();

    const { isSuccess, isValidationError, data, errors } = await putTasksTimerStopRequest({ data: values });

    if (isSuccess && data != null) {
        activeTimer.value = null;
        updateRecord(data);
    } else if (isValidationError) {
        ctx.setErrors(errors);
    }

    return { isSuccess, data };
};

/**
 * @param description
 * @param issue
 */
export const changeDescriptionWithIssueTrackingTimer = async (
    description: string | null,
    issue: TasksIssueInfoShort | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateTimer } = useTasksTimerStore();
    return await updateAndStoreDataOrNotifyError<TrackingTimerInfo, TrackingTimerFormData>(
        () => patchTasksTimerRequest({ data: { Description: description, IssueId: issue } }),
        updateTimer,
        'tasks.form.timer.labels'
    );
};

/**
 * @param description
 */
export const changeDescriptionTrackingTimer = async (
    description: string | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateTimer } = useTasksTimerStore();
    return await updateAndStoreDataOrNotifyError<TrackingTimerInfo, TrackingTimerFormData>(
        () => patchTasksTimerRequest({ data: { Description: description } }),
        updateTimer,
        'tasks.form.timer.labels'
    );
};

/**
 * @param issue
 */
export const changeIssueTrackingTimer = async (
    issue: TasksIssueInfoShort | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateTimer } = useTasksTimerStore();
    return await updateAndStoreDataOrNotifyError<TrackingTimerInfo, TrackingTimerFormData>(
        () => patchTasksTimerRequest({ data: { IssueId: issue } }),
        updateTimer,
        'tasks.form.timer.labels'
    );
};

/**
 * @param startAt
 */
export const changeStartTrackingTimer = async (
    startAt: DateTime | null
): Promise<{
    isSuccess: boolean;
}> => {
    const { updateTimer } = useTasksTimerStore();
    return await updateAndStoreDataOrNotifyError<TrackingTimerInfo, TrackingTimerFormData>(
        () => patchTasksTimerRequest({ data: { StartAt: startAt } }),
        updateTimer,
        'tasks.form.timer.labels'
    );
};

/**
 * @param id
 * @param description
 */
export const changeDescriptionTrackingTimeRecord = async (
    id: number,
    description: string | null
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordInfo | null;
}> => {
    return await updateAndStoreDataOrNotifyError<TrackingTimeRecordInfo, TrackingTimeRecordFormData>(
        () => patchTasksTimeRecordRequest({ id, data: { Description: description } }),
        undefined,
        'tasks.form.timer.labels'
    );
};

/**
 * @param id
 * @param issueId
 */
export const changeIssueTrackingTimeRecord = async (
    id: number,
    issue: TasksIssueInfoShort | null
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordInfo | null;
}> => {
    return await updateAndStoreDataOrNotifyError<TrackingTimeRecordInfo, TrackingTimeRecordFormData>(
        () => patchTasksTimeRecordRequest({ id, data: { IssueId: issue } }),
        undefined,
        'tasks.form.timer.labels'
    );
};

/**
 * @param id
 * @param start
 * @param end
 */
export const changeTimeRangeTrackingTimeRecord = async (
    id: number,
    start: DateTime | null,
    end: DateTime | null
): Promise<{
    isSuccess: boolean;
    data: TrackingTimeRecordInfo | null;
}> => {
    return await updateAndStoreDataOrNotifyError<TrackingTimeRecordInfo, TrackingTimeRecordFormData>(
        () => patchTasksTimeRecordRequest({ id, data: { StartAt: start, EndAt: end } }),
        undefined,
        'tasks.form.timer.labels'
    );
};

/**
 * @param id
 */
export const deleteTrackingTimeRecord = async (
    id: number
): Promise<{
    isSuccess: boolean;
}> => {
    const { deleteRecord } = useTasksTimeRecordsMeStore();
    const { isSuccess } = await deleteTasksTimeRecordRequest(id);

    if (isSuccess) {
        deleteRecord(id);
    }

    return { isSuccess };
};

/**
 * @param id
 */
export const cloneTrackingTimeRecord = async (
    id: number
): Promise<{
    isSuccess: boolean;
}> => {
    const activeTimer = getActiveTimerFromStore();
    const { isSuccess, data } = await postTasksTimeRecordCloneRequest(id);

    if (isSuccess && data != null) {
        activeTimer.value = data;
    }

    return { isSuccess };
};

/**
 * @param values
 * @param ctx
 */
export const changeTrackingTimeRecord: FormHandlerWithArgs<
    number,
    TrackingTimeRecordFormData,
    { isSuccess: boolean; data: TrackingTimeRecordInfo | null }
> = async (id, values, ctx) => {
    const { isSuccess, isValidationError, data, errors } = await patchTasksTimeRecordRequest({ id, data: values });

    if (isValidationError) {
        ctx.setErrors(errors);
    }

    return { isSuccess, data };
};

/**
 * @param values
 * @param ctx
 */
export const createTrackingTimeRecord: FormHandler<
    TrackingTimeRecordFormData,
    { isSuccess: boolean; data: TrackingTimeRecordInfo | null }
> = async (values, ctx) => {
    const { isSuccess, isValidationError, data, errors } = await postTasksTimeRecordRequest({ data: values });

    if (isValidationError) {
        ctx.setErrors(errors);
    }

    return { isSuccess, data };
};

/**
 * @param id
 */
export const approveTrackingTimeRecord = async (
    id: number
): Promise<{ isSuccess: boolean; data: TrackingTimeRecordInfo | null }> => {
    return await patchTasksTimeRecordApproveRequest({ id });
};

/**
 * @param values
 * @param ctx
 */
export const rejectTrackingTimeRecord: FormHandlerWithArgs<
    number,
    TrackingTimeRecordRejectFormData,
    { isSuccess: boolean; data: TrackingTimeRecordInfo | null }
> = async (id, values, ctx) => {
    const { isSuccess, isValidationError, data, errors } = await patchTasksTimeRecordRejectRequest({
        id,
        data: values,
    });

    if (isValidationError) {
        ctx.setErrors(errors);
    }

    return { isSuccess, data };
};

const getActiveTimerFromStore = (): Ref<TrackingTimerInfo | null> => {
    const { activeTimer } = storeToRefs(useTasksTimerStore());
    return activeTimer;
};

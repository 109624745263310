import { RouteLocationRaw } from 'vue-router';
import { ModuleKey } from '~/modules/core/enums/_types';
import routerInstance from '~/plugins/router';
import { HOME_ROUTE } from '~/plugins/router/_constants';

const moduleRouteNames = {
    [ModuleKey.Dispatching]: 'dispatching',
    [ModuleKey.Inventory]: 'inventory',
    [ModuleKey.HumanResources]: 'human-resources',
    [ModuleKey.HumanitarianCare]: 'humanitarian-care',
    [ModuleKey.Reporting]: 'reporting',
    [ModuleKey.Statistics]: 'statistics',
    [ModuleKey.Planning]: 'planning',
    [ModuleKey.Tasks]: 'tasks',
};

export const getModuleOverviewRouteTitle = (moduleKey: ModuleKey): string => {
    return `pages.${moduleRouteNames[moduleKey]}`;
};
export const getModuleOverviewRoute = (moduleKey: ModuleKey): RouteLocationRaw => {
    return { name: moduleRouteNames[moduleKey] };
};

export const getModuleRouteOverviewName = (moduleKey: ModuleKey): string => {
    return moduleRouteNames[moduleKey];
};

export const getModuleDetailRouteName = (moduleKey: ModuleKey): string => {
    return `${moduleRouteNames[moduleKey]}-id`;
};

export const getModuleDetailRoute = (moduleKey: ModuleKey, id: number): RouteLocationRaw => {
    return {
        name: `${moduleRouteNames[moduleKey]}-id`,
        params: {
            id,
        },
    };
};

export const getModuleDetailSettingsRoute = (moduleKey: ModuleKey, projectId: number): RouteLocationRaw => {
    return {
        name: `${moduleRouteNames[moduleKey]}-id-settings`,
        params: {
            id: projectId,
        },
    };
};

export const getModuleDetailSettingsSectionRoute = (
    moduleKey: ModuleKey,
    projectId: number,
    section: string
): RouteLocationRaw => {
    return {
        name: `${moduleRouteNames[moduleKey]}-id-settings-section`,
        params: {
            id: projectId,
            section: section,
        },
    };
};

export const getSubjectDetailSettingsSectionRoute = (subjectId: number, section: string): RouteLocationRaw => {
    return {
        name: 'settings-subjects-id-settings-section',
        params: {
            id: subjectId,
            section: section,
        },
    };
};

export const redirectToLocation = (
    to: RouteLocationRaw,
    replace = true,
    fallbackTo: RouteLocationRaw = { name: HOME_ROUTE }
) => {
    try {
        return replace ? routerInstance.replace(to) : routerInstance.push(to);
    } catch (e) {
        return replace ? routerInstance.replace(fallbackTo) : routerInstance.push(fallbackTo);
    }
};

export const executeRoute = async (route: RouteLocationRaw): Promise<boolean> => {
    return (await routerInstance.push(route)) == null;
};

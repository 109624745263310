export const NOT_FOUND_ROUTE = 'all';
export const AUTH_ROUTE = 'auth-login';
export const AUTH_2FA_ROUTE = 'auth-login2fa';
export const FORGOT_PASSWORD_START = 'auth-forgot-password';
export const SET_PASSWORD_FINISH = 'auth-set-password-token-email';
export const CHANGE_PASSWORD_FINISH = 'auth-change-password-token-email';
export const HOME_ROUTE = 'index';
export const ACCESS_DENIED_ROUTE = 'access-denied';

export const PUBLIC_ROUTES = [
    NOT_FOUND_ROUTE,
    ACCESS_DENIED_ROUTE,
    AUTH_ROUTE,
    AUTH_2FA_ROUTE,
    FORGOT_PASSWORD_START,
    SET_PASSWORD_FINISH,
    CHANGE_PASSWORD_FINISH,
];

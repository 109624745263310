import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiResponseContext } from '~/plugins/apiClient/_types';
import { rawToConfig } from './_transformers';
import { ConfigDataInfo } from './_types';

const apiEndpointPrefix = '';

export const fetchConfigRequest: ApiHandler<ApiResponseContext<ConfigDataInfo | null>> = async () => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'config'), {
        transform: rawToConfig,
    });
};

import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { pick } from 'lodash-es';
import { AuthData, AuthToken, AuthUser } from '~/plugins/auth/_types';
import { rawToModuleInfoArray } from '~/modules/core/api/modules/_transformers';
import { getFullName } from '~/modules/core/utils/userUtils';

export const rawToAuthToken: ApiResponseTransformer<AuthToken> = (data) => {
    return {
        Value: data.Token,
        ExpiresAt: new Date(data.ExpiresAt).getTime(),
        Validated: false,
    };
};

export const rawToAuthUser: ApiResponseTransformer<AuthUser | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, [
            'Id',
            'FirstName',
            'LastName',
            'Organization',
            'IsAdmin',
            'Avatar',
            'TotalUncheckedCount',
            'TotalCheckedCount',
            'AuthAdapter',
        ]),
        FullName: getFullName(data),
        Permissions: Array.isArray(data.Permissions) ? new Set<string>(data.Permissions) : new Set<string>(),
        Modules: rawToModuleInfoArray(data.Modules) || [],
    };
};

export const rawToAuthData: ApiResponseTransformer<AuthData | null> = (data) => {
    if (data == null) return null;
    return {
        Required2FA: data.Required2FA,
        Token: rawToAuthToken(data),
        User: rawToAuthUser(data.User),
    };
};

import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import {
    TrackingTimeRecordRejectFormData,
    TrackingTimeRecordFormData,
    TrackingTimeRecordInfo,
    TrackingTimerFormData,
    TrackingTimerInfo,
    TrackingTimerStartFormData,
    TrackingTimeRecordReportInfo,
    TrackingTimeRecordReportGroupByKey,
    TrackingTimeRecordReportExportInfo,
} from './_types';
import {
    rawToTrackingTimeRecordInfo,
    rawToTrackingTimeRecordInfoArray,
    rawToTrackingTimeRecordReportExportInfo,
    rawToTrackingTimeRecordReportInfo,
    rawToTrackingTimerInfo,
    rawToTrackingTimerInfoArray,
    trackingTimeRecordFormDataToRaw,
    trackingTimerFormDataToRaw,
    trackingTimerStartFormDataToRaw,
} from './_transformers';

// > Endpoint prefix
const apiEndpointPrefix = 'tasks/tracking';

export const fetchTasksTrackingRecordsMeRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TrackingTimeRecordInfo[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'records/me'), {
        transform: rawToTrackingTimeRecordInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksTrackingRecordsRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TrackingTimeRecordInfo[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'records'), {
        transform: rawToTrackingTimeRecordInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksTrackingTimersRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TrackingTimerInfo[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'timers'), {
        transform: rawToTrackingTimerInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksTimerRequest: ApiHandlerWithArgs<boolean, ApiResponseContext<TrackingTimerInfo | null>> = async (
    notify
) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'me/timer'), {
        transform: rawToTrackingTimerInfo,
        notify: notify,
    });
};

export const fetchTasksTrackingTimeRecordReportRequest: ApiHandlerWithData<
    { key: TrackingTimeRecordReportGroupByKey; opt?: FetchListOptions },
    ApiResponseContext<TrackingTimeRecordReportInfo | null>
> = async ({ key, opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `records/group-by/${key}`), {
        transform: rawToTrackingTimeRecordReportInfo,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksTrackingTimeRecordReportExportRequest: ApiHandlerWithData<
    { key: TrackingTimeRecordReportGroupByKey; opt?: FetchListOptions },
    ApiResponseContext<TrackingTimeRecordReportExportInfo | null>
> = async ({ key, opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `records/group-by/${key}/export`), {
        transform: rawToTrackingTimeRecordReportExportInfo,
        params: fetchListOptionsToQuery(opt),
        responseType: 'blob',
    });
};

export const patchTasksTimerStartRequest: ApiHandlerWithData<
    {
        data: TrackingTimerStartFormData;
    },
    ApiResponseContext<TrackingTimerInfo | null, TrackingTimerStartFormData>
> = async ({ data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, 'me/timer/start'), trackingTimerStartFormDataToRaw(data), {
        transform: rawToTrackingTimerInfo,
    });
};

export const patchTasksTimerRequest: ApiHandlerWithData<
    {
        data: TrackingTimerFormData;
    },
    ApiResponseContext<TrackingTimerInfo | null, TrackingTimerFormData>
> = async ({ data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, 'me/timer'), trackingTimerFormDataToRaw(data), {
        transform: rawToTrackingTimerInfo,
    });
};

export const putTasksTimerStopRequest: ApiHandlerWithData<
    {
        data: TrackingTimerFormData;
    },
    ApiResponseContext<TrackingTimeRecordInfo | null, TrackingTimerFormData>
> = async ({ data }) => {
    return api.put(formatApiUrl(apiEndpointPrefix, 'me/timer/stop'), trackingTimerFormDataToRaw(data), {
        transform: rawToTrackingTimeRecordInfo,
    });
};

export const postTasksTimeRecordCloneRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<TrackingTimerInfo | null>
> = async (id) => {
    return api.post(formatApiUrl(apiEndpointPrefix, `records/${id}/clone`), null, {
        transform: rawToTrackingTimerInfo,
    });
};

export const postTasksTimeRecordRequest: ApiHandlerWithData<
    {
        data: TrackingTimeRecordFormData;
    },
    ApiResponseContext<TrackingTimeRecordInfo | null, TrackingTimeRecordFormData>
> = async ({ data }) => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'records'), trackingTimeRecordFormDataToRaw(data), {
        transform: rawToTrackingTimeRecordInfo,
    });
};

export const patchTasksTimeRecordRequest: ApiHandlerWithData<
    {
        id: number;
        data: TrackingTimeRecordFormData;
    },
    ApiResponseContext<TrackingTimeRecordInfo | null, TrackingTimeRecordFormData>
> = async ({ id, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `records/${id}`), trackingTimeRecordFormDataToRaw(data), {
        transform: rawToTrackingTimeRecordInfo,
    });
};

export const patchTasksTimeRecordApproveRequest: ApiHandlerWithData<
    {
        id: number;
    },
    ApiResponseContext<TrackingTimeRecordInfo | null>
> = async ({ id }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `records/${id}/approve`), null, {
        transform: rawToTrackingTimeRecordInfo,
    });
};

export const patchTasksTimeRecordRejectRequest: ApiHandlerWithData<
    {
        id: number;
        data: TrackingTimeRecordRejectFormData;
    },
    ApiResponseContext<TrackingTimeRecordInfo | null, TrackingTimeRecordRejectFormData>
> = async ({ id, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `records/${id}/reject`), data, {
        transform: rawToTrackingTimeRecordInfo,
    });
};

export const deleteTasksTimeRecordRequest: ApiHandlerWithData<number, ApiResponseContext<null>> = async (id) => {
    return api.delete(formatApiUrl(apiEndpointPrefix, `records/${id}`));
};

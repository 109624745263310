import { createI18n, I18nOptions, PluralizationRule, PluralizationRulesMap } from 'vue-i18n';
import { debug } from '~/utils/debugUtilts';
import config from '~/plugins/config';
import { merge } from 'lodash-es';

const _createI18n = () => {
    const { locale, fallbackLocale } = config.app;

    const resolveLocaleMessages = (): I18nOptions['messages'] => {
        const messages: I18nOptions['messages'] = {};
        const localeFiles = import.meta.glob<{ default: unknown }>(`../../modules/**/locales/**/*.json`, {
            eager: true,
        });

        Object.entries(localeFiles).forEach(([key, value]) => {
            const matchedLocale = key.match(/([A-Za-z0-9-_]+)\./i);
            const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/locales/);

            if (matchedLocale?.length !== 2 || matchedModule?.length !== 2) {
                return debug('[Plugin i18n]', `Can't load ${key} locale.`);
            }

            const locale = matchedLocale[1];
            const module = matchedModule[1];

            if (!config.isModuleSupported(module) || !config.isLocaleSupported(locale)) {
                return debug('[Plugin i18n]', `Module ${module} or locale ${locale} is not supported.`);
            }

            messages[locale] = merge(messages[locale], value.default);
        });

        return messages;
    };

    const createPluralizationMap = (): PluralizationRulesMap => ({
        cs: (choice: number, choicesLength: number, orgRule?: PluralizationRule): number => {
            if (choice === 0) return choicesLength === 4 ? 0 : choicesLength - 1;
            if (choice === 1) return choicesLength - 3;
            if (choice > 1 && choice <= 4) return choicesLength - 2;
            if (choice > 4) return choicesLength - 1;

            return orgRule ? orgRule(choice, choicesLength) : 0;
        },
    });

    return createI18n({
        legacy: false,
        globalInjection: true,
        locale,
        fallbackLocale,
        pluralRules: createPluralizationMap(),
        messages: resolveLocaleMessages(),
    });
};

export default _createI18n;

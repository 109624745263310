import { AppPlugin } from '~/plugins/_types';
import jobManagerInstance, { resolveJobs } from '~/plugins/jobManager';

export default {
    name: 'jobManager',
    install: async () => {
        const jobs = resolveJobs();

        jobManagerInstance.registerBatch(jobs);
    },
    priority: 3,
} satisfies AppPlugin;

import { AppPlugin } from '~/plugins/_types';
import router from '~/plugins/router';

export default {
    name: 'router',
    install: async (app) => {
        app.use(router);
    },
    priority: 10,
} satisfies AppPlugin;

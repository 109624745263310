import { Locale } from '~/plugins/i18n/_types';
import localeIsoCodes from '~/plugins/i18n/isoCodes.json';
import config from '~/plugins/config';

/**
 * Get locale ISO Code
 * @param locale
 */
export const getLocaleIsoCode = (locale: Locale | string): string =>
    localeIsoCodes[<Locale>locale] || config.app.locale;

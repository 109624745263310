import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionTasksTracking from '~/modules/tasks/composables/permissions/usePermissionsTasksTracking';
import usePermissionTasksGantt from '~/modules/tasks/composables/permissions/usePermissionsTasksGantt';
import { isTasksTrackingManageTab } from '~/modules/tasks/enums/_types';

export const redirects = (): RoutePageRedirects => {
    const { canManageTrackTimeMeAny, canManageTrackTimeOthersAny } = usePermissionTasksTracking();
    const { canViewGanttsAny } = usePermissionTasksGantt();

    return {
        'tasks-tracking': () => (canManageTrackTimeMeAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'tasks-tracking-manage-section': (to) => {
            if (!isTasksTrackingManageTab(to.params.section?.toString() || '')) return RedirectType.NotFound;

            return canManageTrackTimeOthersAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied;
        },
        'tasks-gantts': () => (canViewGanttsAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

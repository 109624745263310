import config from '~/plugins/config';
import { useAuthStore } from '~/plugins/auth';
import { t } from '~/plugins/i18n';
import { ModuleSimpleInfo } from '~/modules/core/api/modules/_types';
import { ModuleKey } from '~/modules/core/enums/_types';

const useModules = () => {
    const store = useAuthStore();

    const modules = computed(() => store.user?.Modules.filter((x) => config.isModuleSupported(x.Key.toString())) || []);
    const moduleEnums = computed(() =>
        modules.value.map((x) => {
            return {
                ...x,
                Label: t(`modules.${x.Key}`),
            };
        })
    );

    const getModuleEnumById = (id: number): ModuleSimpleInfo | null => {
        return moduleEnums.value.find((x) => x.Id === id) || null;
    };

    const getModuleEnumByKey = (key: ModuleKey): ModuleSimpleInfo | null => {
        return moduleEnums.value.find((x) => x.Key === key) || null;
    };

    return {
        modules,
        moduleEnums,
        getModuleEnumById,
        getModuleEnumByKey,
    };
};

export default useModules;

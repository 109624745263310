import { Locale } from '~/plugins/i18n/_types';
import { Config, EnvMode } from '~/plugins/config/_types';
import { debug } from '~/utils/debugUtilts';
import { parseEnvValue, parseEnvValueOrFail } from '~/plugins/config/utils';
import { ModuleKey } from '~/modules/core/enums/_types';

const {
    DEV,
    MODE,
    VITE_API_URL,
    VITE_APP_VERSION,
    VITE_DEFAULT_LOCALE,
    VITE_FALLBACK_LOCALE,
    VITE_LOGIN_PLACEHOLDER,
    VITE_PASSWORD_PLACEHOLDER,
    VITE_2FA_FAKE_SMS_CODE,
    VITE_SENTRY_DSN,
    VITE_SENTRY_ENABLED,
    VITE_SENTRY_AUTH_TOKEN,
    VITE_SENTRY_URL,
    VITE_SENTRY_ORG,
    VITE_SENTRY_PROJECT,
    VITE_SENTRY_RELEASE,
    VITE_MAPS_API_KEY,
} = import.meta.env;

const createConfig = () => {
    const config: Config = {
        app: {
            version: parseEnvValue<string>(VITE_APP_VERSION, '0.0.0'),
            locale: parseEnvValue<Locale>(VITE_DEFAULT_LOCALE, 'cs'),
            fallbackLocale: parseEnvValue<Locale>(VITE_FALLBACK_LOCALE, 'cs'),
            availableLocales: ['cs', 'en'],
        },
        placeholders: {
            login: parseEnvValue<string>(VITE_LOGIN_PLACEHOLDER, ''),
            password: parseEnvValue<string>(VITE_PASSWORD_PLACEHOLDER, ''),
            fakeSmsCode: parseEnvValue<string>(VITE_2FA_FAKE_SMS_CODE, ''),
        },
        api: {
            baseURL: parseEnvValueOrFail<string>(VITE_API_URL),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest',
            },
        },
        storage: {
            data: {
                auth: {
                    key: 'auth_token',
                },
                locale: {
                    key: 'lang',
                },
            },
            type: 'local',
        },
        env: {
            dev: DEV,
            mode: MODE as EnvMode,
        },
        sentry: {
            enabled: JSON.parse(parseEnvValue<string>(VITE_SENTRY_ENABLED, 'false')),
            dsn: parseEnvValue<string>(VITE_SENTRY_DSN, ''),
            org: parseEnvValue<string>(VITE_SENTRY_ORG, ''),
            project: parseEnvValue<string>(VITE_SENTRY_PROJECT, ''),
            url: parseEnvValue<string>(VITE_SENTRY_URL, ''),
            authToken: parseEnvValue<string>(VITE_SENTRY_AUTH_TOKEN, ''),
            release: parseEnvValue<string>(VITE_SENTRY_RELEASE, ''),
        },
        maps: {
            apiKey: parseEnvValue<string>(VITE_MAPS_API_KEY, ''),
        },
        modules: __APP_RESOLVED_MODULES__ as Config['modules'],
        // NOTE: order of this array is also order of rendering on screen
        // @plachtova: defineAsyncComponent has limitation that it cannot be called from function
        // This limitation brokes our module isolation
        homeWidgets: [
            {
                module: null,
                widget: defineAsyncComponent<Component>(
                    () => import('../../modules/core/components/home/widgets/HomeFavoriteProjectsWidget.vue')
                ),
            },
            {
                module: ModuleKey.Statistics,
                widget: defineAsyncComponent<Component>(
                    () => import('../../modules/statistics/components/home/widgets/StatisticsMasterProductWidget.vue')
                ),
            },
            {
                module: ModuleKey.Dispatching,
                widget: defineAsyncComponent<Component>(
                    () =>
                        import(
                            '../../modules/dispatching/components/home/widgets/DispatchingAssignedIssuesTableWidget.vue'
                        )
                ),
            },
            {
                module: ModuleKey.Dispatching,
                widget: defineAsyncComponent<Component>(
                    () =>
                        import(
                            '../../modules/dispatching/components/home/widgets/DispatchingAssignedIssuesMapWidget.vue'
                        )
                ),
            },
            {
                module: ModuleKey.Tasks,
                widget: defineAsyncComponent<Component>(
                    () => import('../../modules/tasks/components/home/widgets/TasksAssignedIssuesTableWidget.vue')
                ),
            },
            // @plachtova: awebsys weather widget nepotrebuje, proto zakomentujeme, aby se nezobrazil na homepage
            // {
            //     module: null,
            //     widget: defineAsyncComponent<Component>(
            //         () => import('../../modules/core/components/home/widgets/WeatherWidget.vue')
            //     ),
            // },
        ],
    };

    const isLocaleSupported = (locale: Locale | string): boolean =>
        config.app.availableLocales.includes(locale as Locale);
    const isModuleSupported = (module: string): boolean => config.modules.some((m) => m.name === module);

    debug('[ConfigService]', config);

    return Object.freeze({
        ...config,
        isLocaleSupported,
        isModuleSupported,
    });
};

export default createConfig;

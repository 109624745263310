import usePermissions from './usePermissions';
import { GlobalPermission } from '~/modules/core/enums/permissions/_types';

const usePermissionGlobal = () => {
    const { can } = usePermissions();

    const canLabelsManage = (): boolean => {
        return can([GlobalPermission.LabelsManage]);
    };

    const canGlobalProjectUserManage = (): boolean => {
        return can([GlobalPermission.GlobalProjectUserManage]);
    };

    return {
        canLabelsManage,
        canGlobalProjectUserManage,
    };
};

export default usePermissionGlobal;

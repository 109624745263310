import { AuthData, AuthUser } from '~/plugins/auth/_types';
import { ApiHandler, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { AuthLoginFormData, TwoFactorAuthSmsChallengeData } from './_types';
import { rawToAuthData, rawToAuthUser } from './_transformers';

// > Endpoint prefix
const apiEndpointPrefix = 'auth';

/**
 * @param values
 */
export const loginRequest: ApiHandlerWithData<
    AuthLoginFormData,
    ApiResponseContext<AuthData | null, AuthLoginFormData>
> = async (values) => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'login'), values, { transform: rawToAuthData });
};

export const twoFactorAuthSmsChallengeRequest: ApiHandler<
    ApiResponseContext<null, TwoFactorAuthSmsChallengeData>
> = async () => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'login/2fa-challenge/sms'), null, { transform: rawToAuthUser });
};

export const twoFactorAuthSmsLoginRequest: ApiHandlerWithData<
    TwoFactorAuthSmsChallengeData,
    ApiResponseContext<AuthUser | null, TwoFactorAuthSmsChallengeData>
> = async (values) => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'login/sms'), values, { transform: rawToAuthUser });
};

export const logoutRequest: ApiHandler = async () => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'logout'));
};

export const fetchUserRequest: ApiHandler<ApiResponseContext<AuthUser | null>> = async () => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'info'), { transform: rawToAuthUser });
};

import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionSubject from '~/modules/core/composables/permissions/usePermissionsSubject';
import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';

export const redirects = (): RoutePageRedirects => {
    const { canCreateAnyProject } = usePermissionSubject();
    const { canManageAny } = usePermissionProject();

    return {
        'settings-projects': () => (canManageAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        'settings-projects-new': () => (canCreateAnyProject() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { TasksPermissions } from '~/modules/tasks/enums/permissions/_types';

const usePermissionTasksIssue = () => {
    const { can, canProject } = usePermissions();

    const canCreateIssue = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueCreate], project);
    };

    const canCreateDueDate = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateDueDate], project);
    };

    const canEditTextIssue = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateBase], project);
    };

    const canEditIssueAssignee = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateAssignee], project);
    };

    const canEditIssueState = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateState], project);
    };

    const canEditIssuePriority = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdatePriority], project);
    };

    const canEditIssueDueDate = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateDueDate], project);
    };

    const canEditIssueEstimatedTime = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueUpdateEstimatedTime], project);
    };

    const canCloseIssue = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueClose], project);
    };

    const canReopenIssue = (project: ProjectDetail | null): boolean => {
        return can([TasksPermissions.Manage]) || canProject([TasksPermissions.IssueClose], project);
    };

    return {
        canCreateIssue,
        canCreateDueDate,
        canEditTextIssue,
        canEditIssueAssignee,
        canEditIssuePriority,
        canEditIssueState,
        canEditIssueDueDate,
        canEditIssueEstimatedTime,
        canCloseIssue,
        canReopenIssue,
    };
};

export default usePermissionTasksIssue;

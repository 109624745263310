import { pick } from 'lodash-es';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { ConfigDataInfo } from './_types';

export const rawToConfig: ApiResponseTransformer<ConfigDataInfo | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['AppVersion', 'UploadMaxFilesize']),
        Password: {
            ...pick(data.Password, ['MinLength', 'MixedCase', 'Numbers', 'Symbols']),
        },
    };
};

import { StatisticsMasterProductDetail } from '~/modules/statistics/api/products/_types';

const useStatisticsMasterProductStore = defineStore('statistics-master-product', () => {
    const activeProduct = ref<StatisticsMasterProductDetail | null>(null);

    const reset = () => {
        activeProduct.value = null;
    };

    return {
        activeProduct,
        reset,
    };
});

export default useStatisticsMasterProductStore;

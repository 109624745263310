import Cookies from 'js-cookie';
import config from '~/plugins/config';
import { BrowserStorage } from './_types';

const appCookieStorage = (): BrowserStorage => {
    const set: BrowserStorage['set'] = (key, value) => {
        if (config.storage.type !== 'cookie') return;

        Cookies.set(key, JSON.stringify(value), config.storage.cookie);
    };

    const get: BrowserStorage['get'] = (key, defaultValue = null) => {
        try {
            const value = Cookies.get(key) ?? '';
            return JSON.parse(value);
        } catch {
            return defaultValue;
        }
    };

    const remove: BrowserStorage['remove'] = (key) => {
        if (config.storage.type !== 'cookie') return;

        Cookies.remove(key);
    };

    const clear: BrowserStorage['clear'] = () => {
        if (config.storage.type !== 'cookie') return;

        for (const _key in config.storage.data) {
            const key = _key as keyof typeof config.storage.data;
            Cookies.remove(config.storage.data[key].key, config.storage.cookie);
        }
    };

    return {
        set,
        get,
        remove,
        clear,
    };
};

export default appCookieStorage;

import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { HumanitarianCarePermissions } from '~/modules/humanitarian-care/enums/permissions/_types';

const usePermissionsHumanitarianCare = () => {
    const { canProject } = usePermissions();

    const canCreateTransaction = (project: ProjectDetail | null): boolean => {
        return canProject([HumanitarianCarePermissions.TransactionCreate], project);
    };

    const canDeleteTransaction = (project: ProjectDetail | null): boolean => {
        return canProject([HumanitarianCarePermissions.TransactionDelete], project);
    };

    const canRestoreTransaction = (project: ProjectDetail | null): boolean => {
        return canProject([HumanitarianCarePermissions.TransactionRestore], project);
    };

    const canManageSettings = (project: ProjectDetail | null): boolean => {
        return canProject([HumanitarianCarePermissions.SettingsManage], project);
    };

    return {
        canCreateTransaction,
        canDeleteTransaction,
        canRestoreTransaction,
        canManageSettings,
    };
};

export default usePermissionsHumanitarianCare;

import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { API_DATE_FORMAT, MAX_ROLE_ACCESS_LEVEL } from '~/modules/core/constants';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { rawToAppFileInfo } from '~/modules/core/api/files/_transformers';
import { rawToUserDetail, rawToUserInfo } from '~/modules/core/api/users/_transformers';
import {
    rawToProjectSimpleInfo,
    rawToRoleSimpleDetailArray,
    rawToRoleSimpleInfoArray,
} from '~/modules/core/api/enums/_transformers';
import {
    SubjectContact,
    SubjectDetail,
    SubjectInfo,
    SubjectInfoShort,
    SubjectProjectInfo,
    SubjectUserDetail,
    SubjectUserInfo,
    SubjectUserProjectRole,
} from './_types';

export const rawToSubjectContact: ApiResponseTransformer<SubjectContact | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Street', 'Town', 'Zip', 'Country']),
    };
};

export const rawToSubjectProjectInfo: ApiResponseTransformer<SubjectProjectInfo | null> = (data) => {
    if (data == null) return null;

    const project = rawToProjectSimpleInfo(data.Project);
    if (project == null) {
        console.error(
            "[SubjectProjectInfoTransformer] - Data for property 'Project' from [ProjectEnumInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        ...pick(data, ['Id', 'Type']),
        Project: project,
        AccessLevel: data.AccessLevel ?? MAX_ROLE_ACCESS_LEVEL,
        ExpiresAt: data.ExpiresAt == null ? null : DateTime.fromFormat(data.ExpiresAt, API_DATE_FORMAT),
        Permissions: Array.isArray(data.Permissions) ? new Set<string>(data.Permissions) : new Set<string>(),
    };
};

export const rawToSubjectInfoShort: ApiResponseTransformer<SubjectInfoShort | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Name', 'NameShort', 'Type']),
    };
};

export const rawToSubjectInfo: ApiResponseTransformer<SubjectInfo | null> = (data) => {
    if (data == null) return null;
    const info = rawToSubjectInfoShort(data);
    if (info == null) {
        console.error(
            '[SubjectInfoTransformer] - [SubjectInfoShortTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }
    return {
        ...info,
        ...pick(data, ['Level', 'Description']),
        Parent: data.Parent ? rawToSubjectInfoShort(data.Parent) : null,
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        UpdatedAt: DateTime.fromISO(data.UpdatedAt),
        DeletedAt: data.DeletedAt ? DateTime.fromISO(data.DeletedAt) : null,
        Permissions: Array.isArray(data.Permissions) ? new Set<string>(data.Permissions) : new Set<string>(),
    };
};

export const rawToSubjectDetail: ApiResponseTransformer<SubjectDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToSubjectInfo(data);
    if (info == null) {
        console.error(
            '[SubjectDetailTransformer] - [SubjectInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        Contact: data.Contact ? rawToSubjectContact(data.Contact) : null,
        Logo: rawToAppFileInfo(data.Logo),
        Projects: rawToSubjectProjectInfoArray(data.Projects) || [],
    };
};

export const rawToSubjectUserInfo: ApiResponseTransformer<SubjectUserInfo | null> = (data) => {
    if (data == null) return null;

    const info = rawToUserInfo(data);
    if (info == null) {
        console.error(
            '[SubjectUserInfoTransformer] - [UserInfoTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        Roles: rawToRoleSimpleDetailArray(data.Roles) || [],
    };
};

export const rawToSubjectUserDetail: ApiResponseTransformer<SubjectUserDetail | null> = (data) => {
    if (data == null) return null;

    const info = rawToUserDetail(data);
    if (info == null) {
        console.error(
            '[SubjectUserDetailTransformer] - [UserDetailTransformer] returned null. Cannot transform object from BE.'
        );
        return null;
    }

    return {
        ...info,
        SubjectRoles: rawToRoleSimpleInfoArray(data.SubjectRoles) || [],
        ProjectRoles: rawToSubjectUserProjectRoleArray(data.ProjectRoles) || [],
    };
};

export const rawToSubjectUserProjectRole: ApiResponseTransformer<SubjectUserProjectRole | null> = (data) => {
    if (data == null) return null;

    return {
        ...pick(data, ['ProjectSubjectId', 'Type']),
        Roles: rawToRoleSimpleInfoArray(data.Roles) || [],
    };
};

export const rawToSubjectInfoShortArray: ApiResponseTransformer<SubjectInfoShort[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectInfoShort(x)).filter((x): x is SubjectInfoShort => !!x);
};

export const rawToSubjectInfoArray: ApiResponseTransformer<SubjectInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectInfo(x)).filter((x): x is SubjectInfo => !!x);
};

export const rawToSubjectProjectInfoArray: ApiResponseTransformer<SubjectProjectInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectProjectInfo(x)).filter((x): x is SubjectProjectInfo => !!x);
};

export const rawToSubjectUserInfoArray: ApiResponseTransformer<SubjectUserInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectUserInfo(x)).filter((x): x is SubjectUserInfo => !!x);
};

export const rawToSubjectUserProjectRoleArray: ApiResponseTransformer<SubjectUserProjectRole[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToSubjectUserProjectRole(x)).filter((x): x is SubjectUserProjectRole => !!x);
};

import { AppPlugin } from '~/plugins/_types';
import { useAuthStore } from '~/plugins/auth/store';

export default {
    name: 'auth',
    install: async (app) => {
        const authStore = useAuthStore();

        app.config.globalProperties.$auth = authStore;
        app.provide('auth', authStore);
    },
    priority: 5,
} satisfies AppPlugin;

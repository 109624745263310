import { RouteLocationNormalized } from 'vue-router';
import { RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionStatistics from '~/modules/statistics/composables/permissions/usePermissionsStatistics';
import { checkApiResponseRedirect } from '~/plugins/router/utils';

export const redirects = (): RoutePageRedirects => {
    const { getProjectDetailFromRoute, canManage } = usePermissionProject();

    const { canManageInventory, canManageDashboard, canManageMasterProduct } = usePermissionStatistics();

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canManage(response.data) || canManageInventory(response.data));
    };

    const canGoNewProduct = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canManageMasterProduct(response.data));
    };

    const canGoNewDashboard = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canManageDashboard(response.data));
    };

    return {
        'statistics-id-new-dashboard': canGoNewDashboard,
        'statistics-id-new-product': canGoNewProduct,
        'statistics-id-settings': canGoSettings,
        'statistics-id-settings-section': canGoSettings,
    };
};

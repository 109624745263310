import { Guid } from 'guid-ts';
import config from '~/plugins/config';

export const useHomeWidgets = () => {
    const _widgets = getHomeWidgets();

    const { modules } = useModules();

    const widgets = computed(() => {
        return _widgets.filter((x) => x.module == null || modules.value.map((y) => y.Key).includes(x.module));
    });

    return {
        widgets,
    };
};

export default useHomeWidgets;

const getHomeWidgets = () => {
    return config.homeWidgets.map((x) => {
        return {
            uid: Guid.newGuid().toString(),
            widget: x.widget,
            module: x.module,
        };
    });
};

import { HCTransactionInfo } from '~/modules/humanitarian-care/api/transactions/_types';

const useHCTransactionStore = defineStore('hc-transaction', () => {
    const activeTransaction = ref<HCTransactionInfo | null>(null);

    const reset = () => {
        activeTransaction.value = null;
    };

    return {
        activeTransaction,
        reset,
    };
});

export default useHCTransactionStore;

import { ProjectDetail } from '~/modules/core/api/projects/_types';
import usePermissions from '~/modules/core/composables/permissions/usePermissions';
import { DispatchingPermissions } from '~/modules/dispatching/enums/permissions/_types';

const usePermissionDispatchingIssue = () => {
    const { user, canProject } = usePermissions();

    const canCreateIssue = (project: ProjectDetail | null): boolean => {
        return canProject([DispatchingPermissions.IssueCreate], project);
    };

    const canEditIssue = (project: ProjectDetail | null, authorId?: number): boolean => {
        if (authorId == null) return false;
        if (user.value?.Id === authorId) return true;

        return canProject([DispatchingPermissions.IssueUpdate], project);
    };

    const canDeleteIssue = (project: ProjectDetail | null): boolean => {
        return canProject([DispatchingPermissions.IssueDelete], project);
    };

    const canChangeStateIssue = (project: ProjectDetail | null): boolean => {
        return canProject([DispatchingPermissions.IssueChangeState], project);
    };

    const canShowMenuIssue = (project: ProjectDetail | null, authorId?: number): boolean => {
        return canEditIssue(project, authorId) || canDeleteIssue(project) || canChangeStateIssue(project);
    };

    return {
        canCreateIssue,
        canEditIssue,
        canDeleteIssue,
        canChangeStateIssue,
        canShowMenuIssue,
    };
};

export default usePermissionDispatchingIssue;

import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationActionInfo } from '~/plugins/notificationManager/_types';

export enum NotificationActionStatisticsKey {
    Project = 'StatisticsProject',
}

export const getActions = (): NotificationActionInfo[] => {
    return [
        {
            group: NotificationActionStatisticsKey.Project,
            actionKey: NotificationActionStatisticsKey.Project,
            label: `notifications.actions.${NotificationActionStatisticsKey.Project}.actionTitle`,
            canExecuteFn: (info) => info.RouteParams != null,
            executeFn: async (info) => {
                if (info.RouteParams == null) return false;

                return await executeRoute({
                    name: 'statistics-id',
                    params: info.RouteParams,
                });
            },
        },
    ];
};

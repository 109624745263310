import { AxiosResponse, HttpStatusCode } from 'axios';
import { ApiErrors, ApiResponse, ApiGenericValues, ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { asyncTimeout } from '~/utils/debugUtilts';
import { isNull, isUndefined } from 'lodash-es';

export const parseRawResponse = async <T = unknown>(rawResponse: AxiosResponse): Promise<ApiResponse<T>> => {
    const baseResponse: AxiosResponse = {
        ...rawResponse,
        status: rawResponse.status || HttpStatusCode.ServiceUnavailable,
        statusText: rawResponse.statusText || 'Unknown response',
    };

    const isSuccess = baseResponse.status >= 200 && baseResponse.status < 300;
    const isValidationError = [HttpStatusCode.UnprocessableEntity].includes(baseResponse.status);
    const isAuthError = [HttpStatusCode.Unauthorized].includes(baseResponse.status);
    const isNotFoundError = baseResponse.status === HttpStatusCode.NotFound;

    let parsedData = baseResponse.data;
    try {
        if (baseResponse.config?.responseType === 'blob') {
            parsedData = await baseResponse.data.blob();
        }
    } catch (e) {
        // Exception
    }

    const response: ApiResponse = {
        ...baseResponse,
        data: parsedData?.Data ?? (parsedData || null),
        meta: parsedData?.Meta || null,
        message:
            (parsedData?.message ?? parsedData?.Message) ||
            (!isSuccess ? 'There was a problem processing the request.' : ''),
        isSuccess,
        isValidationError,
        isAuthError,
        isNotFoundError,
        errors: { _reason: '< ? >' },
    };

    if (!isSuccess) {
        response.errors = parseErrors(response);
        response.data = null;
    }

    return response;
};

const isErrorBagObject = (data: unknown): data is ApiGenericValues => {
    return data !== null && typeof data === 'object';
};

const isLaravelErrorBagObject = (data: unknown): data is { errors: ApiGenericValues } => {
    return isErrorBagObject(data) && 'errors' in data;
};

const parseErrors = ({ isValidationError, data, message }: ApiResponse): ApiErrors => {
    // Custom error bag response ("errors" -> "Data") { F1: [...], F2: [...]  } and HTTP status = UnprocessableEntity
    if (isValidationError && isErrorBagObject(data)) {
        return { ...data, _reason: 'Validation error' };
    }
    // Laravel error bag response { errors: { F1: [...], F2: [...] } and HTTP status = BadRequest or something else
    if (isLaravelErrorBagObject(data)) {
        return { ...data.errors, _reason: 'Validation Error' };
    }
    // "message"
    if (typeof data === 'string') {
        return { _reason: data };
    }

    return { _reason: message };
};

export const transformResponseData = <T>(data: T, transformer?: ApiResponseTransformer): T => {
    if (!isUndefined(transformer) && !isNull(data)) {
        return transformer(data) as T;
    }

    return data;
};

export const createFakeResponse = async <T = unknown>(
    data: T,
    status: HttpStatusCode,
    waitMs = 200
): Promise<ApiResponse> => {
    await asyncTimeout(waitMs);

    return parseRawResponse<T>({
        data,
        // @ts-ignore
        config: {},
        headers: {},
        status,
        statusText: 'Fake response success',
    });
};

import { DateTime, Duration } from 'luxon';
import { TrackingTimeRecordDayData, TrackingTimeRecordWeekData } from '~/modules/tasks/composables/_types';
import { TrackingTimeRecordInfo } from '~/modules/tasks/api/tracking/_types';

export const useTasksTimeRecordsMeStore = defineStore('tasks-time-records-me', () => {
    const weekData = ref<TrackingTimeRecordWeekData[]>([]);
    const start = ref<DateTime>();
    const end = ref<DateTime>();

    const updateRecord = (item: TrackingTimeRecordInfo) => {
        const data = weekData.value.find((x) => item.StartAt >= x.start && item.StartAt <= x.end);

        if (data == null) return;

        let day = (data.week as Record<number, TrackingTimeRecordDayData>)[item.StartAt.weekday];
        if (day == null) {
            day = {
                totalTime: Duration.fromMillis(0),
                day: DateTime.fromObject({ year: item.StartAt.year, month: item.StartAt.month, day: item.StartAt.day }),
                data: [],
            };
            (data.week as Record<number, TrackingTimeRecordDayData>)[item.StartAt.weekday] = day;
        }

        const recordIndex = day.data.findIndex((x: TrackingTimeRecordInfo) => item.Id === x.Id);

        if (recordIndex < 0) {
            day.data.push(item);
            day.totalTime = day.totalTime.plus(item.Duration);
            data.totalTime = data.totalTime.plus(item.Duration);
        } else {
            day.totalTime = day.totalTime.plus(item.Duration).minus(day.data[recordIndex].Duration);
            data.totalTime = data.totalTime.plus(item.Duration).minus(day.data[recordIndex].Duration);
            day.data[recordIndex] = item;
        }
    };

    const deleteRecord = (id: number) => {
        for (const week of weekData.value) {
            for (const day of Object.values(week.week)) {
                const recordIdx = day.data.findIndex((x) => x.Id === id);
                if (recordIdx >= 0) {
                    day.totalTime = day.totalTime.minus(day.data[recordIdx].Duration);
                    week.totalTime = week.totalTime.minus(day.data[recordIdx].Duration);
                    day.data.splice(recordIdx, 1);
                    return;
                }
            }
        }
    };

    const reset = () => {
        weekData.value = [];
        start.value = undefined;
        end.value = undefined;
    };

    return {
        weekData,
        start,
        end,
        updateRecord,
        deleteRecord,
        reset,
    };
});

import { HRWorkerDetail } from '~/modules/human-resources/api/workers/_types';

const useHRWorkerStore = defineStore('hr-worker', () => {
    const activeWorker = ref<HRWorkerDetail | null>(null);

    const reset = () => {
        activeWorker.value = null;
    };

    return {
        activeWorker,
        reset,
    };
});

export default useHRWorkerStore;

import { AppPlugin } from '~/plugins/_types';
import i18n from '~/plugins/i18n/index';

export default {
    name: 'i18n',
    install: async (app) => {
        app.use(i18n);
    },
    priority: 5,
} satisfies AppPlugin;

import { RouteLocationNormalized } from 'vue-router';
import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionReportingReports from '~/modules/reporting/composables/permissions/usePermissionsReportingReports';
import { checkApiResponseRedirect } from '~/plugins/router/utils';
import { fetchProjectDetail } from '~/modules/core/services/projects';
import { fetchReportingReportDetail } from '~/modules/reporting/services/reports';

export const redirects = (): RoutePageRedirects => {
    const { getProjectDetailFromRoute } = usePermissionProject();
    const { canCreateReport } = usePermissionReportingReports();

    const canGoNewReport = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canCreateReport(response.data));
    };

    const canGoRecreateReport = async (to: RouteLocationNormalized) => {
        const id = +to.params.id;

        if (!id) false;

        const reportResponse = await fetchReportingReportDetail(id);
        const reportRedirect = checkApiResponseRedirect(reportResponse);

        if (reportRedirect !== RedirectType.NoRedirect) return reportRedirect;
        // @plachtova: data should not be null but we need to check for ts
        if (reportResponse.data == null) return RedirectType.NotFound;

        const projectResponse = await fetchProjectDetail(reportResponse.data.Project.Id);
        return checkApiResponseRedirect(projectResponse, () => canCreateReport(projectResponse.data));
    };

    return {
        'reporting-id-new-report': canGoNewReport,
        'reporting-reports-id-new-report': canGoRecreateReport,
    };
};

import { FetchListOptions } from '~/modules/core/api/_types';
import {
    ReportDetail,
    ReportInfo,
    ReportingInfoSender,
    ReportTemplateInfo,
    ReportTemplateDetail,
} from '~/modules/reporting/api/reports/_types';
import {
    fetchReportingReportDetailRequest,
    fetchReportingReportListRequest,
    fetchReportingSenderRequest,
    fetchReportingTemplateListRequest,
    fetchReportingTemplateDetailRequest,
    postReportingAttachmentRequest,
    postReportingReportRequest,
} from '~/modules/reporting/api/reports';
import { FetchDetailOptions } from '~/modules/core/services/_types';
import { fetchAndStoreData, updateAndStoreFormData } from '~/modules/core/services/utils';
import routerInstance from '~/plugins/router';
import useReportingReportStore from '~/modules/reporting/stores/reports';
import useReportingSenderStore from '~/modules/reporting/stores/sender';
import useTemplateDetailStore from '~/modules/reporting/stores/templateDetail';
import { FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import { ReportFormData } from '~/modules/reporting/api/reports/_types';
import { AppFileInfo, UploadFileFormData } from '~/modules/core/api/files/_types';

export const fetchReportingReportList = async (
    projectId: number,
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: ReportInfo[] | null;
}> => {
    return await fetchReportingReportListRequest({ projectId, opt });
};

export const fetchReportingReportDetail = async (
    reportId: number,
    opt?: FetchDetailOptions
): Promise<{
    isSuccess: boolean;
    isAuthError: boolean;
    isNotFoundError: boolean;
    data: ReportDetail | null;
}> => {
    return await fetchAndStoreData(
        reportId,
        routerInstance,
        getActiveReportFromStore,
        async (reportId) => await fetchReportingReportDetailRequest(reportId),
        opt
    );
};

const getActiveReportFromStore = (): Ref<ReportDetail | null> => {
    const { activeReport } = storeToRefs(useReportingReportStore());
    return activeReport;
};

export const fetchReportingSender = async (
    projectId: number
): Promise<{
    isSuccess: boolean;
    data: ReportingInfoSender | null;
}> => {
    const result = await fetchReportingSenderRequest({ projectId });

    if (result && result.data) {
        const store = useReportingSenderStore();
        const { sender } = storeToRefs(store);
        sender.value = result.data;
    }

    return result;
};

export const fetchReportingTemplateList = async (
    projectId: number,
    opt?: FetchListOptions
): Promise<{
    isSuccess: boolean;
    data: ReportTemplateInfo[] | null;
}> => {
    return await fetchReportingTemplateListRequest({ projectId, opt });
};

export const fetchReportingTemplateDetail = async (
    projectId: number,
    templateId: number
): Promise<{
    isSuccess: boolean;
    data: ReportTemplateDetail | null;
}> => {
    const result = await fetchReportingTemplateDetailRequest({ projectId, templateId });

    if (result && result.data) {
        const store = useTemplateDetailStore();
        const { templateDetail } = storeToRefs(store);
        templateDetail.value = result.data;
    }

    return result;
};

export const createReportingReport: FormHandlerWithArgs<
    number,
    ReportFormData,
    { isSuccess: boolean; data: ReportDetail | null }
> = async (projectId, values, ctx) => {
    return await updateAndStoreFormData(
        null,
        values,
        ctx,
        getActiveReportFromStore,
        async (_, values) => await postReportingReportRequest({ projectId: projectId, data: values })
    );
};

export const uploadAttachment = async (
    projectId: number,
    values: UploadFileFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: string | string[];
    appFile: AppFileInfo | null;
}> => {
    const { data, isSuccess, isValidationError, errors } = await postReportingAttachmentRequest({
        projectId: projectId,
        data: values,
    });
    let err: string | string[] = '';

    if (isValidationError) {
        err = errors.File || '';
    }

    return { isSuccess, isValidationError, errors: err, appFile: data };
};

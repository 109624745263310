import { TasksGanttDetail } from '~/modules/tasks/api/gantt/_types';

export const useTasksGanttStore = defineStore('tasks-gantt', () => {
    const activeGantt = ref<TasksGanttDetail | null>(null);

    const reset = () => {
        activeGantt.value = null;
    };

    const updateGantt = (val: Partial<TasksGanttDetail>) => {
        if (activeGantt.value == null) return;

        activeGantt.value = {
            ...activeGantt.value,
            ...val,
        };
    };

    return {
        activeGantt,
        updateGantt,
        reset,
    };
});

import { ReportingInfoSender } from '~/modules/reporting/api/reports/_types';

const useReportingSenderStore = defineStore('sender', () => {
    const sender = ref<ReportingInfoSender | null>(null);

    return {
        sender,
    };
});

export default useReportingSenderStore;

export enum UserSettingsTab {
    Info = 'info',
    Projects = 'projects',
    Subjects = 'subjects',
    Roles = 'roles',
}

export const isUserSettingsTab = (key: string): key is UserSettingsTab => {
    return Object.values(UserSettingsTab).some((x) => x === key);
};

import { CommunicationInfo } from '~/modules/core/api/communication/_types';

const useCommunicationStore = defineStore('communication', () => {
    const info = ref<CommunicationInfo | null>(null);

    return {
        info,
    };
});

export default useCommunicationStore;

import { NavigationGuardWithThis, NavigationHookAfter } from 'vue-router';
import config from '~/plugins/config';

const resolveRouteHooks = (): {
    afterHooks: NavigationHookAfter[];
    beforeHooks: NavigationGuardWithThis<undefined>[];
} => {
    const localeFiles = import.meta.glob<{ after: NavigationHookAfter; before: NavigationGuardWithThis<undefined> }>(
        `../../modules/**/pages/**/_routeHooks.ts`,
        {
            eager: true,
        }
    );

    const afterHooks: NavigationHookAfter[] = [];
    const beforeHooks: NavigationGuardWithThis<undefined>[] = [];

    Object.entries(localeFiles).forEach(async ([key, value]) => {
        const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/pages/);

        if (matchedModule?.length !== 2) {
            console.warn('[Plugin router]', `Can't load '${key}' afterHook.`);
            return;
        }

        const module = matchedModule[1];

        if (!config.isModuleSupported(module)) {
            console.log('[Plugin router]', `Module '${module}' is not supported for afterHook.`);
            return;
        }

        if (value.after) afterHooks.push(value.after);
        if (value.before) beforeHooks.push(value.before);
    });

    return {
        afterHooks,
        beforeHooks,
    };
};

export const getRouteHooks = () => {
    if (_hooks == null) {
        _hooks = resolveRouteHooks();
    }
    return _hooks;
};

let _hooks: {
    afterHooks: NavigationHookAfter[];
    beforeHooks: NavigationGuardWithThis<undefined>[];
} | null = null;

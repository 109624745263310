import { JobInfo, JobManager } from './_types';
import { debug } from '~/utils/debugUtilts';

export const createJobManager = (): JobManager => {
    const { activeJobs, registeredJobs, getJob, registerJob, unregisterJob, getJobTimer, addJobTimer, removeJobTimer } =
        createJobmanagerStore();

    const register: JobManager['register'] = (job) => {
        const existingJob = getJob(job.key);
        if (existingJob != null) {
            console.warn(
                `[Plugin JobManager] - job with key '${job.key}' is already registered. This job registration will be skipped.`
            );
            return;
        }

        registerJob(job);
        debug(`[Plugin JobManager] - Job with key '${job.key}' was registered.`);
    };

    const registerBatch: JobManager['registerBatch'] = (jobs) => {
        jobs.forEach((x) => register(x));
    };

    const unregister: JobManager['unregister'] = (key) => {
        const existingJob = getJob(key);
        if (existingJob == null) return;

        pauseJob(key);
        unregisterJob(key);
        debug(`[Plugin JobManager] - Job with key '${key}' was unregistered.`);
    };

    const unregisterAll: JobManager['unregisterAll'] = () => {
        const jobsToUnregister = [...activeJobs];
        jobsToUnregister.forEach((x) => {
            unregister(x.key);
        });
    };

    const startJob: JobManager['startJob'] = (key) => {
        const job = getJob(key);
        if (job == null) return;

        pauseJob(key);

        _executeJob(job);
        const timer = setInterval(() => {
            _executeJob(job);
        }, job.interval);

        addJobTimer(key, timer);

        debug(`[Plugin JobManager] - Job with key '${key}' started.`);
    };

    const pauseJob: JobManager['startJob'] = (key) => {
        const timer = getJobTimer(key);
        if (timer != null) {
            clearInterval(timer);
            removeJobTimer(key);
            debug(`[Plugin JobManager] - Job with key '${key}' has been paused.`);
        }
    };

    const startAllJobs: JobManager['startAllJobs'] = () => {
        debug(`[Plugin JobManager] - Starting all jobs`);
        registeredJobs.forEach((x) => {
            startJob(x.key);
        });
    };

    const pauseAllJobs: JobManager['pauseAllJobs'] = () => {
        debug(`[Plugin JobManager] - Pausing all jobs`);

        registeredJobs.forEach((x) => {
            pauseJob(x.key);
        });
    };

    const _executeJob = async (job: JobInfo) => {
        debug(`[Plugin JobManager] - Job with key '${job.key}' is executing...`);
        await job.jobFn();
        debug(
            `[Plugin JobManager] - Job with key '${job.key}' finished executing. Next execution is in ${job.interval}ms`
        );
    };

    return {
        register,
        registerBatch,
        unregister,
        unregisterAll,
        startJob,
        pauseJob,
        startAllJobs,
        pauseAllJobs,
    };
};

const createJobmanagerStore = () => {
    let activeJobs: { key: string; timer: NodeJS.Timeout }[] = [];
    let registeredJobs: JobInfo[] = [];

    const getJob = (key: string): JobInfo | null => {
        return registeredJobs.find((x) => x.key === key) || null;
    };

    const registerJob = (job: JobInfo): boolean => {
        const existingJob = getJob(job.key);
        if (existingJob != null) return false;

        registeredJobs.push(job);
        return true;
    };

    const unregisterJob = (key: string): void => {
        registeredJobs = registeredJobs.filter((x) => x.key !== key);
    };

    const getJobTimer = (key: string): NodeJS.Timeout | null => {
        return activeJobs.find((x) => x.key === key)?.timer || null;
    };

    const addJobTimer = (key: string, timer: NodeJS.Timeout): boolean => {
        const existingTimer = getJobTimer(key);
        if (existingTimer != null) return false;

        activeJobs.push({ key, timer });
        return true;
    };

    const removeJobTimer = (key: string): void => {
        activeJobs = activeJobs.filter((x) => x.key !== key);
    };

    return {
        activeJobs,
        registeredJobs,
        getJob,
        registerJob,
        unregisterJob,
        getJobTimer,
        addJobTimer,
        removeJobTimer,
    };
};

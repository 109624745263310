import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import {
    tasksIssueCreateFormDataToRaw,
    rawToTasksIssueDetail,
    rawToTasksIssueInfoArray,
    tasksIssueDueDateFormDataToRaw,
    tasksIssueEstimatedTimeFormDataToRaw,
    rawToTasksIssueAssigneeInfo,
    rawToTasksIssuePriorityInfo,
    rawToTasksIssueStateInfo,
    rawToTasksIssueDueDateInfo,
    rawToTasksIssueEstimatedTimeInfo,
    rawToTasksIssueClosedInfo,
    rawToTasksIssueInfoShortArray,
} from './_transformers';
import {
    TasksIssueDetail,
    TasksIssueCreateFormData,
    TasksIssueInfo,
    TasksIssueDueDateFormData,
    TasksIssueEstimatedTimeFormData,
    TasksIssueAssigneeFormData,
    TasksIssuePriorityFormData,
    TasksIssueStateFormData,
    TasksIssueAssigneeInfo,
    TasksIssuePriorityInfo,
    TasksIssueStateInfo,
    TasksIssueDueDateInfo,
    TasksIssueClosedInfo,
    TasksIssueEstimatedTimeInfo,
    TasksIssueInfoShort,
} from './_types';

// > Endpoint prefix
const apiEndpointPrefix = 'tasks';

export const fetchTasksIssuesRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TasksIssueInfo[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'issues'), {
        transform: rawToTasksIssueInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksIssuesSelectRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TasksIssueInfoShort[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'issues/select'), {
        transform: rawToTasksIssueInfoShortArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};

export const fetchTasksIssuesGanttSelectRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TasksIssueInfo[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'issues/gantt-select'), {
        transform: rawToTasksIssueInfoArray,
        transformMeta: rawToPaginationMeta,
        params: fetchListOptionsToQuery(opt),
    });
};
export const fetchTasksIssueDetailRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<TasksIssueDetail | null>
> = async (issueId: number) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `issues/${issueId}`), {
        transform: rawToTasksIssueDetail,
    });
};

export const postTasksIssueRequest: ApiHandlerWithData<
    {
        projectId: number;
        data: TasksIssueCreateFormData;
    },
    ApiResponseContext<TasksIssueDetail | null, TasksIssueCreateFormData>
> = async ({ projectId, data }) => {
    return api.post(formatApiUrl(apiEndpointPrefix, `${projectId}/issues`), tasksIssueCreateFormDataToRaw(data), {
        transform: rawToTasksIssueDetail,
    });
};

export const patchTasksIssueRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssueCreateFormData;
    },
    ApiResponseContext<TasksIssueDetail | null, TasksIssueCreateFormData>
> = async ({ issueId, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}`), data, {
        transform: rawToTasksIssueDetail,
    });
};

export const patchTasksIssueCloseRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<TasksIssueClosedInfo | null, TasksIssueClosedInfo>
> = async (issueId) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/close`), null, {
        transform: rawToTasksIssueClosedInfo,
    });
};

export const patchTasksIssueReopenRequest: ApiHandlerWithData<
    number,
    ApiResponseContext<TasksIssueClosedInfo | null, TasksIssueClosedInfo>
> = async (issueId) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/re-open`), null, {
        transform: rawToTasksIssueClosedInfo,
    });
};

export const patchTasksIssueChangeAssigneeRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssueAssigneeFormData;
    },
    ApiResponseContext<TasksIssueAssigneeInfo | null, TasksIssueAssigneeFormData>
> = async ({ issueId, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/assignee`), data, {
        transform: rawToTasksIssueAssigneeInfo,
    });
};

export const patchTasksIssueChangePriorityRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssuePriorityFormData;
    },
    ApiResponseContext<TasksIssuePriorityInfo | null, TasksIssuePriorityFormData>
> = async ({ issueId, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/priority`), data, {
        transform: rawToTasksIssuePriorityInfo,
    });
};

export const patchTasksIssueChangeStateRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssueStateFormData;
    },
    ApiResponseContext<TasksIssueStateInfo | null, TasksIssueStateFormData>
> = async ({ issueId, data }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/state`), data, {
        transform: rawToTasksIssueStateInfo,
    });
};

export const patchTasksIssueChangeDueDateRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssueDueDateFormData;
    },
    ApiResponseContext<TasksIssueDueDateInfo | null, TasksIssueDueDateFormData>
> = async ({ issueId, data }) => {
    return api.patch(
        formatApiUrl(apiEndpointPrefix, `issues/${issueId}/due-date`),
        tasksIssueDueDateFormDataToRaw(data),
        {
            transform: rawToTasksIssueDueDateInfo,
        }
    );
};

export const patchTasksIssueChangeEstimatedTimeRequest: ApiHandlerWithData<
    {
        issueId: number;
        data: TasksIssueEstimatedTimeFormData;
    },
    ApiResponseContext<TasksIssueEstimatedTimeInfo | null, TasksIssueEstimatedTimeFormData>
> = async ({ issueId, data }) => {
    return api.patch(
        formatApiUrl(apiEndpointPrefix, `issues/${issueId}/estimated-time`),
        tasksIssueEstimatedTimeFormDataToRaw(data),
        {
            transform: rawToTasksIssueEstimatedTimeInfo,
        }
    );
};

export const patchTasksIssueTimeComplianceRequest: ApiHandlerWithData<
    {
        issueId: number;
        isCompliant: boolean;
    },
    ApiResponseContext<null>
> = async ({ issueId, isCompliant }) => {
    return api.patch(formatApiUrl(apiEndpointPrefix, `issues/${issueId}/time-compliance`), { Value: isCompliant });
};

import { ApiErrors, ApiGenericValues } from '~/plugins/apiClient/_types';

/*
    @plachtova: TODO: is there a better way to do it?
    'vee-validate' does not have good typing for validation errors, so if we have to modify errors we will blindly change type, 
    do the necessary transformations and then convert it back to the type vee-validate needs it for `setErrors` method

    'vee-validate' type problems:
    * vee-validate should also accept 'string[]' not just  'string | undefined'
    * (keyof T where T is form model) does not work if property in T is array or if there are nested objects
*/
export const transformApiErrors = <T = ApiGenericValues>(
    errors: ApiErrors<T>,
    transformFn: (
        errors: Record<string, string[] | string | undefined>
    ) => Record<string, string[] | string | undefined>
): ApiErrors<T> => {
    const tmpErrors = errors as Record<string, string[] | string | undefined>;
    const transformedErrors = transformFn(tmpErrors);

    return transformedErrors as ApiErrors<T>;
};

export const pushErrorToArray = (errors: string[], error: string | string[] | undefined): void => {
    if (error != null) {
        if (typeof error === 'string') {
            errors.push(error);
        } else {
            errors.push(...error);
        }
    }
};

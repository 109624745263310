import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { fetchListOptionsToQuery, rawToPaginationMeta } from '~/modules/core/api/_transformers';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { ProjectSimpleInfo, SubjectSimpleInfo, LabelSimpleInfo, RolesGroupedCollection } from './_types';
import {
    rawToLabelSimpleInfoArray,
    rawToProjectSimpleInfoArray,
    rawToRolesGroupedCollection,
    rawToSubjectSimpleInfoArray,
} from './_transformers';

const apiEndpointPrefix = 'enums';

export const fetchSimpleProjectsRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<ProjectSimpleInfo[] | null, null, PaginationMeta>
> = async (args: { opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'projects'), {
        transformMeta: rawToPaginationMeta,
        transform: rawToProjectSimpleInfoArray,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchSimpleSubjectsRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<SubjectSimpleInfo[] | null, null, PaginationMeta>
> = async (args: { opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'subjects'), {
        transformMeta: rawToPaginationMeta,
        transform: rawToSubjectSimpleInfoArray,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchSimpleLabelsRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<LabelSimpleInfo[] | null, null, PaginationMeta>
> = async (args: { opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'labels'), {
        transformMeta: rawToPaginationMeta,
        transform: rawToLabelSimpleInfoArray,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchSimpleRolesRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<RolesGroupedCollection | null, null, PaginationMeta>
> = async (args: { opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'roles'), {
        transformMeta: rawToPaginationMeta,
        transform: rawToRolesGroupedCollection,
        params: fetchListOptionsToQuery(args.opt),
    });
};

export const fetchEnumsRolesRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<RolesGroupedCollection | null, null, PaginationMeta>
> = async (args: { opt?: FetchListOptions }) => {
    return api.get(formatApiUrl(apiEndpointPrefix, 'roles'), {
        transformMeta: rawToPaginationMeta,
        transform: rawToRolesGroupedCollection,
        params: fetchListOptionsToQuery(args.opt),
    });
};

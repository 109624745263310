import { InventoryHistoryDetail } from '~/modules/inventory/api/history/_types';

const useInventoryHistoryStore = defineStore('inventory-history', () => {
    const activeHistoryDetail = ref<InventoryHistoryDetail | null>(null);

    const reset = () => {
        activeHistoryDetail.value = null;
    };

    return {
        activeHistoryDetail,
        reset,
    };
});

export default useInventoryHistoryStore;

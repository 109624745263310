import { Checklist, ChecklistItem } from '~/modules/core/api/checklists/_types';

const useChecklistsStore = defineStore('checklists', () => {
    const checklists = ref<Array<Checklist>>([]);
    const totalChecklists = ref<number>(-1);
    const activeChecklist = ref<Checklist | null>(null);
    const checklistFormOpen = ref<boolean>(false);
    const modalChecklistOpen = ref<boolean>(false);
    const totalUncheckedCount = ref<number>(0);

    const addNewChecklist = (checklist: Checklist) => {
        checklists.value.unshift(checklist);
    };

    const updateChecklist = (checklist: Checklist) => {
        deleteChecklistById(checklist.Id);
        addNewChecklist(checklist);
    };

    const updateChecklistById = (id: number, item: Checklist) => {
        const targetItem = checklists.value.find((item) => item.Id === id);

        if (!targetItem) {
            return;
        }

        targetItem.Title = item.Title;

        targetItem.Items.forEach((ite: ChecklistItem, key: number) => {
            ite.Title = item.Items[key].Title || ite.Title;
            ite.Checked = item.Items[key].Checked;
        });
    };

    const deleteChecklistById = (id: number) => {
        const index = checklists.value.findIndex((checklist) => checklist.Id === id);

        if (index > -1) {
            checklists.value.splice(index, 1);
        }
    };

    const setActiveChecklist = (checklist: Checklist | null) => {
        activeChecklist.value = checklist;
    };

    const setChecklistFormOpen = (open: boolean) => {
        checklistFormOpen.value = open;
    };

    const setModalChecklistOpen = (open: boolean) => {
        modalChecklistOpen.value = open;
    };

    const clearChecklists = () => {
        checklists.value = [];
        totalChecklists.value = -1;
    };

    const updateUncheckedCount = (count: number) => {
        totalUncheckedCount.value = count;
    };

    return {
        checklists,
        totalChecklists,
        activeChecklist,
        checklistFormOpen,
        modalChecklistOpen,
        totalUncheckedCount,
        addNewChecklist,
        updateChecklist,
        updateChecklistById,
        deleteChecklistById,
        setActiveChecklist,
        setChecklistFormOpen,
        setModalChecklistOpen,
        clearChecklists,
        updateUncheckedCount,
    };
});

export default useChecklistsStore;

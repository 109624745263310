import { RouteLocationNormalized } from 'vue-router';
import { RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionTasksIssue from '~/modules/tasks/composables/permissions/usePermissionsTasksIssue';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import { checkApiResponseRedirect } from '~/plugins/router/utils';

export const redirects = (): RoutePageRedirects => {
    const { getProjectDetailFromRoute } = usePermissionProject();

    const { canCreateIssue } = usePermissionTasksIssue();

    const canGoNewIssue = async (to: RouteLocationNormalized) => {
        const response = await getProjectDetailFromRoute(to);
        return checkApiResponseRedirect(response, () => canCreateIssue(response.data));
    };

    return {
        'tasks-id-new': canGoNewIssue,
    };
};

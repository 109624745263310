import { RedirectType, RoutePageRedirects } from '~/plugins/router/_types';
import usePermissionCommunication from '~/modules/core/composables/permissions/usePermissionsCommunication';

export const redirects = (): RoutePageRedirects => {
    const { canSendAny, canReceiveAny } = usePermissionCommunication();
    return {
        'communication-send': () => (canSendAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
        communication: () => (canReceiveAny() ? RedirectType.NoRedirect : RedirectType.AccessDenied),
    };
};

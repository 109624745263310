import { Duration } from 'luxon';
import { TrackingTimerInfo } from '~/modules/tasks/api/tracking/_types';

export const useTasksTimerStore = defineStore('tasks-timer', () => {
    const activeTimer = ref<TrackingTimerInfo | null>(null);
    const activeDuration = ref<Duration>(Duration.fromMillis(0));
    const activeDurationId = ref<number>();

    const updateTimer = (val: Partial<TrackingTimerInfo>) => {
        if (activeTimer.value == null) return;

        activeTimer.value = {
            ...activeTimer.value,
            ...val,
        };
    };

    const reset = () => {
        activeTimer.value = null;
        activeDuration.value = Duration.fromMillis(0);
        window.clearInterval(activeDurationId.value);
        activeDurationId.value = undefined;
    };

    return {
        activeTimer,
        activeDuration,
        activeDurationId,
        updateTimer,
        reset,
    };
});

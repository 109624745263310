import { JobInfo } from '~/plugins/jobManager/_types';
import { fetchNotificationStatus } from '~/modules/core/services/notifications';

export enum JobSharedKey {
    NotificationStatus = 'NotificationStatus',
}

export const getJobs = (): JobInfo[] => {
    return [
        {
            key: JobSharedKey.NotificationStatus,
            // 5min
            interval: 5000 * 60,
            jobFn: () => {
                fetchNotificationStatus();
            },
        },
    ];
};

import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandler, ApiHandlerWithArgs, ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { rawToLabelInfo, rawToLabelInfoArray } from './_transformers';
import { LabelFormData, LabelInfo } from './_types';
import { FetchListOptions } from '~/modules/core/api/_types';
import { fetchListOptionsToQuery } from '~/modules/core/api/_transformers';

// > Endpoint prefix
const apiEndpointPrefix = 'labels';

export const fetchLabelsMyRequest: ApiHandler<ApiResponseContext<LabelInfo[] | null>> = async () => {
    return api.get(formatApiUrl(apiEndpointPrefix, `list/my`), {
        transform: rawToLabelInfoArray,
    });
};

export const fetchLabelsRequest: ApiHandlerWithArgs<FetchListOptions, ApiResponseContext<LabelInfo[] | null>> = async (
    opt?: FetchListOptions
) => {
    return api.get(formatApiUrl(apiEndpointPrefix, `list`), {
        transform: rawToLabelInfoArray,
        params: fetchListOptionsToQuery(opt),
    });
};

export const deleteLabelRequest: ApiHandlerWithData<number, ApiResponseContext<never>> = async (labelId) => {
    return api.delete(formatApiUrl(apiEndpointPrefix, labelId));
};

export const postLabelRequest: ApiHandlerWithData<
    { data: LabelFormData },
    ApiResponseContext<LabelInfo, LabelFormData>
> = async ({ data }) => {
    return api.post(apiEndpointPrefix, data, {
        transform: rawToLabelInfo,
    });
};

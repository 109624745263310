import {
    fetchNotificationsRequest,
    fetchNotificationsStatusRequest,
    patchNotificationActionRequest,
} from '~/modules/core/api/notifications';
import {
    NotificationInfoDetail,
    NotificationInfoList,
    NotificationStatusInfo,
} from '~/modules/core/api/notifications/_types';
import { useNotificationsStore } from '~/modules/core/stores/notifications';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { NotificationAction } from '~/modules/core/enums/_types';

export const fetchNotificationStatus = async (): Promise<{
    isSuccess: boolean;
    data: NotificationStatusInfo | null;
}> => {
    const { isSuccess, data } = await fetchNotificationsStatusRequest();

    if (isSuccess && data != null) {
        const store = useNotificationsStore();
        store.status = data;
    }

    return { isSuccess, data };
};

/**
 * @param opt
 */
export const fetchNotifications = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: NotificationInfoList | null; meta: PaginationMeta | null }> => {
    const res = await fetchNotificationsRequest(opt);

    if (res.isSuccess && res.data != null) {
        updateStatus(res.data.Status);
    }

    return res;
};

/**
 * @param id
 */
export const setNotificationRead = async (
    id: number
): Promise<{ isSuccess: boolean; data: NotificationInfoDetail | null }> => {
    const res = await patchNotificationActionRequest({ id, action: NotificationAction.Read });

    if (res.isSuccess && res.data != null) {
        updateStatus(res.data.Status);
    }

    return res;
};

/**
 * @param id
 */
export const setNotificationUnread = async (
    id: number
): Promise<{ isSuccess: boolean; data: NotificationInfoDetail | null }> => {
    const res = await patchNotificationActionRequest({ id: id, action: NotificationAction.Unread });

    if (res.isSuccess && res.data != null) {
        updateStatus(res.data.Status);
    }

    return res;
};

const updateStatus = (status: NotificationStatusInfo): void => {
    const store = useNotificationsStore();
    store.status = status;
};

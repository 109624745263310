import { ReportDetail } from '~/modules/reporting/api/reports/_types';

const useReportingReportStore = defineStore('reports', () => {
    const activeReport = ref<ReportDetail | null>(null);

    const reset = () => {
        activeReport.value = null;
    };

    return {
        activeReport,
        reset,
    };
});

export default useReportingReportStore;

import Sortable, { MultiDrag } from 'sortablejs';
import { AppPlugin } from '~/plugins/_types';

export default {
    name: 'sortablejs',
    install: async () => {
        Sortable.mount(new MultiDrag());
    },
    priority: 1,
} satisfies AppPlugin;

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    TimeSeriesScale,
    Filler,
    LineController,
    ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotation from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon';
import { AppPlugin } from '~/plugins/_types';

export default {
    name: 'chartJS',
    install: async () => {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            PointElement,
            LineElement,
            ArcElement,
            TimeScale,
            TimeSeriesScale,
            Filler,
            Title,
            Tooltip,
            Legend,
            ChartDataLabels,
            ChartAnnotation,
            LineController
        );
        ChartJS.defaults.font = {
            family: 'Source Sans Pro',
            size: 14,
        };
        ChartJS.register();
    },
    priority: 1,
} satisfies AppPlugin;
